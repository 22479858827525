import { lockClosed, person } from "ionicons/icons";
import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, TextField } from "../../../atoms/LoginTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormButton from "../../../atoms/FormButton";
import { IdentificationMethodEnum } from "../../../../types/profile";

interface loginLoginFieldsProps {
  onConnect(arg: any): void;
  withNickName?: boolean;
}

interface FormType {
  login: string;
  password: string;
}

const EmailLoginFields: FC<loginLoginFieldsProps> = ({
  onConnect,
  withNickName,
}): ReactElement => {
  const { t } = useTranslation();

  const loginSchema = Yup.object().shape({
    login: withNickName ? Yup.string().required() : Yup.string().email().required(),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])(?!.*\s).{8,100}$/,
        "form_error_email_password"
      )
      .required(),
  });

  const connectUser = async (formData: FormType) => {
    try {
      if (withNickName) {
        onConnect({[IdentificationMethodEnum["NICKNAME_PASSWORD"]]: { nickname: formData.login, password: formData.password} });
      } else {
        onConnect({[IdentificationMethodEnum["EMAIL"]]: formData });
      }
    } catch (e) {
      console.error(e);
    }

  };

  const { values, touched, errors, submitForm, setFieldValue } =
    useFormik<FormType>({
      initialValues: {
        login: "",
        password: "",
      },
      onSubmit: connectUser,
      validationSchema: loginSchema,
    });

  return (
    <>
      <FormGroup>
        <TextField
          value={values.login}
          icon={person}
          type="login"
          error={touched.login && errors.login}
          onChange={(value) => setFieldValue("login", value)}
          placeholder={t(withNickName ? "home_login_nickname_placeholder" : "home_login_email_placeholder")}
        />

        <TextField
          value={values.password}
          icon={lockClosed}
          type="password"
          error={touched.password && errors.password}
          onChange={(value) => setFieldValue("password", value)}
          placeholder={t("home_login_password_placeholder")}
        />
      </FormGroup>
      <FormButton onClick={submitForm}>{t("home_login_button")}</FormButton>
    </>
  );
};

export default EmailLoginFields;
