import { ReactElement, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../contexts/AuthContext";
import { useConfig } from "../../contexts/ConfigContext";
import { useFetch } from "../../hooks/useFetch";
import VeriticalCenter from "../../components/atoms/VerticalCenter";
import Container from "../../components/atoms/Container";

import DisplayMountain from "../../components/organisms/Home/DisplayMountain";
import { useResonanceLayout } from "../../components/organisms/ResonnanceLayout/ResoncanceContext";
import { postUserGamification } from "../../services/user.service";
import PointModal from "../Profile/PointsModal";
import { ComponentType, QuestionnaireDetailsType } from "../../types/Questionnaire";
import { getQuestionnairesByComponents } from "../../services/questionnaire.service";

import { useHistory, useParams } from "react-router";
import StoryTelling from "../../components/molecules/Home/StoryTellingModal";
import ResonanceLoader from "../../components/atoms/ResonanceLoader/ResonanceLoader";
import Info from "../../components/atoms/ErrorComponent";
import DisplayThankYouParticipation from "../../components/organisms/Invitation/DisplayThankYouParticipation";
import { UserGamificationDetails } from "../../types/welcome";
import { isMandatoryQuestionnaire } from "../../helper/questionnaire";

const LoguedHome = (): ReactElement => {
  const { setResonanceLayout, userGamificationDetails, questionnaires } = useResonanceLayout();  
  const history = useHistory();
  const { study } = useConfig();
  const { study_slug } = useParams<{ study_slug: string }>();
  const { user } = useAuth();
  const {
    t,
    i18n: { language: lang },
  } = useTranslation();

  const [pointToDisplay, setPointToDisplay] = useState(0);
  const [animationCompleted, setAnimationCompleted] = useState(false);

  const [isPointModalOpen, SetIsPointModalOpen] = useState(false);
  const [isThankYouParticipationModalOpen, SetIsThankYouParticipationModalOpen] = useState(false);
  const [isStorryTellingModalOpen, SetIsStorryTellingModalOpen] = useState(false);

  const [disableHeaderFinished, setDisableHeaderFinished] = useState(false);

  const [storyTellingFinished, setStoryTellingFinished] = useState(false);

  const {
    isError: isError,
    isLoading: isLoading,
    data: questionnairesBDD,
  } = useFetch<ComponentType[]>("questionnairesBDD", getQuestionnairesByComponents, {
    studyId: study.id,
    userId: user.id,
    lang: lang,
  });

  useEffect(() => {
    setResonanceLayout({
      disableHeader: true,
      disableNav: false,
      backgroundColor: "white",
    });
    
    setDisableHeaderFinished(true);
    
    return () =>
      setResonanceLayout({
        disableHeader: true,
        disableNav: false,
        backgroundColor: "white",
      });
  }, []);

  useEffect(() => {
    const UpdateUserGamification = async () => {
      try {
        const { data: userGamificationBDD } = await postUserGamification(study.id, user.id, null);

        if (userGamificationBDD){
          const userGamificationUpdates: UserGamificationDetails = {
            points: userGamificationBDD.points,
            step: userGamificationBDD.step,
            animation_success: userGamificationBDD.animation_success,
            pointsEarned: 0,
            stepEarned: 0,
            animation_successEarned: 0
          };
    
          setResonanceLayout({ userGamificationDetails: userGamificationUpdates });
        }
      }
      catch (e) 
      {
        console.error("Error Get UserGamification :" + e);
      }
    }

    if (userGamificationDetails != undefined)
    {
      if (userGamificationDetails.pointsEarned != 0 || userGamificationDetails.stepEarned != 0 || userGamificationDetails.animation_successEarned != 0)
      {
        checkPointEarned();
      }
      
      checkStoryTelling();
    }
    else
    {
      UpdateUserGamification();
    }

    return () => {};
  }, [userGamificationDetails]);

  useEffect(() => {
    if(questionnairesBDD)
    {
      if (questionnaires === undefined)
        setResonanceLayout({ questionnaires: questionnairesBDD });
    }

    return () => {};
  }, [questionnairesBDD]);

  const checkPointEarned = () => {
    if (userGamificationDetails.pointsEarned == 0)
    {
      setPointToDisplay(0);
    }
    else
    {
      setPointToDisplay(userGamificationDetails.pointsEarned);

      if (userGamificationDetails.animation_success == 0)
        SetIsThankYouParticipationModalOpen(true);
    }
  }

  const checkStoryTelling = () => {
    if(userGamificationDetails.points == 0 && userGamificationDetails.step == 0 && userGamificationDetails.animation_success == 0)
    {
      SetIsStorryTellingModalOpen(true);
    }
    else 
    {
      SetIsStorryTellingModalOpen(false);
      setStoryTellingFinished(true);
    }
  }

  const goQuestionnaire = (sequence_id: number, component_id: number) => {
    history.push(
      `/${study_slug}/resonance/sequences/${sequence_id}/components/${component_id}`
    );
  };

  /** 
   * Check if there are mandatory questionnaires
   * */ 
  useEffect(() => {
    if (questionnaires && storyTellingFinished && userGamificationDetails)
    {
      for (const index in questionnaires) 
      {
        const questionnaire = questionnaires[index] as ComponentType;
  
        if (isMandatoryQuestionnaire(questionnaire.keywords) && (storyTellingFinished || userGamificationDetails.step !== 0 || userGamificationDetails.points !== 0)) 
        {
          goQuestionnaire(questionnaire.sequenceId, questionnaire.id);
          break;
        }
      }
    }
  }, [isLoading, questionnaires, storyTellingFinished, userGamificationDetails]);  

  const handleAnimationComplete = (value) => {
    setAnimationCompleted(value);
  };

  const animation = () => {
    SetIsStorryTellingModalOpen(false);
    setStoryTellingFinished(true);
  };

  useEffect(() =>
  {
    if (animationCompleted && pointToDisplay > 0)
      SetIsPointModalOpen(true);
  }, [animationCompleted, pointToDisplay]);

  if (isLoading && questionnaires == undefined) return <ResonanceLoader />;

  if (isError)
  {
    return (
      <Info
        title={t("common_server_error_title")}
        description={t("common_server_error_desc")}
        actionText={t("common_go_back")}
      />
    );
  }

  // Ajout Synakene 
  return (
    <Container>
      <VeriticalCenter>
      {isThankYouParticipationModalOpen ? (
        <DisplayThankYouParticipation onDismiss={() => SetIsThankYouParticipationModalOpen(false)} />
      ) : (
        <>
          <PointModal isOpen={isPointModalOpen} onDismiss={() => SetIsPointModalOpen(false)} points={pointToDisplay} />
          <StoryTelling isOpen={isStorryTellingModalOpen} onDismiss={() => SetIsStorryTellingModalOpen(false)} animate={animation}/>
          <DisplayMountain onAnimationComplete={handleAnimationComplete} disableHeaderFinished={disableHeaderFinished} />
        </>
      )}
      </VeriticalCenter>
    </Container>
  );
};

export default LoguedHome;