export const common = {
  common_cancel: 'Cancelar',
  common_close: 'Cerrar',
  common_questionnaire: 'Cuestionario',
  common_clear: 'Borrar',
  common_save: 'Guardar',
  common_mail: 'Correo electrónico',
  common_sms: 'Sms',
  common_copy: 'Copiar',
  common_name: 'Nombre',
  common_surname: 'Apellido',
  common_lang: 'Idioma',
  common_phone: 'Teléfono',
  common_next: 'Siguiente',
  common_previous: 'Anterior',
  common_details: 'Detalles',
  common_send: 'Enviar',
  common_start: 'Comenzar',
  common_password: 'Contraseña',
  common_click_to_speak: 'Haga clic para hablar',
  common_server_error_title: 'Ha ocurrido un error en el servidor',
  common_server_error_desc: 'Por favor, inténtelo de nuevo o contáctenos',
  common_go_back: 'Regresar',
  common_nodata_title: 'No hay datos disponibles',
  common_nodata_desc: 'Por favor, inténtelo de nuevo o contáctenos',
  common_bad_request: 'Solicitud incorrecta',
  common_no_slug: 'Por favor, haga clic en el enlace de invitación en su correo electrónico o mensajes SMS',
  confirm_quit_study_button: 'Dejar definitivamente',
  come_back_later_button: 'Volveré más tarde',
  doing_questionnaire_button: 'Estoy haciendo un cuestionario',
  help_link_part1: 'Si no te sientes bien, puedes ',
  help_link_part2: 'obtener ayuda haciendo clic aquí',
  thank_you: '¡Gracias!',
  you_earned_part1: 'Has ganado ',
  you_earned_part2: ' puntos!',
  other_questionnaire: 'Puedes ayudarnos aún más completando otro cuestionario.'
};
