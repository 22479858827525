export const consent = {
  consent_subtitle_1_1: "INFORMATION DOCUMENT FOR 11-17 YEAR OLDS",
  consent_subtitle_1_2: "INFORMATION DOCUMENT FOR 18-24 YEAR OLDS",
  consent_subtitle_1_3: "INFORMATION DOCUMENT FOR PARENTS OF 11-17 YEAR OLDS",
  consent_subtitle_2: "Mental well-being of adolescents and young adults: realities and evolutions (Mentalo)",
  consent_paragraph_1: "The scientific director of the research is Professor Karine CHEVREUL, director of the ECEVE unit at the National Institute of Health and Medical Research (Inserm) and at the University of Paris Cité. She is also responsible for implementing data processing.",
  consent_paragraph_2: "The data processing manager is the ECEVE unit - UMR 1123 INFORMATION ON THE RESEARCH FOR PARTICIPANTS AGED 11 TO 17",
  consent_paragraph_3_1: "Hello,",
  consent_paragraph_3_2: "You are invited to participate in the study entitled 'Mental well-being of adolescents and young adults: realities and evolutions' (Mentalo), which focuses on the mental well-being of 11-24-year-olds and is led by Professor Karine CHEVREUL.",
  consent_paragraph_4: "This document aims to inform you about the study and its procedures to explain what your participation involves. You are free to participate or not. If you do not want to participate, you do not have to justify yourself. Before making your decision, you can talk to a close person or anyone you feel comfortable with.",
  consent_paragraph_5: "If you agree to participate in this study, an information note for your parents is also available so that they can learn about this study and ensure they do not object to the processing of your data.",
  consent_paragraph_6: "Feel free to ask questions at any time by emailing the researchers responsible for the study at mentalo@inserm.fr.",
  consent_paragraph_7: "You can change your mind at any time and request to no longer participate in this study. We simply ask you to inform the researchers by email at mentalo@inserm.fr.",

  consent_subtitle_3: "1. CONTEXT, OBJECTIVES AND JUSTIFICATION",

  consent_paragraph_8: "The COVID-19 pandemic and the subsequent health measures such as lockdowns have had a significant impact on the mental well-being of the entire population, especially the youngest. Recent studies on the subject show a deterioration in the mental well-being of adolescents and young adults.",
  consent_paragraph_9: "The Mentalo study was set up to better understand the situation. Its goal is to study the mental well-being of young people, its evolution over a year, and the factors that may influence it.",
  consent_paragraph_10: "It targets young people aged 11 to 24 living in France and plans to survey 50,000 young people.",

  consent_subtitle_4: "2. STUDY PROCEDURE",

  consent_paragraph_11: "The study takes place online, via a questionnaire. You will be surveyed at different times throughout the study year, upon registration and then 15 days later, then 1, 2, 3, 6, 9, and 12 months later.",
  consent_paragraph_12: "It will take no more than 10 minutes each time.",
  consent_paragraph_13: "After agreeing to participate, you will be asked to create an account on the site where the questionnaire is located, with a login (pseudonym or email) and a password. You will also be asked to provide a phone number or email to notify you when new questionnaires are available. These SMS or emails will be sent automatically and the researchers will not have access to this information.",
  consent_paragraph_14: "Once your account is created, you can answer the study questionnaire. It consists of mandatory modules and optional modules. Upon registration, the mandatory modules cover your mental well-being (how you are doing, what worries you…) and a few questions about your characteristics (your age, whether you are in middle school or high school…).",
  consent_paragraph_15: "During the follow-up, the mandatory follow-up module will cover your mental well-being, to know how you are doing compared to the last time, if there have been changes, and why.",
  consent_paragraph_16: "From your registration, you will also have access to optional modules, which you can choose to complete or not. They cover your family, social, emotional, school, or professional life. You can answer these modules at any time during the study year.",
  consent_paragraph_17: "To thank you for your participation, you will earn points each time you complete a module, whether mandatory or not. Your points will allow you to access gifts once you reach a certain threshold. You can choose your gift from those that are still available and correspond to your number of points. The further you progress in the study, the greater the value of the gifts.",
  consent_paragraph_18: "You can also invite other young people (friends, family…) to participate in the study through a link that will be sent to them.",
  consent_paragraph_19: "All information about the study is available on the site https://etude-mentalo.fr/.",
  consent_paragraph_20: "Your participation will help us better understand the mental well-being of young people in France.",
  consent_paragraph_21: "Your participation will last: 1 year",
  consent_paragraph_22: "The total duration of the research is 3 years.",

  consent_subtitle_5: "3. CONFIDENTIALITY AND DATA PROCESSING",
  consent_paragraph_23: "As part of your participation in this research, your personal data will be processed to analyze the results. This processing is for scientific research purposes and is based on the execution of a public interest mission.",
  consent_paragraph_24: "The data you provide in the questionnaires will be confidential and only the researchers working on the study will have access to it. It will not be possible for the researchers to identify you. Your login credentials, phone number, or email will never be shared with the researchers.",
  consent_paragraph_25: "Your data will be kept securely until the end of the analysis, for a maximum of 3 years. After that, it will be anonymized. Your personal data will therefore not be archived after this period.",

  consent_paragraph_26: "In accordance with the law, you have the right:",
  consent_paragraph_27: "to access the data you have provided during the research and to request its deletion or modification.",
  consent_paragraph_28: "to object at any time to the continued collection of your data.",
  consent_paragraph_29: "to temporarily prevent the use of your data.",
  consent_paragraph_30: "These rights cannot be exercised if they are likely to make it impossible or seriously compromise the achievement of the research objectives.",
  consent_paragraph_31: "If you wish to exercise these rights, you can contact the research team at mentalo@inserm.fr. A response will be provided as soon as possible, and no later than one month from the receipt of your request. In case of difficulties, you can also, through your parents, contact the data protection officer (DPO) of the University of Paris Cité.",
  consent_paragraph_32: "If you believe, after contacting us, that your 'Informatics and Freedoms' rights are not being respected, your parents can file a complaint with the CNIL (www.cnil.fr).",
  consent_paragraph_33: "If you decide to stop participating in the research, the data collected up to that point will be used in accordance with the law. In this case, your data will not be used later or for another research.",
  consent_paragraph_34: "You also have the right to privacy and confidentiality of your data. If you are fifteen years or older, you have the right to refuse your parents/legal guardians access to the data collected about you during the study. If you wish to exercise this right, you can contact the research team (mentalo@inserm.fr).",

  consent_subtitle_6: "4. INFORMATION ON THE GLOBAL RESULTS",

  consent_paragraph_35: "You will have access to the global results of the study on the study site https://etude-mentalo.fr/.",
  consent_paragraph_36: "The research results may also be presented at conferences, in scientific publications, or in the general media.",

  consent_subtitle_7: "5. RE-USE OF DATA FOR NEW RESEARCH",

  consent_paragraph_37: "The data you have provided in the questionnaires could be reused in other studies on the mental well-being of adolescents, but they will never allow you to be identified.",
  consent_paragraph_38: "You have the right to obtain additional information about these studies from the study manager.",
  consent_paragraph_39: "In any case, you and your legal guardians have the right to refuse the reuse of your data in other studies.",

  consent_subtitle_8: "6. LEGISLATIVE AND REGULATORY PROVISIONS",

  consent_paragraph_40: "This research received a favorable opinion from the Inserm Ethics Evaluation Committee (CEEI/IRB) on June 6, 2023 (opinion n°23-1018).",
  consent_paragraph_41: "In summary...",
  consent_paragraph_42: "The study focuses on the mental well-being of young people aged 11 to 24 living in France.",
  consent_paragraph_43: "It requires filling out online questionnaires several times a year.",
  consent_paragraph_44: "The response time will be less than 10 minutes each time.",
  consent_paragraph_45: "Your participation in the study is voluntary and free.",
  consent_paragraph_46: "Your data is treated absolutely confidentially.",
  consent_paragraph_47: "You are free to decide whether or not to participate in the research.",
  consent_paragraph_48: "Since you are under 18, you will need to share the study information and its organization contained in the information letter intended for your parents/legal guardians with them.",
  consent_paragraph_49: "You and your parents/legal guardians have the right to object to the processing of your personal data.",
  consent_paragraph_50: "After discussing it with them, we ask you to confirm your participation before starting the questionnaire.",
  consent_paragraph_51: "By continuing the registration, I certify that I am between 11 and 17 years old.",
  consent_paragraph_52: "By continuing the registration, I confirm that I have communicated the information letter intended for them to my parents/legal guardians and that neither I nor they object to the processing of my personal data.",
  consent_paragraph_53: "By continuing the registration, I confirm that neither I nor my parents/legal guardians object to the reuse of my data for future research, respecting the confidentiality of my identity.",

  // 2ND VERSION OF THE TEXT

  consent_paragraph_54: "In accordance with the provisions of Law No. 78-17 on information technology, files and freedoms and the General Data Protection Regulation (Regulation (EU) 2016/679), you have the following rights:",
  consent_paragraph_55: "Right to access the information concerning you, to verify its accuracy and, if necessary, to rectify, complete, or update it.",
  consent_paragraph_56: "Right to object: the right to object at any time to the transmission of your data and to obtain that your data is no longer collected in the future. Exercising this right will stop your participation in the research.",
  consent_paragraph_57: "Right to restrict data processing: the right to temporarily block the use of your data: no operations can be performed on it.",
  consent_paragraph_58: "You will have the possibility to exercise your rights for 3 years after the end of the research. Indeed, the identification data will be deleted at the end of this period.",

  consent_paragraph_59: "If you wish to exercise these rights, you should contact the research team at mentalo@inserm.fr or the data protection officer (DPO) of the University of Paris Cité.",
  consent_paragraph_60: "Additionally, you have the right to file a complaint with the National Commission for Information Technology and Civil Liberties – CNIL (French data protection authority) via the following link https://www.cnil.fr/fr/cnil-direct/question/adresser-une-reclamation-plainte-la-cnil-quelles-conditions-et-comment or CNIL - Complaints Department - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.",
  consent_paragraph_61: "If you decide to stop participating in the research, the data collected before this stop will be used in accordance with the regulations, and exclusively for the objectives of this research. Indeed, their deletion would likely compromise the validity of the research results. In this case, your data will not be used later or for another research.",

  consent_paragraph_62: "The data you provided in the questionnaires could be reused in other research on the mental well-being of adolescents and young adults, but they will never allow you to be identified.",
  consent_paragraph_63: "You have the right to obtain additional information, such as the purpose of these new studies, from the research team.",
  consent_paragraph_64: "In all cases, you have the right to object to any reuse of your data in future research.",
  consent_paragraph_65: "You are free to decide whether or not to participate in the research. We ask you to confirm your participation before starting the questionnaire.",
  consent_paragraph_66: "By continuing the registration, I certify that I am between 18 and 24 years old.",
  consent_paragraph_67: "By continuing the registration, I confirm that I do not object to the processing of my personal data.",
  consent_paragraph_68: "By continuing the registration, I confirm that I do not object to the reuse of my data for future research, respecting the confidentiality of my identity.",

  // 3RD VERSION OF THE TEXT

  consent_paragraph_69: "Your child's rights:",
  consent_paragraph_70: "You will have the possibility to exercise your child's rights for 3 years after the end of the research. Indeed, your child's identification data will be deleted at the end of this period.",
  consent_paragraph_71: "If you wish to exercise these rights and obtain communication of your information, you must contact the research team at mentalo@inserm.fr or the data protection officer (DPO) of the University of Paris Cité.",
  consent_paragraph_72: "Additionally, you have the right to file a complaint with the National Commission for Information Technology and Civil Liberties – CNIL (French data protection authority) via the following link https://www.cnil.fr/fr/cnil-direct/question/adresser-une-reclamation-plainte-la-cnil-quelles-conditions-et-comment or CNIL - Complaints Department - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.",
  consent_paragraph_73: "If your child decides to stop participating in the research, the data collected before this stop will be used in accordance with the regulations, and exclusively for the objectives of this research. Indeed, their deletion would likely compromise the validity of the research results. In this case, your child's data will not be used later or for another research.",

  consent_paragraph_74: "Your child's personal data provided in the questionnaires could be reused for future research on the mental well-being of young people, but they will never allow your child to be identified. You have the right to obtain additional information, such as the purpose of these new studies, from the research team.",
  consent_paragraph_75: "In all cases, you have the right to object, at any time, to any reuse of your child's data in future research. You can do this by contacting the research team at mentalo@inserm.fr.",

  consent_paragraph_76: "The research focuses on the mental well-being of young people aged 11 to 24 living in France.",
  consent_paragraph_77: "It requires filling out online questionnaires several times over a year.",
  consent_paragraph_78: "The response time will be less than 10 minutes each time.",
  consent_paragraph_79: "Your child's participation in this research is voluntary and free.",
  consent_paragraph_80: "You have the right to object to the processing of your child's personal data.",
  consent_paragraph_81: "Your child's data is treated absolutely confidentially.",
};
