import { ReactElement, SyntheticEvent, useContext, useState } from "react";
import { IonContent, IonIcon } from "@ionic/react";
import { closeOutline, person } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import Icon from "../../atoms/Icon";
import AuthContext from "../../../contexts/AuthContext";
import { useHistory, useParams } from "react-router";
import MenuItem from "./MenuItem";
import LanguageSelectorModal from "../LanguageSelector/LanguageSelectorModal/LanguageSelectorModal";
import Flag from "react-flags";
import { langToCountryCode } from "../../../helper/lang";
import { resetCaches } from '../../../services/questionnaire.service'; // Add this line


interface MenuProps {
  isConnected: boolean;
  onClose(): void;
}

const Menu = ({ isConnected, onClose }: MenuProps): ReactElement => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { study_slug } = useParams<{ study_slug: string }>();

  const { user, endSession } = useContext(AuthContext);
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const stopPropagation = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  const logOut = async () => {
    await endSession();
    resetCaches(); // Add this line
    history.replace(`/${study_slug}`, []);
  };

  return (
    <Modal onClick={onClose}>
      <MenuList onClick={stopPropagation}>
        <Header>
          <Icon icon={closeOutline} size="large" onClick={onClose} />
        </Header>
        <IonContent>
          <MenuItem
            icon={
              <Flag
                name={langToCountryCode[language] || language}
                format="svg"
                pngSize={64}
                shiny={false}
                basePath="/assets/images/flags"
              />
            }
            title={t(`lang_${language}`)}
            action={t("menu_language_button_text")}
            onClick={(): void => setModalVisible(true)}
          />
          {isConnected && (
            <MenuItem
              icon={<IonIcon icon={person} size="large" />}
              title={user.attributes.firstname}
              action={t("menu_logOut")}
              onClick={() => logOut().then().catch()}
            />
          )}

          <LanguageSelectorModal
            modalVisible={modalVisible}
            onCloseClick={(): void => setModalVisible(false)}
          />
        </IonContent>
      </MenuList>
    </Modal>
  );
};

const Modal = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
  z-index: 99999999;
`;

const slideAnimation = keyframes`
  from{
    margin-right: -70%;
  }
  to{
    margin-right: 0;
  }
`;

const MenuList = styled.div`
  width: 70%;
  max-width: 400px;
  animation: ${slideAnimation} 0.1s ease;
  background-color: white;
`;

const Header = styled.div`
  height: 70px;
  background-color: ${(props) => props.theme.primary.background};
  color: ${(props) => props.theme.primary.contrast};
  display: flex;
  align-items: center;
`;

export default Menu;
