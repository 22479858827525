import { DateConstraintEnum, DateConstraintType, DateSensEnum, DateUnitEnum, FixedDateType, RelativeDateType } from '../../types/Question';
import { numberToIsoDate } from '../number';

const computeFixedDate = ({ fixed_date }: FixedDateType): string => fixed_date;

type ComputeFunctionType = (value: number, quantity: number) => number;

const functionsByDateSens: Record<DateSensEnum, ComputeFunctionType> = {
  MINUS: (value: number, quantity: number) => value - quantity,
  PLUS: (value: number, quantity: number) => value + quantity
}

type ComputeDateDetails = Record<DateUnitEnum, number>;

const getRelativeDate = (computeFct: ComputeFunctionType, quantity: number, unit: DateUnitEnum): ComputeDateDetails => {
  const now: Date = new Date();
  const dateDetails: ComputeDateDetails = {
    YEAR: now.getFullYear(),
    MONTH: now.getMonth(),
    DAY: now.getDate()
  };

  return {
    ...dateDetails,
    [unit]: computeFct(dateDetails[unit], quantity)
  };
};

const computeRelativeDate = (constraint: RelativeDateType): string => {
  const { todays_date_sens, todays_date_quantity: quantity, todays_date_unit: unit } = constraint;
  const computeFunction: ComputeFunctionType = todays_date_sens ? functionsByDateSens.PLUS : functionsByDateSens.MINUS;

  const { YEAR, MONTH, DAY }: ComputeDateDetails = getRelativeDate(computeFunction, quantity, unit);

  return numberToIsoDate(YEAR, MONTH, DAY);
};

const extractFunctionByDateContraint: Record<DateConstraintEnum, (constraint: DateConstraintType) => string | undefined> = {
  FIXED_DATE: (constraint: DateConstraintType): string => computeFixedDate(constraint as FixedDateType),
  RELATIVE_DATE: (constraint: DateConstraintType): string => computeRelativeDate(constraint as RelativeDateType),
  DISABLED: (): undefined => undefined,
  NO_LIMIT: (constraint: DateConstraintType): string => {
    const todayDate = new Date()
    const dateIncreaseFactor = 50 * (constraint.isMinDate ? -1 : 1)
    const limitDate = new Date((todayDate).setFullYear((todayDate).getFullYear() + dateIncreaseFactor))
    return limitDate.toISOString()
  }
};

export const computeDateLimit = (constraint: DateConstraintType): string | undefined => {
  const { date_type } = constraint;
  return extractFunctionByDateContraint[date_type](constraint);
};
