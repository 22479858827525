import { ReactElement } from "react";
import { IonItem, IonDatetime } from "@ionic/react";
import { HourFormatEnum, HourQuestionType } from "../../../../types/Question";
import { useTranslation } from "react-i18next";
import { timeToIsoDate } from "../../../../helper/number";
interface AnswerHourProps {
  value?: string;
  question: HourQuestionType;
  onChange: (value: string) => void;
}

const displayFormatByHourType: Record<HourFormatEnum, string> = {
  "12HOURS": "h:mm a",
  "24HOURS": "HH:mm",
  "24HOURS-AM/PM": "HH:mm a",
};

interface PickerTimeDetailsType<T> {
  text: string;
  value: T;
}

interface PickerTimeType {
  minute: PickerTimeDetailsType<number>;
  hour: PickerTimeDetailsType<number>;
  ampm?: PickerTimeDetailsType<"am" | "pm">;
}

const AnswerHour = ({
  question,
  value = "",
  onChange,
}: AnswerHourProps): ReactElement => {
  const {
    detail: { format },
  } = question;

  const { t } = useTranslation();

  const pickerOptions = {
    buttons: [
      {
        text: t("common_cancel"),
        role: "cancel",
      },
      {
        text: t("common_clear"),
        handler: (): void => onChange(""),
      },
      {
        text: t("common_save"),
        handler: ({ hour, minute, ampm }: PickerTimeType): void =>
          onChange(timeToIsoDate(hour.value, minute.value, ampm?.value)),
      },
    ],
  };

  return (
    <IonItem>
      <IonDatetime
        displayFormat={displayFormatByHourType[format]}
        value={value}
        placeholder={displayFormatByHourType[format]}
        className="res-text-input"
        displayTimezone="utc"
        pickerOptions={pickerOptions}
      />
    </IonItem>
  );
};

export default AnswerHour;
