export const formError = {
  "form_error_required": "Este campo es obligatorio",
  "form_default_error": "Entrada inválida",
  "form_error_email": "Este correo electrónico no es válido",
  "form_error_email_password": "La contraseña debe contener mayúsculas, minúsculas y símbolos",
  "form_error_min": "Debe tener al menos {{min}} caracteres",
  "form_error_email_confirm_password": "Las contraseñas no son idénticas",
  "form_error_phone": "El número de teléfono no es válido",
  "form_error_email_or_phone_required": "Se requiere correo electrónico o número de teléfono"
}
