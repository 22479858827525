/* eslint-disable no-useless-escape */

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";

import { Capacitor } from "@capacitor/core";
import { registerDevice } from "../services/notifications.service";
import { ENV } from "../environments/environment";

export const urlB64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const registerWebNotifications = async ({ studyId, public_key }: { studyId: number, public_key: string }) => {
  await navigator.serviceWorker.register("/service-worker.js");
  const registration = await navigator.serviceWorker.getRegistration();
  let publicKeyUsed = public_key ? public_key : ENV.VAPID_PUBLIC_KEY
  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: publicKeyUsed
  });

  registerDevice(
    { studyId },
    { type: "WEB", deviceCredentials: subscription }
  );
};

const registerMobileNotitification = async ({ studyId }: { studyId: number }) => {
  const register = () => {

    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", (token: Token) => {
      console.log("Push registration success");
      registerDevice(
        { studyId },
        { type: "MOBILE", deviceCredentials: { token: token.value } }
      );
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error: any) => {
      console.log("Error on registration: " + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => { }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => { }
    );

    PushNotifications.createChannel({
      id: '1',
      name: 'Study Notifications',
      description: 'Study notifications',
      importance: 4,
      visibility: 1,
      lights: true,
      vibration: true,
    })
  };

  let currentPermission = await PushNotifications.checkPermissions()
  if (currentPermission.receive !== 'granted') {
    currentPermission = await PushNotifications.requestPermissions()
  }

  if (currentPermission.receive === 'granted') {
    register()
  }
};

const unregisterMobileNotifications = () => {

}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const unregisterWebNotification = async () => {
  try
  {
    const registration = await navigator.serviceWorker.getRegistration();
    await registration.unregister();
  }
  catch (error)
  {
    console.warn("Cannot get service worker to unregister")
  }

};


export const registerNotifications = async ({ studyId, public_key }: { studyId: number, public_key: string }) => {
  if (Capacitor.isNativePlatform()) {
    await registerMobileNotitification({ studyId });
    return unregisterMobileNotifications;
  } else {
    registerWebNotifications({ studyId, public_key });
    return unregisterWebNotification;
  }
}