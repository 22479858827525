/*
 * Default template, match text between bracket {}
 */
const defaultRegexTemplate = /{([\s\S]+?)}/g;

/**
 * Template interpolation helper
 * @param  {RegExp}                 regexTemplate     Template to identify the editable part
 * @param  {string}                 str               eg. "This is {editable}"
 * @param  {Record<string, string>} templateContext   eg. { editable: 'value' }
 * @return {string}                                   eg. "This is value"
 */
export const interpolate = (
  str: string,
  templateContext: Record<string, string | number>,
  regexTemplate: RegExp = defaultRegexTemplate,
): string => str
  .replace(regexTemplate, (match: string, submatch: string) => `${templateContext[submatch]}` || '')
  .replace(/[^=&|?]+=(&|$)/g, '')
  .replace(/&$/, '');
