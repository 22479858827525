import { ReactElement } from 'react';
import { EDITOR_JS_TOOLS } from './editorJsTools';
import EditorJs from 'react-editor-js';
import { OutputData } from '@editorjs/editorjs';

interface EditorJsReaderProps {
  data: OutputData;
}

const EditorJsReader = ({ data }: EditorJsReaderProps): ReactElement => (
  // @ts-ignore: Unreachable code error
  <EditorJs
    data={data}
    readOnly={true}
    tools={{ ...EDITOR_JS_TOOLS }}
    holder="editorJs"
    minHeight={0}
  >
    <div id="editorJs" />
  </EditorJs>
);

export default EditorJsReader;
