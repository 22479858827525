import { AnswerType } from "../types/Answer";
import { Dependency } from "../types/Dependency";
import { QuestionType } from "../types/Question";
import { QuestionnaireDetailsType } from "../types/Questionnaire";
import { findElementsByKey } from "../components/organisms/Home/Mountain/landscapeLogic";

export function getNextQuestion(
  questions: QuestionType[],
  dependencies: Dependency[],
  answer: AnswerType
): QuestionType {
  const dependency = dependencies.find((d) => d.id === answer.questionId);

  let questionDepId: number;

  if (dependency.answers && dependency.answers.length > 0) {
    const answerDep = dependency.answers.find((a) => a.id === answer.value);
    questionDepId = answerDep?.next_question?.id;
    if (answerDep?.endAfter) {
      return null
    }
  } else {
    if (dependency.endAfter) {
      return null
    }
  }

  if (!questionDepId) questionDepId = dependency?.next_question?.id;

  if (questionDepId) return questions.find((q) => q.id === questionDepId);

  const currentQuestionIndex = questions.findIndex(
    (q) => q.id === answer.questionId
  );

  return questions[currentQuestionIndex + 1];
}

export const isMandatoryQuestionnaire = (keywords: string) => {    
  return !findElementsByKey(keywords);
};
