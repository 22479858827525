import styled from "styled-components";

enum colors {
  primary = "primary",
  secondary = "secondary",
}

interface SmallButtonProps {
  color?: keyof typeof colors;
  onClick?(): void;
}

const SmallButton = styled("div")<SmallButtonProps>`
  background-color: ${(props) => props.theme[props.color].background};
  padding: 4px 10px;
  color: ${(props) => props.theme[props.color].contrast};
  border-radius: 20px;
  font-size: 12px;
  white-space: nowrap;
`;

SmallButton.defaultProps = {
  color: colors.primary,
};

export default SmallButton;
