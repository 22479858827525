import { ComponentType, getComponentBodyType, QuestionnaireDetailsType, SequenceDetailsType, SequenceType } from '../types/Questionnaire';
import { getApi, postApi } from './resonanceApi';
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { PostAnswersBodyType, PostAnswersParamsType, } from '../types/Answer';
import { Dependency } from '../types/Dependency';

export enum QuestionnaireUrlsEnum {
  GET_QUESTIONNAIRE = 'studies/{studyId}/questionnaires/{questionnaireId}',
  GET_SEQUENCES = 'studies/{studyId}/users/{userId}/sequences',
  GET_SEQUENCE_DETAILS = 'studies/{studyId}/users/{userId}/sequences/{sequenceId}',
  POST_ANSWERS = 'studies/{studyId}/user_sequences/{userSequenceId}/questionnaires/{questionnaireId}/questions/{questionId}/answers',
  GET_DEPENDENCIES = 'studies/{studyId}/questionnaires/{questionnaireId}/dependencies'
}

let sequenceCache: SequenceType[] | null = null;
let componentsCache: ComponentType[] | null = null;
let questionnaireCache: { [key: string]: QuestionnaireDetailsType } = {};
const CACHE_EXPIRY_TIME = 60000000; // Cache expiry time in milliseconds (10 minutes)
let cacheTimestamp = 0;

// Cache reset function
export const resetCaches = () => {
  sequenceCache = null;
  componentsCache = null;
  questionnaireCache = {};
  cacheTimestamp = 0;
};

export const getApiSequences = ({ studyId, userId, lang }): Promise<AxiosResponse<SequenceType[]>> => {
  const url = QuestionnaireUrlsEnum.GET_SEQUENCES;
  return getApi<SequenceType[]>(url, { studyId, userId }, false, { lang });
}

export const getApiQuestionnaire = async (studyId: number, questionnaireId: number, lang: string): Promise<AxiosResponse<QuestionnaireDetailsType>> => {
  const cacheKey = `${studyId}_${questionnaireId}_${lang}`;
  if (questionnaireCache[cacheKey]) {
    return { data: questionnaireCache[cacheKey], status: 200, statusText: 'OK', headers: {}, config: {} } as AxiosResponse<QuestionnaireDetailsType>;
  }

  let response: AxiosResponse<QuestionnaireDetailsType>;
  const url = QuestionnaireUrlsEnum.GET_QUESTIONNAIRE;
  response = await getApi<QuestionnaireDetailsType>(url, { studyId, questionnaireId }, false, { lang });
  questionnaireCache[cacheKey] = response.data;

  return response;
};

export const getApiSequenceDetails = (studyId: number, userId: number, sequenceId: number): Promise<AxiosResponse<SequenceDetailsType>> => {
  const url = QuestionnaireUrlsEnum.GET_SEQUENCE_DETAILS;
  return getApi<SequenceDetailsType>(url, { studyId, userId, sequenceId });
};

export const postAnswers = (params: PostAnswersParamsType, body: PostAnswersBodyType): Promise<AxiosResponse<void>> => {
  const url = QuestionnaireUrlsEnum.POST_ANSWERS;
  return postApi(url, params, body);
}

export const getComponents = async ({ studyId, userId, sequenceId, lang }): Promise<AxiosResponse<ComponentType[]>> => {
   const url = QuestionnaireUrlsEnum.GET_SEQUENCES;
  const response = await getApi<SequenceType[]>(url, { studyId, userId }, false, { lang });
  const newResponse: AxiosResponse<ComponentType[]> = {
    ...response,
    data: response.data.find((seq) => seq.id === parseInt(sequenceId))?.components
  }
  return newResponse;
}

export const getQuestionnairesByComponents = async ({ studyId, userId, lang }): Promise<AxiosResponse<ComponentType[]>> => {  
  const sequences = await getCachedApiSequences({ studyId, userId, lang });
  
  // Check if sequences or components are empty
  if (sequences.length === 0) {
    return { data: [] } as AxiosResponse<(ComponentType)[]>;
  }

  const now = Date.now();

  if (componentsCache && (now - cacheTimestamp) < CACHE_EXPIRY_TIME) {
    return { data: componentsCache } as AxiosResponse<ComponentType[]>;
  }

  // Extract components from all sequences
  const allComponents = sequences.flatMap(sequence => {
    const sequenceId = sequence.id;
    const sequence_id = sequence.sequence_id;
    return (sequence.components || []).map(component => ({ ...component, sequenceId, sequence_id }));
  });
  
  // Filter out components without questionnaire_id
  const componentsWithQuestionnaireId = allComponents.filter(component => component.questionnaire_id !== null);

  // Check if components with questionnaire id are empty
  if (componentsWithQuestionnaireId.length === 0) {
    return { data: [] } as AxiosResponse<(ComponentType & QuestionnaireDetailsType)[]>;
  }

  componentsCache = componentsWithQuestionnaireId;
  cacheTimestamp = now;

  return { data: componentsWithQuestionnaireId } as AxiosResponse<ComponentType[]>;
};

const getCachedApiSequences = async ({ studyId, userId, lang }): Promise<SequenceType[]> => {
  const now = Date.now();
  
  if (sequenceCache && (now - cacheTimestamp) < CACHE_EXPIRY_TIME) {
    return sequenceCache;
  }

  const sequencesResponse = await getApiSequences({ studyId, userId, lang });
  sequenceCache = sequencesResponse.data || [];
  cacheTimestamp = now;
  
  return sequenceCache;
};

export const getComponent = async ({ studyId, userId, sequenceId, componentId, lang }): Promise<AxiosResponse<getComponentBodyType>> => {

  const url = QuestionnaireUrlsEnum.GET_SEQUENCES;
  const response = await getApi<SequenceType[]>(url, { studyId, userId }, false, { lang });

  const sequence = response.data.find((seq) => seq.id === parseInt(sequenceId));

  let component: ComponentType;
  let nextComponent: ComponentType;
  const sequence_id = sequence.sequence_id;
    
  sequence.components.find((comp, index) => {
    if (comp.id === parseInt(componentId)) {
      component = comp;
      nextComponent = sequence.components[index + 1];
      return true;
    }
    return false;
  });

  const newResponse: AxiosResponse<getComponentBodyType> = {
    ...response,
    data: {
      sequence_id,
      component,
      nextComponent,
      isLastSequence: sequence.id === response.data[response.data.length - 1].id
    }
  }

  console.log(newResponse.data.component.questionnaire_title)
  console.log(newResponse.data.component.keywords)
  return newResponse;
};


export const getDependencies = async (params: { studyId: number, questionnaireId: number }): Promise<AxiosResponse<Dependency[]>> => {
  const url = QuestionnaireUrlsEnum.GET_DEPENDENCIES;
  return getApi(url, params)
}