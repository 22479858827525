import { person, phonePortrait, mail, barcode } from "ionicons/icons";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { AttributesType } from "../../../../types/profile";
import { PatchUserType } from "../../../../types/welcome";
import { FormGroup, TextField } from "../../../atoms/LoginTextField";
import Subtitle from "../../../atoms/Subtitle";
import { useConfig } from "../../../../contexts/ConfigContext";

interface InscriptionFieldsProps {
  attributes: AttributesType;
  values: PatchUserType;
  setFieldValue: (key: string, value: string) => void;
  errors: any;
  touched: any;
}

const InscriptionFields = ({
  attributes,
  touched,
  errors,
  values,
  setFieldValue,
}: InscriptionFieldsProps): ReactElement => {
  const { t } = useTranslation();
  const { study } = useConfig();
  const {
    has_firstname,
    has_lastname,
    has_email,
    has_phonenumber,
    has_external_id,
    has_nickname,
  } = attributes;

  const { firstname, lastname, email, phone_number, external_id, nickname } =
    values;

  return (
    <FormGroup>
      <Subtitle>{t("home_identifier_title")}</Subtitle>
      {has_firstname && (
        <TextField
          value={firstname}
          icon={person}
          type="text"
          error={touched.firstname && errors.firstname}
          onChange={(value) => setFieldValue("firstname", value)}
          placeholder={t("home_inscription_identifier")}
        />
      )}
      {has_lastname && (
        <TextField
          value={lastname}
          icon={person}
          type="text"
          error={touched.lastname && errors.lastname}
          onChange={(value) => setFieldValue("lastname", value)}
          placeholder={t("home_inscription_lastname")}
        />
      )}
      {has_nickname && (
        <TextField
          value={nickname}
          icon={person}
          type="text"
          error={touched.nickname && errors.nickname}
          onChange={(value) => setFieldValue("nickname", value)}
          placeholder={t("home_inscription_nickname")}
        />
      )}
      {!study.is_gamified && has_email && (
        <TextField
          icon={mail}
          value={email}
          type="text"
          error={touched.email && errors.email}
          onChange={(value) => setFieldValue("email", value)}
          placeholder={t("home_inscription_email")}
        />
      )}
      {!study.is_gamified && has_phonenumber && (
        <TextField
          icon={phonePortrait}
          value={phone_number}
          type="text"
          error={touched.phone_number && errors.phone_number}
          onChange={(value) => setFieldValue("phone_number", value)}
          placeholder={t("home_inscription_phone")}
        />
      )}
      {has_external_id && (
        <TextField
          icon={barcode}
          value={external_id}
          type="text"
          error={touched.external_id && errors.external_id}
          onChange={(value) => setFieldValue("external_id", value)}
          placeholder={t("home_inscription_external_id")}
        />
      )}
    </FormGroup>
  );
};

export default InscriptionFields;
