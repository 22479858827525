import { ReactElement } from 'react';
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { ICON_SHIELD_CHECKMARK } from  '../../../../constants/images';

const ConsentScroll = (): ReactElement => {
    const { t } = useTranslation();

  return (
    <ScrollText>
    <SubTitle>{t("consent_subtitle_1_1")}</SubTitle>
    <SubTitle>{t("consent_subtitle_2")}</SubTitle>

    <Text>{t("consent_paragraph_1")}</Text>
    <Text>{t("consent_paragraph_2")}</Text>
    <Text>{t("consent_paragraph_3_1")}<br/>{t("consent_paragraph_3_2")}</Text>
    <Text>{t("consent_paragraph_4")}</Text>
    <Text>{t("consent_paragraph_5")}</Text>
    <Text>{t("consent_paragraph_6")}</Text>
    <Text>{t("consent_paragraph_7")}</Text>

    <SubTitle>{t("consent_subtitle_3")}</SubTitle>

    <Text>{t("consent_paragraph_8")}</Text>
    <Text>{t("consent_paragraph_9")}</Text>
    <Text>{t("consent_paragraph_10")}</Text>

    <SubTitle>{t("consent_subtitle_4")}</SubTitle>

    <Text>{t("consent_paragraph_11")}</Text>
    <Text>{t("consent_paragraph_12")}</Text>
    <Text>{t("consent_paragraph_13")}</Text>
    <Text>{t("consent_paragraph_14")}</Text>
    <Text>{t("consent_paragraph_15")}</Text>
    <Text>{t("consent_paragraph_16")}</Text>
    <Text>{t("consent_paragraph_17")}</Text>
    <Text>{t("consent_paragraph_18")}</Text>
    <Text>{t("consent_paragraph_19")}</Text>
    <Text>{t("consent_paragraph_20")}</Text>
    <Text style={{fontWeight : 'bold'}}>{t("consent_paragraph_21")}</Text>
    <Text style={{fontWeight : 'bold'}}>{t("consent_paragraph_22")}</Text>

    <SubTitle>{t("consent_subtitle_5")}</SubTitle>

    <Text>{t("consent_paragraph_23")}</Text>
    <Text>{t("consent_paragraph_24")}</Text>

    <Text style={{fontWeight : 'bold'}}>{t("consent_paragraph_25")}</Text>

    <Text>{t("consent_paragraph_26")}</Text>
    <ul>
      <li>{t("consent_paragraph_27")}</li>
      <li>{t("consent_paragraph_28")}</li>
    </ul>

    <Text>{t("consent_paragraph_29")}</Text>
    <Text>{t("consent_paragraph_30")}</Text>
    <Text>{t("consent_paragraph_31")}</Text>

    <SubTitle>{t("consent_subtitle_6")}</SubTitle>

    <Text>{t("consent_paragraph_32")}</Text>
    <Text>{t("consent_paragraph_33")}</Text>

    <SubTitle>{t("consent_subtitle_7")}</SubTitle>

    <Text>{t("consent_paragraph_34")}</Text>
    <Text>{t("consent_paragraph_35")}</Text>
    <Text>{t("consent_paragraph_36")}</Text>

    <SubTitle>{t("consent_subtitle_8")}</SubTitle>

    <Text>{t("consent_paragraph_37")}</Text>
    <Text style={{fontWeight: 'bold'}}>{t("consent_paragraph_38")}</Text>

    <ul>
      <li>{t("consent_paragraph_39")}</li>
      <li>{t("consent_paragraph_40")}</li>
      <li>{t("consent_paragraph_41")}</li>
      <li>{t("consent_paragraph_42")}</li>
      <li>{t("consent_paragraph_43")}</li>
    </ul>

    <Text>{t("consent_paragraph_44")}</Text>
    <Text>{t("consent_paragraph_45")}</Text>
    <Text>{t("consent_paragraph_46")}</Text>
    <Text>{t("consent_paragraph_47")}</Text>
    <Text>{t("consent_paragraph_48")}</Text>


    {/* 2EME VERSION DU TEXTE */}

    <SubTitle>{t("consent_subtitle_1_2")}</SubTitle>
    <SubTitle>{t("consent_subtitle_2")}</SubTitle>

    <Text>{t("consent_paragraph_1")}</Text>
    <Text>{t("consent_paragraph_2")}</Text>
    <Text>{t("consent_paragraph_3_1")}<br/>{t("consent_paragraph_3_2")}</Text>
    <Text>{t("consent_paragraph_4")}</Text>
    <Text>{t("consent_paragraph_5")}</Text>
    <Text>{t("consent_paragraph_6")}</Text>
    <Text>{t("consent_paragraph_7")}</Text>

    <SubTitle>{t("consent_subtitle_3")}</SubTitle>

    <Text>{t("consent_paragraph_8")}</Text>
    <Text>{t("consent_paragraph_9")}</Text>
    <Text>{t("consent_paragraph_10")}</Text>

    <SubTitle>{t("consent_subtitle_4")}</SubTitle>

    <Text>{t("consent_paragraph_11")}</Text>
    <Text>{t("consent_paragraph_12")}</Text>
    <Text>{t("consent_paragraph_13")}</Text>
    <Text>{t("consent_paragraph_14")}</Text>
    <Text>{t("consent_paragraph_15")}</Text>
    <Text>{t("consent_paragraph_16")}</Text>
    <Text>{t("consent_paragraph_17")}</Text>
    <Text>{t("consent_paragraph_18")}</Text>
    <Text>{t("consent_paragraph_19")}</Text>
    <Text>{t("consent_paragraph_20")}</Text>
    <Text style={{fontWeight : 'bold'}}>{t("consent_paragraph_21")}</Text>
    <Text style={{fontWeight : 'bold'}}>{t("consent_paragraph_22")}</Text>

    <SubTitle>{t("consent_subtitle_5")}</SubTitle>

    <Text>{t("consent_paragraph_23")}</Text>
    <Text>{t("consent_paragraph_24")}</Text>

    <Text style={{fontWeight : 'bold'}}>{t("consent_paragraph_25")}</Text>
    <Text>{t("consent_paragraph_53")}</Text>

    <ul>
      <li>{t("consent_paragraph_54")}</li>
      <li>{t("consent_paragraph_55")}</li>
      <li>{t("consent_paragraph_56")}</li>
    </ul>

    <Text>{t("consent_paragraph_57")}</Text>
    <Text>{t("consent_paragraph_58")}</Text>
    <Text>{t("consent_paragraph_59")}</Text>
    <Text>{t("consent_paragraph_60")}</Text>

    <SubTitle>{t("consent_subtitle_6")}</SubTitle>

    <Text>{t("consent_paragraph_32")}</Text>
    <Text>{t("consent_paragraph_33")}</Text>

    <SubTitle>{t("consent_subtitle_7")}</SubTitle>

    <Text>{t("consent_paragraph_61")}</Text>
    <Text>{t("consent_paragraph_62")}</Text>
    <Text>{t("consent_paragraph_63")}</Text>

    <SubTitle>{t("consent_subtitle_8")}</SubTitle>

    <Text>{t("consent_paragraph_37")}</Text>
    <Text style={{fontWeight: 'bold'}}>{t("consent_paragraph_38")}</Text>

    <ul>
      <li>{t("consent_paragraph_39")}</li>
      <li>{t("consent_paragraph_40")}</li>
      <li>{t("consent_paragraph_41")}</li>
      <li>{t("consent_paragraph_42")}</li>
      <li>{t("consent_paragraph_43")}</li>
    </ul>

    <Text>{t("consent_paragraph_64")}</Text>
    <Text>{t("consent_paragraph_65")}</Text>
    <Text>{t("consent_paragraph_66")}</Text>
    <Text>{t("consent_paragraph_67")}</Text>


    {/* 3EME VERSION DU TEXTE */}

    <SubTitle>{t("consent_subtitle_1_3")}</SubTitle>
    <SubTitle>{t("consent_subtitle_2")}</SubTitle>

    <Text>{t("consent_paragraph_1")}</Text>
    <Text>{t("consent_paragraph_2")}</Text>
    <Text>{t("consent_paragraph_3_1")}<br/>{t("consent_paragraph_3_2")}</Text>
    <Text>{t("consent_paragraph_4")}</Text>
    <Text>{t("consent_paragraph_5")}</Text>
    <Text>{t("consent_paragraph_6")}</Text>
    <Text>{t("consent_paragraph_7")}</Text>

    <SubTitle>{t("consent_subtitle_3")}</SubTitle>

    <Text>{t("consent_paragraph_8")}</Text>
    <Text>{t("consent_paragraph_9")}</Text>
    <Text>{t("consent_paragraph_10")}</Text>

    <SubTitle>{t("consent_subtitle_4")}</SubTitle>

    <Text>{t("consent_paragraph_11")}</Text>
    <Text>{t("consent_paragraph_12")}</Text>
    <Text>{t("consent_paragraph_13")}</Text>
    <Text>{t("consent_paragraph_14")}</Text>
    <Text>{t("consent_paragraph_15")}</Text>
    <Text>{t("consent_paragraph_16")}</Text>
    <Text>{t("consent_paragraph_17")}</Text>
    <Text>{t("consent_paragraph_18")}</Text>
    <Text>{t("consent_paragraph_19")}</Text>
    <Text>{t("consent_paragraph_20")}</Text>
    <Text style={{fontWeight : 'bold'}}>{t("consent_paragraph_21")}</Text>
    <Text style={{fontWeight : 'bold'}}>{t("consent_paragraph_22")}</Text>

    <SubTitle>{t("consent_subtitle_5")}</SubTitle>

    <Text>{t("consent_paragraph_23")}</Text>
    <Text>{t("consent_paragraph_24")}</Text>

    <Text style={{fontWeight : 'bold'}}>{t("consent_paragraph_68")}</Text>
    <Text>{t("consent_paragraph_53")}</Text>

    <ul>
      <li>{t("consent_paragraph_54")}</li>
      <li>{t("consent_paragraph_55")}</li>
      <li>{t("consent_paragraph_56")}</li>
    </ul>

    <SubTitle>{t("consent_subtitle_6")}</SubTitle>

    <Text>{t("consent_paragraph_32")}</Text>
    <Text>{t("consent_paragraph_33")}</Text>

    <SubTitle>{t("consent_subtitle_7")}</SubTitle>
    <Text>{t("consent_paragraph_73")}</Text>
    <Text>{t("consent_paragraph_74")}</Text>

    <SubTitle>{t("consent_subtitle_8")}</SubTitle>
    <Text>{t("consent_paragraph_37")}</Text>
    <Text style={{fontWeight: 'bold'}}>{t("consent_paragraph_38")}</Text>


    <ul>
      <li>{t("consent_paragraph_75")}</li>
      <li>{t("consent_paragraph_76")}</li>
      <li>{t("consent_paragraph_77")}</li>
      <li>{t("consent_paragraph_78")}</li>
      <li>{t("consent_paragraph_79")}</li>
      <li>{t("consent_paragraph_80")}</li>
      <li>{t("consent_paragraph_81")}</li>
    </ul>

  </ScrollText>

  );
};

const ScrollText = styled.div`
  height: 300px; // Vous pouvez ajuster la hauteur selon vos besoins
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
`

const SubTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin: 10px 10px 10px 5px;
  display: flex;
  color: black;
`;

const Text = styled.p`
`

export default ConsentScroll;