export const consent = {
  consent_subtitle_1_1: "DOCUMENTO DE INFORMACIÓN PARA PERSONAS DE 11 A 17 AÑOS",
  consent_subtitle_1_2: "DOCUMENTO DE INFORMACIÓN PARA PERSONAS DE 18 A 24 AÑOS",
  consent_subtitle_1_3: "DOCUMENTO DE INFORMACIÓN PARA PADRES DE PERSONAS DE 11 A 17 AÑOS",
  consent_subtitle_2: "Bienestar mental de adolescentes y jóvenes adultos: realidades y evoluciones (Mentalo)",
  consent_paragraph_1: "La responsable científica de la investigación es la Profesora Karine CHEVREUL, directora de la unidad ECEVE del Instituto Nacional de Salud e Investigación Médica (Inserm) y de la Universidad de París Cité. También es responsable de la implementación del tratamiento de datos.",
  consent_paragraph_2: "El responsable del tratamiento de datos es la unidad ECEVE - UMR 1123 INFORMACIÓN SOBRE LA INVESTIGACIÓN PARA PARTICIPANTES DE 11 A 17 AÑOS",
  consent_paragraph_3_1: "Hola,",
  consent_paragraph_3_2: "Estás invitado/a a participar en el estudio titulado 'Bienestar mental de adolescentes y jóvenes adultos: realidades y evoluciones' (Mentalo), que se centra en el bienestar mental de las personas de 11 a 24 años y está dirigido por la Profesora Karine CHEVREUL.",
  consent_paragraph_4: "Este documento tiene como objetivo informarte sobre el estudio y sus procedimientos para explicarte en qué consiste tu participación. Eres libre de participar o no. Si no deseas participar, no tienes que justificarte. Antes de tomar una decisión, puedes hablar con una persona cercana o con alguien con quien te sientas cómodo/a.",
  consent_paragraph_5: "Si aceptas participar en este estudio, también está disponible una nota informativa para tus padres, para que puedan conocer este estudio y asegurarse de que no se oponen al tratamiento de tus datos.",
  consent_paragraph_6: "No dudes en hacer preguntas en cualquier momento por correo electrónico contactando a los investigadores responsables del estudio en mentalo@inserm.fr.",
  consent_paragraph_7: "Puedes cambiar de opinión en cualquier momento y solicitar dejar de participar en este estudio. Simplemente te pedimos que informes a los investigadores por correo electrónico a mentalo@inserm.fr.",

  consent_subtitle_3: "1. CONTEXTO, OBJETIVOS Y JUSTIFICACIÓN",

  consent_paragraph_8: "La pandemia de COVID-19 y las medidas sanitarias que siguieron, como los confinamientos, han tenido un fuerte impacto en el bienestar mental de toda la población, especialmente en los más jóvenes. Los últimos estudios que han abordado el tema muestran un deterioro en el estado de bienestar mental de los adolescentes y jóvenes adultos.",
  consent_paragraph_9: "El estudio Mentalo se ha puesto en marcha para comprender mejor la situación. Su objetivo es estudiar el bienestar mental de los jóvenes, su evolución a lo largo de un año y los factores que podrían influir en él.",
  consent_paragraph_10: "Está dirigido a jóvenes de 11 a 24 años que viven en Francia y planea entrevistar a 50,000 jóvenes.",

  consent_subtitle_4: "2. DESARROLLO DE LA INVESTIGACIÓN",

  consent_paragraph_11: "El estudio se realiza en línea, a través de un cuestionario. Serás entrevistado/a en diferentes momentos a lo largo del año del estudio: al inscribirte, luego 15 días después, y luego 1, 2, 3, 6, 9 y 12 meses después.",
  consent_paragraph_12: "No te llevará más de 10 minutos cada vez.",
  consent_paragraph_13: "Después de aceptar participar, se te pedirá que crees una cuenta en el sitio donde se encuentra el cuestionario, con un inicio de sesión (seudónimo o correo electrónico) y una contraseña. También se te pedirá que indiques un número de teléfono o un correo electrónico para avisarte cuando haya nuevos cuestionarios disponibles. El envío de estos SMS o correos electrónicos será automático y los investigadores no tendrán acceso a esta información.",
  consent_paragraph_14: "Una vez que tu cuenta esté creada, podrás responder al cuestionario del estudio. Está compuesto por módulos obligatorios y módulos opcionales. Al momento de tu inscripción, los módulos obligatorios tratarán sobre tu bienestar mental (cómo te sientes, qué te preocupa...) y algunas preguntas sobre tus características (tu edad, si eres estudiante de secundaria o preparatoria...).",
  consent_paragraph_15: "Durante el seguimiento, el módulo de seguimiento obligatorio tratará sobre tu bienestar mental, para saber cómo te sientes en comparación con la última vez, si ha habido cambios y por qué.",
  consent_paragraph_16: "Desde tu inscripción, también tendrás acceso a módulos opcionales, que puedes elegir completar o no. Tratan sobre tu vida familiar, social, afectiva, escolar o profesional. Puedes responder a estos módulos en cualquier momento durante el año del estudio.",
  consent_paragraph_17: "Para agradecerte por tu participación, ganarás puntos cada vez que completes un módulo, ya sea obligatorio o no. Tus puntos te permitirán acceder a regalos una vez que alcances un cierto umbral. Podrás elegir tu regalo entre los que estén disponibles y correspondan a tu número de puntos. Cuanto más progreses en el estudio, mayor será el valor de los regalos.",
  consent_paragraph_18: "También podrás invitar a otros jóvenes (amigos, familia...) a participar en el estudio a través de un enlace que se les enviará.",
  consent_paragraph_19: "Toda la información sobre el estudio está disponible en el sitio https://etude-mentalo.fr/.",
  consent_paragraph_20: "Tu participación nos permitirá comprender mejor el bienestar mental de los jóvenes en Francia.",
  consent_paragraph_21: "La duración de tu participación es de: 1 año",
  consent_paragraph_22: "La duración total de la investigación es de 3 años.",

  consent_subtitle_5: "3. CONFIDENCIALIDAD Y TRATAMIENTO DE DATOS",
  consent_paragraph_23: "Como parte de tu participación en esta investigación, se llevará a cabo un tratamiento de tus datos personales para permitir analizar los resultados. Este tratamiento tiene una finalidad de investigación científica y se basa en la ejecución de una misión de interés público.",
  consent_paragraph_24: "Los datos que proporciones en los cuestionarios serán confidenciales y solo los investigadores que trabajen en el estudio tendrán acceso a ellos. No será posible para los investigadores identificarte. Tus credenciales de inicio de sesión, número de teléfono o correo electrónico nunca se comunicarán a los investigadores.",
  consent_paragraph_25: "Tus datos se conservarán de forma segura hasta el final del análisis, durante un máximo de 3 años. Después, se anonimizarán. Por lo tanto, tus datos personales no se archivarán al finalizar este período.",

  consent_paragraph_26: "De acuerdo con la ley, tienes derecho a:",
  consent_paragraph_27: "acceder a los datos que has proporcionado durante la investigación y solicitar su eliminación o modificación.",
  consent_paragraph_28: "oponerte en cualquier momento a que tus datos sigan siendo recolectados.",
  consent_paragraph_29: "impedir temporalmente el uso de tus datos.",
  consent_paragraph_30: "Estos derechos no podrán ejercerse si son susceptibles de hacer imposible o comprometer gravemente la realización de los objetivos de la investigación.",
  consent_paragraph_31: "Si deseas ejercer estos derechos, puedes dirigirte al equipo de investigación en mentalo@inserm.fr. Se te proporcionará una respuesta lo antes posible, y a más tardar un mes después de la recepción de tu solicitud. En caso de dificultades, también puedes, a través de tus padres, contactar al delegado de protección de datos (DPD) de la Universidad de París Cité.",
  consent_paragraph_32: "Si consideras, después de contactarnos, que tus derechos de 'Informática y Libertades' no se respetan, tus padres podrán presentar una reclamación ante la CNIL (www.cnil.fr).",
  consent_paragraph_33: "Si decides dejar de participar en la investigación, los datos recogidos hasta ese momento se utilizarán de acuerdo con la ley. En este caso, tus datos no se utilizarán posteriormente, ni para otra investigación.",
  consent_paragraph_34: "También tienes derecho al respeto de tu privacidad y a la confidencialidad de tus datos. Si tienes quince años o más, tienes derecho a rechazar que tus padres/tutores legales accedan a los datos recogidos sobre ti durante el estudio. Si deseas ejercer este derecho, puedes dirigirte al equipo de investigación (mentalo@inserm.fr).",

  consent_subtitle_6: "4. INFORMACIÓN SOBRE LOS RESULTADOS GLOBALES",

  consent_paragraph_35: "Tendrás acceso a los resultados globales del estudio en el sitio del estudio https://etude-mentalo.fr/.",
  consent_paragraph_36: "Los resultados de la investigación también podrán presentarse en congresos, en publicaciones científicas o en los medios de comunicación.",

  consent_subtitle_7: "5. REUTILIZACIÓN DE DATOS EN EL MARCO DE NUEVAS INVESTIGACIONES",

  consent_paragraph_37: "Los datos que has proporcionado en los cuestionarios podrían reutilizarse en otros estudios sobre el bienestar mental de los adolescentes, pero nunca permitirán identificarte.",
  consent_paragraph_38: "Tienes derecho a obtener información adicional sobre estos estudios, del responsable del estudio.",
  consent_paragraph_39: "En cualquier caso, tú y tus tutores legales tienen derecho a negarse a que tus datos sean reutilizados en otros estudios.",

  consent_subtitle_8: "6. DISPOSICIONES LEGISLATIVAS Y REGLAMENTARIAS",

  consent_paragraph_40: "Esta investigación recibió la opinión favorable del Comité de Evaluación Ética de Inserm (CEEI/IRB) el 6 de junio de 2023 (opinión n°23-1018).",
  consent_paragraph_41: "En resumen...",
  consent_paragraph_42: "El estudio se centra en el bienestar mental de los jóvenes de 11 a 24 años que viven en Francia.",
  consent_paragraph_43: "Requiere completar cuestionarios en línea varias veces al año.",
  consent_paragraph_44: "El tiempo de respuesta será de menos de 10 minutos cada vez.",
  consent_paragraph_45: "Tu participación en el estudio es voluntaria y gratuita.",
  consent_paragraph_46: "Tus datos se tratan de manera absolutamente confidencial.",
  consent_paragraph_47: "Eres libre de decidir si deseas o no participar en la investigación.",
  consent_paragraph_48: "Como eres menor de 18 años, deberás compartir con tus padres/tutores legales la información sobre el estudio y su organización que se contiene en la carta informativa destinada a ellos.",
  consent_paragraph_49: "Tú y tus padres/tutores legales tienen derecho a oponerse al tratamiento de tus datos personales.",
  consent_paragraph_50: "Después de hablarlo con ellos, te pedimos que confirmes tu participación antes de comenzar el cuestionario.",
  consent_paragraph_51: "Al continuar con el registro, certifico tener entre 11 y 17 años.",
  consent_paragraph_52: "Al continuar con el registro, confirmo haber comunicado a mis padres/tutores legales la carta informativa destinada a ellos y que ni yo ni ellos nos oponemos al tratamiento de mis datos personales.",
  consent_paragraph_53: "Al continuar con el registro, confirmo que ni yo ni mis padres/tutores legales nos oponemos a la reutilización de mis datos en futuras investigaciones, respetando la confidencialidad de mi identidad.",

  // 2ª VERSIÓN DEL TEXTO

  consent_paragraph_54: "De acuerdo con las disposiciones de la Ley n°78-17 relativa a la informática, archivos y libertades y el Reglamento General de Protección de Datos (Reglamento (UE) 2016/679), tienes los siguientes derechos:",
  consent_paragraph_55: "Derecho de acceso a la información que te concierne, para verificar su exactitud y, en su caso, para corregirla, completarla o actualizarla.",
  consent_paragraph_56: "Derecho de oposición: derecho a oponerte en cualquier momento a la transmisión de tus datos y a obtener que tus datos no se recojan en el futuro. El ejercicio de este derecho conlleva el cese de tu participación en la investigación.",
  consent_paragraph_57: "Derecho a la limitación del tratamiento de datos: derecho a bloquear temporalmente el uso de tus datos: no se podrá realizar ninguna operación sobre ellos.",
  consent_paragraph_58: "Tendrás la posibilidad de ejercer tus derechos durante 3 años después del final de la investigación. De hecho, los datos de identificación se eliminarán al final de este período.",

  consent_paragraph_59: "Si deseas ejercer estos derechos, deberás dirigirte al equipo de investigación en mentalo@inserm.fr o al delegado de protección de datos (DPD) de la Universidad de París Cité.",
  consent_paragraph_60: "Además, tienes derecho a presentar una reclamación ante la Comisión Nacional de Informática y Libertades – CNIL (autoridad francesa de control de datos personales) a través del siguiente enlace https://www.cnil.fr/fr/cnil-direct/question/adresser-une-reclamation-plainte-la-cnil-quelles-conditions-et-comment o CNIL - Servicio de Reclamaciones - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.",
  consent_paragraph_61: "Si decides dejar de participar en la investigación, los datos recogidos antes de esta decisión se utilizarán de acuerdo con la normativa, y exclusivamente para los objetivos de esta investigación. De hecho, su eliminación podría comprometer la validez de los resultados de la investigación. En este caso, tus datos no se utilizarán posteriormente ni para otra investigación.",

  consent_paragraph_62: "Los datos que has proporcionado en los cuestionarios podrían reutilizarse en otras investigaciones sobre el bienestar mental de los adolescentes y jóvenes adultos, pero nunca permitirán identificarte.",
  consent_paragraph_63: "Tienes derecho a obtener información adicional, como el propósito de estas nuevas investigaciones, del equipo de investigación.",
  consent_paragraph_64: "En todos los casos, tienes derecho a oponerte a cualquier reutilización de tus datos en futuras investigaciones.",
  consent_paragraph_65: "Eres libre de decidir si deseas o no participar en la investigación. Te pedimos que confirmes tu participación antes de comenzar el cuestionario.",
  consent_paragraph_66: "Al continuar con el registro, certifico tener entre 18 y 24 años.",
  consent_paragraph_67: "Al continuar con el registro, confirmo que no me opongo al tratamiento de mis datos personales.",
  consent_paragraph_68: "Al continuar con el registro, confirmo que no me opongo a la reutilización de mis datos en futuras investigaciones, respetando la confidencialidad de mi identidad.",

  // 3ª VERSIÓN DEL TEXTO

  consent_paragraph_69: "Los derechos de tu hijo/a:",
  consent_paragraph_70: "Tendrás la posibilidad de ejercer los derechos de tu hijo/a durante 3 años después del final de la investigación. De hecho, los datos de identificación de tu hijo/a se eliminarán al final de este período.",
  consent_paragraph_71: "Si deseas ejercer estos derechos y obtener la comunicación de tus datos, debes dirigirte al equipo de investigación en mentalo@inserm.fr o al delegado de protección de datos (DPD) de la Universidad de París Cité.",
  consent_paragraph_72: "Además, tienes derecho a presentar una reclamación ante la Comisión Nacional de Informática y Libertades – CNIL (autoridad francesa de control de datos personales) a través del siguiente enlace https://www.cnil.fr/fr/cnil-direct/question/adresser-une-reclamation-plainte-la-cnil-quelles-conditions-et-comment o CNIL - Servicio de Reclamaciones - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.",
  consent_paragraph_73: "Si tu hijo/a decide dejar de participar en la investigación, los datos recogidos antes de esta decisión se utilizarán de acuerdo con la normativa, y exclusivamente para los objetivos de esta investigación. De hecho, su eliminación podría comprometer la validez de los resultados de la investigación. En este caso, los datos de tu hijo/a no se utilizarán posteriormente ni para otra investigación.",

  consent_paragraph_74: "Los datos personales de tu hijo/a proporcionados en los cuestionarios podrían reutilizarse para investigaciones futuras sobre el bienestar mental de los jóvenes, pero nunca permitirán identificar a tu hijo/a. Tienes derecho a obtener información adicional, como el propósito de estas nuevas investigaciones, del equipo de investigación.",
  consent_paragraph_75: "En todos los casos, tienes derecho a oponerte, en cualquier momento, a cualquier reutilización de los datos de tu hijo/a en futuras investigaciones. Puedes hacerlo contactando al equipo de investigación en mentalo@inserm.fr.",

  consent_paragraph_76: "La investigación se centra en el bienestar mental de los jóvenes de 11 a 24 años que viven en Francia.",
  consent_paragraph_77: "Requiere completar cuestionarios en línea varias veces durante un año.",
  consent_paragraph_78: "El tiempo de respuesta será de menos de 10 minutos cada vez.",
  consent_paragraph_79: "La participación de tu hijo/a en esta investigación es voluntaria y gratuita.",
  consent_paragraph_80: "Tienes derecho a oponerte al tratamiento de los datos personales de tu hijo/a.",
  consent_paragraph_81: "Los datos relativos a tu hijo/a se tratan de manera absolutamente confidencial.",
};
