import { FC } from "react";
import styled from "styled-components";

interface Props {
  title: string;
  description: string;
  actionText: string;
  onClick?(): void;
}

const Info: FC<Props> = function ({ title, description, actionText, onClick }) {
  return (
    <ErrorCard>
      <ErrorTitle>{title}</ErrorTitle>
      <ErrorDescription>{description}</ErrorDescription>
      {onClick && <ErrorLink onClick={onClick}>{actionText}</ErrorLink>}
    </ErrorCard>
  );
};

const ErrorCard = styled.div`
  text-align: center;
  padding: 30px;
`;

const ErrorTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ErrorDescription = styled.div``;

const ErrorLink = styled.div`
  margin: 20px 0;
  text-align: center;
  font-weight: bold;
  display: block;
  color: ${(props) => props.theme.primary.main};
`;

export default Info;
