import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import styled, { useTheme } from "styled-components";
import Container from "../../atoms/Container";
import VeriticalCenter from "../../atoms/VerticalCenter";
import Button from "../../atoms/Button";
import { useResonanceLayout } from "../ResonnanceLayout/ResoncanceContext";
import { FIREWORK } from "../../../constants/images"
import NextPrevButtons from "../../molecules/NextPrevButtons";

interface Props {
  onDismiss(): void;
}

const DisplayThankYouParticipation: FC<Props> = ({ onDismiss }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { study_slug } = useParams<{ study_slug: string }>();
  const { setResonanceLayout } = useResonanceLayout();
  const theme = useTheme();

  useEffect(() => {
    setResonanceLayout({
      disableHeader: true,
      disableNav: true,
      backgroundColor: theme["primary"].background,
    });

    return () =>
      setResonanceLayout({
        disableHeader: true,
        disableNav: false,
        backgroundColor: "white",
      });
  }, []);

  const goInvitPage = async () => {
    history.push(`/${study_slug}/resonance/invite`);
  };

  return (
    <Container>
      <VeriticalCenter style={{alignItems: "center", marginTop: 110}}>
        <Icon alt="registered successfully" color="white" />
        <Title>{t("invite_thank_you_participation")}</Title>
        <Title>{t("invite_friends_to_help")}</Title>
      </VeriticalCenter>
      
      <NextPrevButtons
        onPreviousClick={onDismiss}
        onNextClick={goInvitPage}
        prevText={t("invite_later")}
        nextText={t("invite_friends")}/>
    </Container>
  );
};

const Icon = styled.img`
  width: 100px;
  margin: 15px;
  content: url(/assets/icon/firework.png);
`

const Title = styled.div`
  font-size: 20px;
  padding: 0 45px;
  font-weight: bold;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
  color:${(props) => props.theme.primary.contrast};
`;

const StyledButton = styled(Button)`
  padding: 0 50px;
  margin: 20px 0;
`;

export default DisplayThankYouParticipation;