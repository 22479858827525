export const updateManifest = async (newName, newIconPath) => {

    document.title = newName;
    let faviconLink:any = document.querySelector("link[rel='icon']") || document.querySelector("link[rel='shortcut icon']");

    if (faviconLink) {
        faviconLink.href = newIconPath;
    } else {
        faviconLink = document.createElement('link');
        faviconLink.rel = 'icon';
        faviconLink.href = newIconPath;
        document.head.appendChild(faviconLink);
    }    

    await fetch('/manifest.json')
      .then(response => response.json())
      .then(manifest => {
        manifest.name = newName;
        manifest.short_name = newName;
        manifest.start_url = window.location.href;
        
  
        manifest.icons.forEach(icon => {
          icon.src = newIconPath;
          icon.sizes = "512x512";
        });
        document.querySelector('link[rel="manifest"]').setAttribute('href', 'data:application/json,' + encodeURIComponent(JSON.stringify(manifest)));
      });
  };