import axios from "axios";
import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { getStudySlug } from "../helper/study";
import { getWelcomeInfo } from "../services/weclome.service";
import { getCurrentUser, logout } from "../services/user.service";

import { UserType } from "../types/session";
import { useConfig } from "./ConfigContext";

const TOKEN_KEY_NAME = "resonance_access_token";

interface AuthState {
  user: UserType | null;
  isInitialized: boolean;
}

interface AuthContextValue extends AuthState {
  startSession: (token: string) => Promise<void>;
  endSession: () => Promise<void>;
  getCurrent: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactElement;
}

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem(TOKEN_KEY_NAME, accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem(TOKEN_KEY_NAME);
    delete axios.defaults.headers.common.Authorization;
  }
};

const AuthContext = createContext<AuthContextValue>({
  user: null,
  isInitialized: false,
  startSession: () => Promise.resolve(),
  endSession: () => Promise.resolve(),
  getCurrent: () => Promise.resolve(),
});

/**
 * 
 * @param obj 
 * @returns 
 * @description check if all first level fields in "obj" empty or not.
 */
const areAllFieldsEmpty = (obj: Record<string, any>): boolean => {
  return Object.values(obj).every(value => {
    return value === null || value === undefined || String(value).trim() === '';
  });
};

export const AuthProvider = ({ children }: AuthProviderProps): ReactElement => {
  const [user, setUser] = useState<UserType | null>(null);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const { study, setRole } = useConfig();

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem(TOKEN_KEY_NAME);
        const invitation_token = window.location.search.includes("invitation=")
  
        if (accessToken && !invitation_token) {
          const slug = getStudySlug();
          const {
            data: { id, roles },
          } = await getWelcomeInfo({ slug, lang: "en" });
          console.log("access token found.");
          setSession(accessToken);
          const { data: user } = await getCurrentUser(id);

          if (!areAllFieldsEmpty(user.attributes)) { 
            console.log("user set correctly");
            const role = roles.find((r) => r.id === user.role_id);
            setUser(user);
            setRole(role);
        }
        } else {
          console.log("version 2.0");
          console.log("no access token");
          setUser(null);
          setRole(null);
        }
      } catch (err) {
        console.log("user error", err);
        window.localStorage.removeItem(TOKEN_KEY_NAME);
        setUser(null);
      } finally {
        setTimeout(function () {
          setIsInitialized(true);
        }, 100);
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [study?.id]);

  const startSession = async (token: string): Promise<void> => {
    setSession(token);
    const { data: user } = await getCurrentUser(study.id);
    console.log("starting session with user", user);
    setUser(user);
  };

  const getCurrent = async (): Promise<void> => {
    try {
      const { data: user } = await getCurrentUser(study.id);
      setUser(user);
    } catch (e) {}
  };

  const endSession = async (): Promise<void> => {
    try {
      if (user)
        await logout() 
    } catch (e) {
    } finally {
      setSession(null);
      setUser(null);
      setRole(null);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isInitialized,
        startSession,
        endSession,
        getCurrent,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;
