

import { postApi } from "./resonanceApi";
import { AxiosResponse } from 'axios';
import { RegisterDeviceRequestBody, RegisterDeviceRequestParams, RegisterDeviceResponseBody } from "../types/notification.types";

export enum NotificationsUrls {
  REGISTER_DEVICE = 'studies/{studyId}/users/current/device_token',
}

export const registerDevice = ({ studyId }: RegisterDeviceRequestParams, body: RegisterDeviceRequestBody): Promise<AxiosResponse<RegisterDeviceResponseBody>> => {
  const url = NotificationsUrls.REGISTER_DEVICE;
  return postApi<RegisterDeviceRequestBody>(url, { studyId }, body);
};