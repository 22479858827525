import styled, { useTheme } from "styled-components";
import { useResonanceLayout } from "../../components/organisms/ResonnanceLayout/ResoncanceContext";
import { useEffect, useState } from "react";
import Subtitle from "../../components/atoms/Subtitle";
import Button from "../../components/atoms/Button";
import { ICON_GIFT } from '../../constants/images';
import { postUserGamification } from "../../services/user.service";
import { useConfig } from "../../contexts/ConfigContext";
import { useAuth } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import ResonanceLoader from "../../components/atoms/ResonanceLoader/ResonanceLoader";
import { ARROW1, ARROW2, ARROW3, ARROW4, ARROW5, ARROW6, ARROW7  } from "../../constants/images";

export default function Gift() {

  const { user } = useAuth();
  const { study } = useConfig();
  const { setResonanceLayout } = useResonanceLayout();
  const theme = useTheme();
  const { t } = useTranslation();

  const [points, setPoints] = useState(0);
  const [isLoading, setIsLoading] = useState(true);


async function encryptAES(plaintext: string, key: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(plaintext);
  const iv = crypto.getRandomValues(new Uint8Array(16));

  // Convertir la clé en ArrayBuffer
  const keyBuffer = encoder.encode(key.padEnd(32, ' ')).slice(0, 32);
  const cryptoKey = await crypto.subtle.importKey(
    "raw",
    keyBuffer,
    { name: "AES-CBC" },
    false,
    ["encrypt"]
  );

  // Chiffrer le texte
  const encrypted = await crypto.subtle.encrypt(
    {
      name: "AES-CBC",
      iv: iv,
    },
    cryptoKey,
    data
  );

  // Concaténer le vecteur d'initialisation et le texte crypté et les encoder en base64
  const encryptedArray = new Uint8Array(encrypted);
  const combinedArray = new Uint8Array(iv.length + encryptedArray.length);
  combinedArray.set(iv);
  combinedArray.set(encryptedArray, iv.length);
  
  return btoa(String.fromCharCode(...combinedArray));
}


  const handleClick = async () => {
    const id = user.attributes.nickname;
    const mdp = "/8gcd7p%H(3Q8YSyd@psoDA_";

    const userIDClair = encodeURIComponent(id);
    const pointsClair = encodeURIComponent(points.toString());
    const userIDCrypte = await encryptAES(userIDClair, mdp);
    const pointsCrypte = await encryptAES(pointsClair, mdp);

    const userIDCrypteEncode = encodeURIComponent(userIDCrypte);
    const pointsCrypteEncode = encodeURIComponent(pointsCrypte);

    const url = `https://etude-mentalo.fr/cadeau/index.php?u1=${userIDClair}&u2=${userIDCrypteEncode}&p1=${pointsClair}&p2=${pointsCrypteEncode}`;

    window.location.href = url;
    }

  const fetchData = async () => { 
    try {
      const { data } = await postUserGamification(study.id, user.id, null);
      setPoints(data.points);
      setIsLoading(false);
      return { data }
    }
    catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    fetchData();
    setResonanceLayout({
      disableHeader: false,
      disableNav: false,
      backgroundColor: "white",
      title: "gift_title",
    });
    return () =>
      setResonanceLayout({
        disableHeader: false,
        disableNav: false,
        backgroundColor: "white",
        title: "gift_title",
      });
  }, []);

  if (isLoading) return (
    <ResonanceLoader/>
  )

  return (
    <Container>
      <Subtitle style={{marginTop : '-80vh'}}>{t("gift_points_1")}</Subtitle>
      <Points>{points}</Points>
      <Subtitle>{t("gift_points_2")}</Subtitle>

      <ArrowContainer>
        <Arrow $points={points} $amount={100} src={ARROW1}/>
        <Arrow $points={points} $amount={800} src={ARROW2}/>
        <Arrow $points={points} $amount={900} src={ARROW3}/>
        <Arrow $points={points} $amount={920} src={ARROW4}/>
        <Arrow $points={points} $amount={950} src={ARROW5}/>
        <Arrow $points={points} $amount={970} src={ARROW6}/>
        <Arrow $points={points} $amount={1000} src={ARROW7}/>
      </ArrowContainer>
      <StyledButton onClick={handleClick} icon={ICON_GIFT}>{t('gift_find_gift')}</StyledButton>
    </Container>
  );
}

const StyledButton = styled(Button)`
  position : absolute;
  bottom: 100px;
`;

const Container = styled.div`
  background-color: #fff;
  height: 100vh;
  color: ${(p) => p.theme.primary.contrast};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Points = styled.div`
  font-weight: bold;
  font-size: min(17vw, 75px);
  text-align: center;
  color : red;
`;

const ArrowContainer = styled.div`
  position : absolute;
  width: 100%;
  height: 100%;
  bottom: 5%;
`;

const Arrow = styled.img<{$points?:number, $amount?:number}>`
  position : absolute;
  filter: ${(props) => props.$points >= props.$amount ? 'none' : 'grayscale(100%)'};
  height : 100%;
  left: 50%;
  transform: translateX(-50%);
`;