export const flagIconByLang: Record<string, string> = {
  en: '/assets/flags/uk_flag.svg',
  de: '/assets/flags/de_flag.svg',
  es: '/assets/flags/es_flag.svg',
  fr: '/assets/flags/fr_flag.svg',
};

export const langToCountryCode: Record<string, string> = {
  ar: 'sa',
  en: 'us'
}
