import { FC, useMemo } from "react";
import { IdentificationMethodEnum } from "../../../../types/profile";
import Container from "../../../atoms/Container";
import InscriptionIdentifier from "../../../molecules/Home/Inscription/InscriptionIdentifier";
import * as Yup from "yup";
import { useFormik } from "formik";
import NextPrevButtons from "../../../molecules/NextPrevButtons";
import { postInitIdentification } from "../../../../services/user.service";
import { useConfig } from "../../../../contexts/ConfigContext";
import useQuery from "../../../../hooks/useQuery";
import { postInitIdentificationType } from "../../../../types/user.types";
import { useAuth } from "../../../../contexts/AuthContext";
import { useHomeContext } from "../../../../pages/Home/HomeContent";
import { WelcompeStepEnum } from "../../../../types/welcome";

interface Props {}

const DisplayInitIdentification: FC<Props> = () => {
  const query = useQuery();
  const { startSession } = useAuth();

  const { setCurrentStep } = useHomeContext();
  const { study, selectedRole } = useConfig();
  const { signin_criteria } = selectedRole;

  const onNextClick = () => {
    setCurrentStep(WelcompeStepEnum.REGISTRED_SUCCESSFULLY);
  };

  const { initialValues, validationSchema } = useMemo(
    function () {
      let initialValues;
      let validationSchema;

      switch (selectedRole?.signin_criteria.method) {
        case IdentificationMethodEnum.SECRET_QUESTIONS:
          initialValues = {};
          const obj = {};
          signin_criteria.identification_questions.forEach((question) => {
            obj[question.id] = Yup.string().required();
            initialValues[question.id] = "";
          });
          validationSchema = Yup.object().shape(obj);
          break;
        case IdentificationMethodEnum.EMAIL:
        case IdentificationMethodEnum.NICKNAME_PASSWORD:
          initialValues = {
            password: "",
            confirmPassword: "",
          };
          validationSchema = Yup.object().shape({
            password: Yup.string()
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])(?!.*\s).{8,100}$/,
                "form_error_email_password"
              )
              .required()
              .oneOf([Yup.ref('confirmPassword')], "form_error_email_confirm_password"),
            confirmPassword: Yup.string()
              .required()
          });
          break;
      }

      return { initialValues, validationSchema };
    },
    [
      selectedRole?.signin_criteria.method,
      signin_criteria.identification_questions,
    ]
  );

  async function onSubmit(values) {
    const invitation_token = query.get("invitation");
    const body: postInitIdentificationType = {
      invitation_token: invitation_token,
    };

    if (signin_criteria.method === IdentificationMethodEnum.SECRET_QUESTIONS) {
      body.identification_questions = [];
      signin_criteria.identification_questions.forEach((question) => {
        body.identification_questions.push({
          id: question.id,
          answer: values[question.id],
          version: 1,
        });
      });
    }

    if (signin_criteria.method === IdentificationMethodEnum.EMAIL ||  signin_criteria.method === IdentificationMethodEnum.NICKNAME_PASSWORD) {
      body.password = values.password;
    }

    const {
      data: { token },
    } = await postInitIdentification({ studyId: study.id }, body);

    await startSession(token);
    // history.push(`/${study.slug}/resonance`);
    onNextClick();
  }

  const { values, touched, errors, submitForm, setFieldValue } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <Container>
      <InscriptionIdentifier
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        touched={touched}
        signinCriteria={selectedRole.signin_criteria}
      />

      <NextPrevButtons onNextClick={submitForm} />
    </Container>
  );
};

export default DisplayInitIdentification;
