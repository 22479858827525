import { useState, useEffect, ReactElement, useRef } from 'react';
import styled from 'styled-components';
import { HIKER, MOUNTAIN, ROAD, VICTORY_HIKER } from '../../../constants/images';
import { motion, useAnimation } from 'framer-motion';
import { landscapeData } from './Mountain/LandscapeData';
import { hikerSteps } from './Mountain/HikerData';
import { calculateResponsiveHikerPosition, setHikerParams, animateHikerToNewStep } from './Mountain/hikerLogic';
import { animatedLandscape, findElementsByValue } from './Mountain/landscapeLogic';
import { UserGamificationDetails } from '../../../types/welcome';
import { useResonanceLayout } from '../ResonnanceLayout/ResoncanceContext';

const background = { src: MOUNTAIN };
const road = { src: ROAD };
const victoryHiker = { src: VICTORY_HIKER };

const DisplayMountain = ({ onAnimationComplete, disableHeaderFinished }): ReactElement => {
  const { setResonanceLayout, userGamificationDetails, questionnaires } = useResonanceLayout();  

  const [parentWidth, setParentWidth] = useState(0);
  const [ratio, setRatio] = useState({ width: 1, height: 1 });

  const [initialBackgroundSize] = useState({ width: 390, height: 844 });
  const [initialPosition] = useState({ x: 320, y: 720 });
  const initialHikerSize = { width: 100, height: 180 };

  const [backgroundSize, setBackgroundSize] = useState({  ...initialBackgroundSize });
  const [hikerSize, sethikerSize] = useState({ ...initialHikerSize });
  const [hikerScale, setHikerScale] = useState(1);

  // const [currentPosition, setCurrentPosition] = useState({ x: undefined, y: undefined});
  const [currentResponsivePosition, setCurrentResponsivePosition] = useState({ ...initialPosition });

  function getInitialAnimationStatus() {
    return {
      hikerAnimationStep: 0,
      landscapeAnimationPlayed: [false, false, false, false, false, false, false, false],
    };
  }
  
  const [currentAnimationStatus, setCurrentAnimationStatus] = useState(getInitialAnimationStatus());
  const [newAnimationStatus, setNewAnimationStatus] = useState(getInitialAnimationStatus());

  const [shouldFlip, setShouldFlip] = useState(false);

  const [handleResizeFinished, setHandleResizeFinished] = useState(false);
  const [hikerSizeFinished, setHikerSizeFinished] = useState(false);
  const [updateAnimationStatusFinished, setUpdateAnimationStatusFinished] = useState(false);
  const [currentResponsivePositionFinished, setCurrentResponsivePositionFinished] = useState(false);

  const controls = useAnimation();

  const [hikerAnimationInProgress, setHikerAnimationInProgress] = useState(true);
  const [landscapeAnimationInProgress, setLandscapeAnimationInProgress] = useState(true);

  const landscapeBackgroundRef = useRef(null);
  const parentRef = useRef(null);

  /**
   * Define BackgroundDimension using landscapeRef
   * Define ratio to the initial background size
   * Define parent width using parentRef
   */
  useEffect(() => {
    const handleResize = () => {
      if (landscapeBackgroundRef.current && parentRef.current && disableHeaderFinished) 
      {
        if (backgroundSize.height !== 0 && backgroundSize.width !== 0 && parentWidth !== 0)
        {
          setHandleResizeFinished(true);
        }
        else
        {
          const { width, height } = landscapeBackgroundRef.current.getBoundingClientRect();

          if (width === 0 && height === 0)
          {
            setBackgroundSize({ width: 0, height: 0 });
          }
          else
          {
            setBackgroundSize({ width, height });
            setRatio({width: width/initialBackgroundSize.width, height: height/initialBackgroundSize.height});
          }
          
          if (parentRef.current.offsetWidth === 0)
          {
            setParentWidth(0);
          }
          else
          {
            setParentWidth(parentRef.current.offsetWidth);
          }
        }
      }
    };
      
    window.addEventListener('resize', handleResize);
    handleResize();
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [parentRef, landscapeBackgroundRef, backgroundSize, parentWidth, disableHeaderFinished]);

  /**
   * Define NewHikerSize according to ratio of background
   */
  useEffect(() => {
    sethikerSize({
      width: (initialHikerSize.width * ratio.width),
      height: (initialHikerSize.height * ratio.height),
    });

    setHikerSizeFinished(true);
  }, [ratio]);

  /**
   * Update data in HikerLogic
   * Calculate responsive hiker position
   */
  useEffect(() => {
    if (updateAnimationStatusFinished)
    {
      setHikerParams({
        ratio: ratio,
        parentWidth: parentWidth,
        backgroundSize: backgroundSize,
        hikerSize: hikerSize,
      });

      const position = calculateResponsiveHikerPosition(hikerSteps[currentAnimationStatus.hikerAnimationStep].position, hikerSteps[currentAnimationStatus.hikerAnimationStep].flipOnLoading, hikerSteps[currentAnimationStatus.hikerAnimationStep].scale);
      setCurrentResponsivePosition({
        x: position.x,
        y: position.y
      });

      setCurrentResponsivePositionFinished(true);
    }
  }, [updateAnimationStatusFinished])

  /**
   * Animate hiker from current step to new step
   */
  useEffect(() => {
    if (currentResponsivePositionFinished)
    {
      if (newAnimationStatus.hikerAnimationStep !== currentAnimationStatus.hikerAnimationStep && hikerSteps[currentAnimationStatus.hikerAnimationStep].flipOnLoading === shouldFlip)
      {
        const newScale = hikerSteps[newAnimationStatus.hikerAnimationStep].scale;
  
        const animate = async () => {
          setHikerAnimationInProgress(true);
          try {
            await animateHikerToNewStep(controls, currentAnimationStatus.hikerAnimationStep, newAnimationStatus.hikerAnimationStep, newScale);
          } catch (error) {
            console.error(error);
          }

          setHikerAnimationInProgress(false);
        };
        animate();
      }
      else
      {
        setHikerAnimationInProgress(false);
      }
    }
    
  }, [currentResponsivePositionFinished, shouldFlip]);

  function UpdateAnimationStatus() {
    let bufferCurrentAnimationStatus = getInitialAnimationStatus();
    let bufferNewAnimationStatus = getInitialAnimationStatus();
  
    const animationSuccess = userGamificationDetails.animation_success;
    const animationSuccessEarned = userGamificationDetails.animation_successEarned;
    const step = userGamificationDetails.step;
    const stepEarned = userGamificationDetails.stepEarned;
    
    if (userGamificationDetails.pointsEarned === 0 && userGamificationDetails.stepEarned === 0 && userGamificationDetails.animation_successEarned === 0) {
      const animationValues = findElementsByValue(animationSuccess);

      animationValues.forEach(value => {
        const matchingLandscapes = landscapeData.filter(ld => ld.keyword.toLowerCase() === value.toLowerCase());
        matchingLandscapes.forEach(matchingLandscape => {
          const index = landscapeData.indexOf(matchingLandscape);
          bufferCurrentAnimationStatus.landscapeAnimationPlayed[index] = !bufferCurrentAnimationStatus.landscapeAnimationPlayed[index];
        });
      });

      bufferCurrentAnimationStatus.hikerAnimationStep = step;

      setCurrentAnimationStatus(bufferCurrentAnimationStatus);
      setNewAnimationStatus(bufferCurrentAnimationStatus);
    } else {
      const currentAnimationValues = findElementsByValue(animationSuccess - animationSuccessEarned);
      currentAnimationValues.forEach(value => {
        const matchingLandscapes = landscapeData.filter(ld => ld.keyword.toLowerCase() === value.toLowerCase());
        matchingLandscapes.forEach(matchingLandscape => {
          const index = landscapeData.indexOf(matchingLandscape);
          bufferCurrentAnimationStatus.landscapeAnimationPlayed[index] = !bufferCurrentAnimationStatus.landscapeAnimationPlayed[index];
        });
      });
      bufferCurrentAnimationStatus.hikerAnimationStep = step - stepEarned;
      setCurrentAnimationStatus(bufferCurrentAnimationStatus);

      const newAnimationValues = findElementsByValue(animationSuccess);
      newAnimationValues.forEach(value => {
        const matchingLandscapes = landscapeData.filter(ld => ld.keyword.toLowerCase() === value.toLowerCase());
        matchingLandscapes.forEach(matchingLandscape => {
          const index = landscapeData.indexOf(matchingLandscape);
          bufferNewAnimationStatus.landscapeAnimationPlayed[index] = !bufferNewAnimationStatus.landscapeAnimationPlayed[index];
        });
      });
      bufferNewAnimationStatus.hikerAnimationStep = step;
      setNewAnimationStatus(bufferNewAnimationStatus);
    }

    const shouldFlip = hikerSteps[step - stepEarned].flipOnLoading;
    setShouldFlip(shouldFlip);
  }

  useEffect(() => {
    try {
      if (userGamificationDetails && handleResizeFinished && hikerSizeFinished)
      {
        

        UpdateAnimationStatus();

        setHikerScale(hikerSteps[userGamificationDetails.step].scale);
        // setCurrentPosition(hikerSteps[userGamificationDetails.step].position)
        setUpdateAnimationStatusFinished(true);
      }
    } catch (error) {
      console.error('Error updating animation status:', error);
    }
  }, [userGamificationDetails, handleResizeFinished, hikerSizeFinished]);

  useEffect(() => {
    return () => {
      if (userGamificationDetails.pointsEarned !== 0 || userGamificationDetails.stepEarned !== 0 || userGamificationDetails.animation_successEarned !== 0) {
        const userGamificationUpdates: UserGamificationDetails = {
          points: userGamificationDetails.points,
          step: userGamificationDetails.step,
          animation_success: userGamificationDetails.animation_success,
          pointsEarned: 0,
          stepEarned: 0,
          animation_successEarned: 0
        };
  
        setResonanceLayout({ userGamificationDetails: userGamificationUpdates });
      }
    };
  }, []); 

  const landscapeAnimationStart = () => {
    setLandscapeAnimationInProgress(true);
  };

  const landscapeAnimationComplete = () => {
    setLandscapeAnimationInProgress(false);
  };

  useEffect(() => {
    if (!hikerAnimationInProgress && !landscapeAnimationInProgress)
      onAnimationComplete(true);
    else
      onAnimationComplete(false);
  }, [hikerAnimationInProgress, landscapeAnimationInProgress]);

  return (
    <div ref={parentRef}>
      <LandscapeImageFixed src={background.src} ref={landscapeBackgroundRef} />

      {currentResponsivePositionFinished && landscapeData.map(({ id, src }) => (
        <motion.div
          key={id}
          initial={{ opacity: landscapeData[id-1].animation.startOpacity }}
          animate={ animatedLandscape(id, currentAnimationStatus.landscapeAnimationPlayed, newAnimationStatus.landscapeAnimationPlayed) }
          onAnimationStart={() => {
            if (currentAnimationStatus.landscapeAnimationPlayed[id - 1] !== newAnimationStatus.landscapeAnimationPlayed[id - 1]) {
              landscapeAnimationStart();
            }
          }}
          onAnimationComplete={() => {
            if (currentAnimationStatus.landscapeAnimationPlayed[id - 1] !== newAnimationStatus.landscapeAnimationPlayed[id - 1]) {
              landscapeAnimationComplete();
            }
          }}
        >
          <LandscapeImage src={src} />
        </motion.div>
      ))}

      <LandscapeImageFixed src={road.src} />

      {newAnimationStatus.hikerAnimationStep === 8 && !hikerAnimationInProgress ? (
        <LandscapeImageFixed src={victoryHiker.src} />
      ) : (
        <Hiker
          src={HIKER}
          alt="Hiker"
          animate={controls}
          style={{
            left: `${currentResponsivePosition.x}px`,
            top: `${currentResponsivePosition.y}px`,
            width: `${hikerSize.width}px`,
            height: `${hikerSize.height}px`,
            transform: `scale(${hikerScale}) ${shouldFlip ? 'scaleX(-1)' : 'scaleX(1)'}`,
          }}
        />
      )}
    </div>
  );
};

const Hiker = styled(motion.img)`
  position: absolute;
`;

const LandscapeImage = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const LandscapeImageFixed = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

export default DisplayMountain;