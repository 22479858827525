export const lang = {
  lang_fr: 'Francés',
  lang_en: 'Inglés',
  lang_es: 'Español',
  lang_ar: 'Árabe',
  lang_be: 'Bielorruso',
  lang_bg: 'Búlgaro',
  lang_bs: 'Bosnio',
  lang_cs: 'Checo',
  lang_da: 'Danés',
  lang_de: 'Alemán',
  lang_hu: 'Húngaro',
  lang_it: 'Italiano',
  lang_ja: 'Japonés',
  lang_pl: 'Polaco',
  lang_ro: 'Rumano'
};
