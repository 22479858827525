//By Synakene

export const onboarding = {
    onboarding_title: "Mentalo",
    onboarding_subtitle: 'Notre quête',
    onboarding_header_text: 'La connaissance sur le bien-être mental des jeunes.',
    onboarding_header_text2: "Pour nous aider, t'aider et aider les autres, il te faudra :",
    onboarding_step1: 'Étape 1',
    onboarding_step1_text: 'Parler de toi',
    onboarding_step2: 'Étape 2',
    onboarding_step2_text: "Donner 7 fois des nouvelles dans l'année en 5min",
    onboarding_step3: 'Étape 3',
    onboarding_step3_text: 'Suivre ton avancée',
    onboarding_step4: 'Étape 4',
    onboarding_step4_text: 'Étre un ambassadeur en invitant tes amis',
  };
  