import { hikerSteps, hikerIntermediatePositions } from './HikerData';

interface Position {
  x: number;
  y: number;
}

interface Size {
  width: number;
  height: number;
}

interface HikerParams {
  ratio: Size;
  parentWidth: number;
  backgroundSize: Size;
  hikerSize: Size;
}

let params: HikerParams = {
  ratio: { width: 1, height: 1 },
  parentWidth: 0,
  backgroundSize: { width: 0, height: 0 },
  hikerSize: { width: 0, height: 0 },
};

export const setHikerParams = (newParams: Partial<HikerParams>) => {
  params = { ...params, ...newParams };
};

export const calculateResponsiveHikerPosition = (position: Position, flip: boolean, scale: number) => {
  return {
    x: ((position.x * params.ratio.width) + 50 * params.parentWidth / 100 - params.backgroundSize.width/2) - (params.hikerSize.width*scale) * 0 - (params.hikerSize.width*(1-scale)/2) - (flip ? params.hikerSize.width*scale : 0),
    y: (position.y * params.ratio.height) - ((params.hikerSize.height*scale) * 0.95 + (params.hikerSize.height*(1-scale)/2)),
  };
};

export const getKeyframes = (currentStep: number, newStep: number, scale: number) => {
  const keyframes = [
    calculateResponsiveHikerPosition(hikerSteps[currentStep].position, hikerSteps[currentStep].flipOnLoading, scale),
  ];

  for (let index = currentStep; index < newStep; index++) {
    keyframes.push(
      ...hikerIntermediatePositions[index].positions.map((position) =>
        calculateResponsiveHikerPosition(position, hikerSteps[currentStep].flipOnLoading, scale)
      )
    );
  }
  
  const position = calculateResponsiveHikerPosition(hikerSteps[newStep].position, hikerSteps[newStep].flipOnLoading, scale);
  keyframes.push(position);

  return keyframes;
}

// export function calculateShrinkage(step) {
//   const shrinkFactor = 0.3;
//   return Math.sqrt(step) * shrinkFactor;
// }

export const animateHikerToNewStep = async(controls: any, currentStep: number, newStep: number, newScale: number) => {
  const keyframes = getKeyframes(currentStep, newStep, newScale);

  if (keyframes.length !== 0) {
    const duration = 1;

    await controls.start({
      left: keyframes.map((position) => `${position.x}px`),
      top: keyframes.map((position) => `${position.y}px`),
      scale: newScale,
      transition: { duration, ease: 'linear', staggerChildren: duration / 2, staggerDirection: -1 },
    });

    if (hikerSteps[newStep].flipAfterAnimation)
      await controls.start({ scaleX: -1, transition: { duration: 0.5 } });
  }
};
