import { IonContent } from "@ionic/react";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/AuthContext";
import { useFetch } from "../../../hooks/useFetch";
import { setSequenceContentRead } from "../../../services/sequence.service";
import { getStudyContent } from "../../../services/study.service";
import { StudyContentType } from "../../../types/welcome";
import Info from "../../atoms/ErrorComponent";
import ResonanceLoader from "../../atoms/ResonanceLoader/ResonanceLoader";
import DisplayContent from "../../molecules/DisplayContent";
import NextPrevButtons from "../../molecules/NextPrevButtons";

interface SequenceContentProps {
  contentId: number;
  studyId: number;
  sequenceId: number;
  onStartClick: () => void;
  onPrevClick: () => void;
}

const SequenceContent = ({
  studyId,
  sequenceId,
  contentId,
  onStartClick,
  onPrevClick,
}: SequenceContentProps): ReactElement => {
  const { user } = useAuth();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    data: content,
    isLoading,
    isError,
  } = useFetch<StudyContentType>(
    "sequenceContent",
    getStudyContent,
    { studyId, contentId, lang: language },
    {
      onSuccess(data) {
        setSequenceContentRead(
          {
            study_id: studyId,
            sequence_id: sequenceId,
            user_id: user.id,
            content_id: contentId,
          },
          {
            version: data.version,
            read_date: new Date().toISOString(),
          }
        );
      },
    }
  );

  if (isLoading) return <ResonanceLoader />;

  if (isError)
    return (
      <Info
        title={t("common_server_error_title")}
        description={t("common_server_error_desc")}
        actionText={t("common_go_back")}
        onClick={() => onPrevClick}
      />
    );

  if (!content)
    return (
      <Info
        title={t("common_nodata_title")}
        description={t("common_nodata_desc")}
        actionText={t("common_go_back")}
        onClick={onPrevClick}
      />
    );

  return (
    <IonContent>
      <DisplayContent
        editorJsData={content?.content}
        buttonComp={
          <NextPrevButtons
            onNextClick={onStartClick}
            onPreviousClick={onPrevClick}
          />
        }
      />
    </IonContent>
  );
};

export default SequenceContent;
