export const invite = {
  invite_title: 'Invitations',
  // Ajout Synakene
  invite_subtitle: 'Invite your friends to participate',
  invite_thank_you_participation: 'Thank you for your participation!',
  invite_friends_to_help: 'You can help us further by inviting friends.',
  invite_later: 'Later',
  invite_friends: 'I invite my friends',
  // invite_subtitle: 'Select a channel to send an invitation',
  invite_modal_mail: 'Send an invitation by email',
  invite_modal_EMAIL: 'Send an invitation by email',
  invite_modal_sms: 'Send an invitation by SMS',
  invite_modal_name_placeholder: 'Ex: John',
  invite_modal_surname_placeholder: 'Ex: Smith',
  invite_modal_email_placeholder: 'Ex: John.Smith@email.com',
  invite_modal_lang_placeholder: 'Choose',
  invite_modal_phone_placeholder: 'Ex: 0123456789',
  invite_copy_notification: 'Copied to clipboard',
  invite_empty_config: 'No invitation options available',
  invite_channel_title: 'How do you prefer to be invited?',
  invite_EMAIL: 'Email',
  invite_SMS: 'SMS',
  invite_PUBLIC_LINK: 'Copy the link',
  invite_inclusion_direct: 'Direct inclusion'
};
