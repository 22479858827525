import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import Paragraph from 'editorjs-paragraph-with-alignment';
import List from '@editorjs/list';
import Image from '@editorjs/image';
import Header from 'editorjs-header-with-alignment';
import Delimiter from '@editorjs/delimiter';
import Marker from '@editorjs/marker';
import Underline from '@editorjs/underline';

export const EDITOR_JS_TOOLS = {
  embed: Embed,
  table: Table,
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    config: {
      placeholder: 'Header'
    },
  },
  list: List,
  image: Image,
  header: Header,
  delimiter: Delimiter,
  marker: Marker,
  underline: Underline,
};
