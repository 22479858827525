import { phonePortrait, mail } from "ionicons/icons";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { AttributesType } from "../../../../types/profile";
import { PatchUserType } from "../../../../types/welcome";
import { FormGroup, TextField } from "../../../atoms/LoginTextField";
import Subtitle from "../../../atoms/Subtitle";
import Description from "../../../atoms/Description";

interface ContactFieldsProps {
  attributes: AttributesType;
  values: PatchUserType;
  setFieldValue: (key: string, value: string) => void;
  errors: any;
  touched: any;
}

const transformPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return phoneNumber;
  // Remove all spaces from the phone number
  phoneNumber = phoneNumber.replace(/\s+/g, '');
  // Replace +33 at the beginning with 0
  if (phoneNumber.startsWith('+33')) {
    phoneNumber = '0' + phoneNumber.slice(3);
  }
  return phoneNumber;
};

const ContactFields = ({
  attributes,
  touched,
  errors,
  values,
  setFieldValue,
}: ContactFieldsProps): ReactElement => {
  const { t } = useTranslation();
  const {
    has_email,
    has_phonenumber,
  } = attributes;

  const { email, phone_number } =
    values;

  return (
    <FormGroup>
      <Subtitle>{t("home_contact_title")}</Subtitle>
      <Description>{t("home_contact_description")}</Description>
      {has_email && (
        <TextField
          icon={mail}
          value={email}
          type="text"
          error={touched.email && errors.email}
          onChange={(value) => setFieldValue("email", value)}
          placeholder={t("home_inscription_email")}
        />
      )}

      {has_email && has_phonenumber && (<Subtitle>ou</Subtitle>)}
      
      {has_phonenumber && (
        <TextField
          icon={phonePortrait}
          value={phone_number}
          type="text"
          error={touched.phone_number && errors.phone_number}
          onChange={(value) => setFieldValue("phone_number", transformPhoneNumber(value))}
          placeholder={t("home_inscription_phone")}
        />
      )}
    </FormGroup>
  );
};

export default ContactFields;
