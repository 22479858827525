// TODO: this is a copy of file types/profileForm.ts from Diapason

import { RoleEnum } from "./invitation.types";

export interface ProfileType {
  id?: number;
  profile: string;
  name: RoleEnum;
  attributes: AttributesType;
  signin_criteria: IdentificationType;
  inclusion_criteria: InclusionType;
  consent: ConsentType;
  site: SiteType;
}

export interface SiteType {
  question_site: string;
  question_subsite: string;
}

export interface ConsentType {
  id: number;
  has_consent: boolean;
  type: ProfileConsentEnum;
}

export enum ProfileConsentEnum {
  NONE = '',
  SIGNATURE = 'SIGNATURE',
  CHECKBOX = 'CHECKBOX'
}

export interface IdentificationType {
  method: IdentificationMethodEnum;
  identification_questions: SecretQuestionType[];
}

export interface SecretQuestionType {
  id?: number;
  question: string;
  type: SecretQuestionTypeEnum;
}

export enum SecretQuestionTypeEnum {
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT'
}

export interface InclusionType {
  has_inclusion_questions: boolean;
  inclusion_questions: InclusionQuestionType[];
}

export interface InclusionQuestionType {
  id?: number;
  question: string;
  eligible_answer: boolean;
}

export enum IdentificationMethodEnum {
  NONE = '',
  EMAIL = 'LOGIN-PASSWORD',
  FILE_NUMBER = 'FILE_NUMBER',
  IDENTIFICATION_OFF = 'IDENTIFICATION_OFF',
  SECRET_QUESTIONS = 'SECRET-QUESTIONS',
  WITHOUT_IDENTIFICATION = 'WITHOUT_IDENTIFICATION',
  NICKNAME_PASSWORD= 'NICKNAME-PASSWORD'
}

export enum AttributeEnum {
  FIRSTNAME = 'has_firstname',
  LASTNAME = 'has_lastname',
  EMAIL = 'has_email',
  PHONENUMBER = 'has_phonenumber',
  EXTERNALID = 'has_external_id',
  VOCALISATION = 'is_vocalisation_enabled',
  NICKNAME = 'has_nickname',
  SITES = 'is_site_requested',
  SPEECH = 'is_speech_recognition_enabled',
  DIRECT_INCLUSION = 'is_direct_inclusion_authorized',
  STRATIFICATION = 'has_stratification',
  CAN_SIGN_UP_ONLY_WITH_INVITATION = 'can_sign_up_only_with_invitation'
}

export type AttributesType = Record<AttributeEnum, boolean>;

export interface IdentificationOptionType {
  label: string;
  value: IdentificationMethodEnum;
  attributeNeeded?: AttributeEnum;
}
