import { resonance } from './resonance.en';
import { lang } from './lang.en';
import { menu } from './menu.en';
import { layout } from './layout.en';
import { common } from './common.en';
import { invite } from './invite.en';
import { answer } from './answer.en';
import { home } from './home.en';
import { profile } from './profile.en';
import { changePassword } from './change-password';
import finished_questionnaires from './finished_questionnaires.en';
import { formError } from './formError.en';
import { questionnaire } from './questionnaire.en';
import { forgotPassword } from './forgot-password.en';
import { sequence } from './sequences.en';
import { gift } from './gift.en';
import { onboarding } from './onboarding.en';
import { consent } from './consent.en';

export const resonanceTradEn = {
  ...resonance,
  ...answer,
  ...menu,
  ...layout,
  ...invite,
  ...common,
  ...lang,
  ...home,
  ...profile,
  ...changePassword,
  ...forgotPassword,
  ...finished_questionnaires,
  ...formError,
  ...questionnaire,
  ...sequence,
  ...onboarding,
  ...gift,
  ...consent
};
