import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Card from "../../atoms/Card";
import styled from "styled-components";
import { useAuth } from "../../../contexts/AuthContext";
import { getTotalUser } from "../../../services/user.service";
import { useConfig } from "../../../contexts/ConfigContext";


const DisplayInvitations = ({
}): ReactElement => {
  const { t } = useTranslation();
  useAuth();
  const { study } = useConfig();

  const [progress, setProgress] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0)
  const [goal, setGoal] = useState(50);

  const fetchData = async () => { 
    try {
      const { data } = await getTotalUser(study.id);
      setTotalUsers(data);
    }
    catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData();
  },[])

  useEffect(() => {
    let newGoal = goal;
    while (totalUsers >= newGoal) {
      newGoal *= 2;
    }
    if (newGoal !== goal) {
      setGoal(newGoal);
    }
    setProgress((totalUsers * 100) / newGoal);
  }, [totalUsers, goal]);

  const handleClick = () => {
    setTotalUsers(totalUsers+20);
  }

  return (
    <div>
      <GoalCard>
      <Circle color={'red'}><img style={{width: '70%', height: 'auto'}} src="assets/images/cible.png"></img></Circle>
      <GoalTitle>Objectif {goal}</GoalTitle>
      <div>
        <ProgressBarContainer>
            <ProgressBar $width={progress} />
        </ProgressBarContainer>
      </div>
      <GoalSubtitle>Déjà {totalUsers} participants !</GoalSubtitle>
    </GoalCard>
      
    </div>
  );
};

const GoalCard = styled(Card)`
  margin-top: 50px;
  padding: 25px 0px;
  height : 25vh;
  max-height : 175px;
`;

const GoalTitle = styled.div`
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  margin-top: 1vh;
`;

const GoalSubtitle = styled.div`
  font-weight: normal;
  font-size : 1.1rem;
  margin: 0px 30px;
`;

const Circle = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 60px;
  background-color: ${(props) => props.theme.primary.background};
  border: 2px;
  margin: -50px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProgressBarContainer = styled.div`
  background-color: #e6e6e6;
  border-radius: 10px;
  height: 20px;
  margin: 20px 30px;
  overflow: hidden;
`;

const ProgressBar = styled.div<{$width?:number}>`
  background: linear-gradient(270deg, #2DB6B4 2.69%, #2DB6B4 2.7%, #ABF0EF 105.65%);
  height: 100%;
  border-radius: 10px;
  width: ${(props) => props.$width}%;
  transition: width 0.4s ease-in-out;
`;

export default DisplayInvitations;
