import { ReactElement } from "react";
import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {
  DropdownQuestionType,
  CommonAnswersType,
} from "../../../../types/Question";
import { useTranslation } from "react-i18next";
import { micOutline } from "ionicons/icons";
import useVocalisation from "../../../../hooks/useVocalisation";
import { useConfig } from "../../../../contexts/ConfigContext";

interface AnswerDropdownProps {
  question: DropdownQuestionType;
  value?: string;
  onChange: (value: string) => void;
}

const AnswerDropdown = ({
  question,
  value = "",
  onChange,
}: AnswerDropdownProps): ReactElement => {
  const { t } = useTranslation();
  const {
    detail: { answers },
  } = question;

  const { startListnening, isListening } = useVocalisation(
    answers.map(({ label }: CommonAnswersType) => label),
    (label) => {
      if (label) onChange(answers.find((a) => a.label === label)?.id as any);
    }
  );

  const { selectedRole } = useConfig();
  const isSpeechRecEnabled =
    selectedRole?.attributes?.is_speech_recognition_enabled;

  const renderOption = ({ label, id }: CommonAnswersType): ReactElement => (
    <IonSelectOption key={id} value={id}>
      {label}
    </IonSelectOption>
  );

  return (
    <>
      <IonItem>
        <IonSelect
          value={value}
          placeholder={t("answer_dropdwon_placeholder")}
          onIonChange={(e: CustomEvent): void => onChange(e.detail.value)}
        >
          {answers.map(renderOption)}
        </IonSelect>
      </IonItem>
      {isSpeechRecEnabled && (
        <IonItem>
          <IonLabel>
            <p>{t("common_click_to_speak")}</p>
          </IonLabel>

          <IonButton onClick={startListnening} slot="end" fill="clear">
            {isListening ? (
              <IonIcon slot="icon-only" src="/assets/icon/loading.svg" />
            ) : (
              <IonIcon slot="icon-only" icon={micOutline} />
            )}
          </IonButton>
        </IonItem>
      )}
    </>
  );
};

export default AnswerDropdown;
