import { getApi, postApi } from "./resonanceApi";
import { AxiosResponse } from 'axios';
import { SiteType, StudyContentType } from "../types/welcome";

export enum StudyApiUrlsEnum {
  GET_CONTENT = 'studies/{studyId}/contents/{contentId}',
  GET_SITES = 'studies/{studyId}/sites',
  LEAVE_STUDY = 'studies/{studyId}/users/current/leave',
  GET_STUDY_LANGS = 'studies/{studyId}/langs',
  GET_STUDY_IS_GAMIFIED = 'studies/{studyId}/is_gamified',
}

export const getStudyContent = ({ studyId, contentId, lang }): Promise<AxiosResponse<StudyContentType>> => {
  const url = StudyApiUrlsEnum.GET_CONTENT;
  return getApi<StudyContentType>(url, { studyId, contentId }, false, { lang });
};

export const getStudySites = ({ studyId }): Promise<AxiosResponse<SiteType[]>> => {
  const url = StudyApiUrlsEnum.GET_SITES;
  return getApi<SiteType[]>(url, { studyId });
};

interface LangsResponseType {
  additional_langs: string[];
  primary_lang: string;
}

export const getStudyLangs = (studyId: number): Promise<AxiosResponse<LangsResponseType>> => {
  const url = StudyApiUrlsEnum.GET_STUDY_LANGS;
  return getApi<LangsResponseType>(url, { studyId });
};

export const getStudyIsGamified = async (studyId: number): Promise<AxiosResponse<boolean>> => {
  const url = StudyApiUrlsEnum.GET_STUDY_IS_GAMIFIED;
  return getApi<boolean>(url, { studyId });
};

export const leaveStudy = (studyId: number, body: { allow_access_data: boolean }): Promise<AxiosResponse> => {
  const url = StudyApiUrlsEnum.LEAVE_STUDY;
  return postApi(url, { studyId }, body);
}