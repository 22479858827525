import { FC } from "react";
import { IonSelect, IonSelectOption,  } from '@ionic/react';
import styled from "styled-components";
import Icon from "./Icon";
import { alertCircle } from "ionicons/icons";



interface Option {
    text: string;
    id: string;
}


interface SelectFieldProps{
    value: string|number;
    options: Option[];
    placeholder: string;
    onChange(value: string): void;
    error?: string;
    icon?: string;
    label?: string;
}

const SelectField: FC<SelectFieldProps> = ({placeholder, value, options, error, icon, label, onChange})=>{

    return (

        <StyledTextField error={error}>
        {icon && <FieldIcon icon={icon}/>}
        <Field>
          <Label>{label}</Label>

            <Select
                value={value}
                placeholder={placeholder}
                onIonChange={(e: CustomEvent<{ value: string }>) => onChange(e.detail.value)}
                >
                {options.map(option => <IonSelectOption key={option.id} value={option.id}>{option.text}</IonSelectOption>)}
            </Select>
        </Field>
        {error && <ErrorIcon icon={alertCircle}/>}
      </StyledTextField>
    )
}


const StyledTextField = styled.div<{error: string}>`
  display: flex;
  background: white;
  padding: 14px 10px;
  border-bottom: 1px solid #eee;
  color: ${props => props.error ? 'red' : 'auto'}!important;
  input::placeholder {
    color: ${props => props.error ? 'red' : 'auto'}!important;
  }
`

const Select = styled(IonSelect)`
    padding: 2px 2px;
`

const FieldIcon = styled(Icon)`
  color: ${props => props.theme.primary.main};
  font-size: 20px;
`

const Field = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Label = styled.div`
  font-size: 11px;
  color: #afafaf;
  margin-bottom: 2px;
  margin-left: 2px;
`
const ErrorIcon = styled(Icon)`
  color: ${props => props.theme.danger.main};
  font-size: 22px;
`


export default SelectField