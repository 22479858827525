import { ReactElement } from "react";
import {
  InvitationChannelDisplayType,
  InvitationDisplayType,
  RoleEnum,
} from "../../../types/invitation.types";
import { useTranslation } from "react-i18next";
import Card from "../../atoms/Card";
import styled, { useTheme } from "styled-components";
import Channel from "./Channel";
import { theme } from "../../../theme/theme";
import { personAdd, arrowRedo } from "ionicons/icons";
import Icon from "../../atoms/Icon";
import Button from "../../atoms/Button";
import { INSTAGRAM, WHATSAPP, SNAPCHAT, TWITTER, MESSENGER } from "../../../constants/images";

interface Props {
  invitation: InvitationDisplayType;
  onChannelClick(
    invit: InvitationDisplayType,
    channel: InvitationChannelDisplayType
  ): void;
}

const Invitation = ({ invitation, onChannelClick }: Props): ReactElement => {
  const { t } = useTranslation();
  const { to_role_id, to_role, to_role_label, channels } = invitation;
  const theme = useTheme()

  const handleShare = () => {
    navigator.share({url : window.location.href})
  }


  return (
    <InvitCard key={to_role_id}>
      <Circle color={theme.primary.background}><StyledIcon icon={personAdd} size="large"/></Circle>
      <InvitSubtitle>{t("invite_subtitle")}</InvitSubtitle>
      <div style={{display: 'flex', flexDirection : 'row', alignItems: 'center'}}>
      <ShareButton onClick={handleShare}><StyledIcon icon={arrowRedo} size="large" /></ShareButton>
      <ShareMenu>
        <SocialButton onClick={handleShare}><SocialImage src={INSTAGRAM} alt="insta" /></SocialButton>
        <SocialButton onClick={handleShare}><SocialImage src={WHATSAPP} alt="whatsapp" /></SocialButton>
        <SocialButton onClick={handleShare}><SocialImage src={SNAPCHAT} alt="snapchat" /></SocialButton>
        <SocialButton onClick={handleShare}><SocialImage src={TWITTER} alt="twitter" /></SocialButton>
        <SocialButton onClick={handleShare}><SocialImage src={MESSENGER} alt="messenger" /></SocialButton>
      </ShareMenu>
      </div>
      <ChannelContainer>
        {channels.map((channel) => (
          <Channel
            key={channel.channel}
            channel={channel}
            onClick={() => onChannelClick(invitation, channel)}
          />
        ))}
      </ChannelContainer>
    </InvitCard>
  );
};


const StyledIcon = styled(Icon)`
  margin-left : auto;
  width : 100%;
  color : black;
`

const ChannelContainer = styled.div`
  display : flex;
  flex-direction: row;
  justify-content : space-evenly;
  align-items: center;
  padding-left: 5%;
  padding-right : 5%;
  width : 100%;

  // box-sizing: border-box;
`

const SocialButton = styled.button`
  border: none;
  padding: min(2vw, 10px);
  border-radius: 50%;
  height: auto;
  width: 12.5vw;
  max-width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: none;
  cursor: pointer;
`;

const SocialImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const ShareButton = styled.button`
  position: absolute;
  border-radius : 50%;
  height : 8vw;
  width: 8vw;
  max-height : 60px;
  max-width: 60px;
  margin: 0px 1.5vw;
  background-color: ${(props) => props.theme.primary.background};

  display: flex;
  justify-content: center;
  align-items: center;
`

const ShareMenu = styled.div`
  margin: 0px 10vw;
  background-color : #D4EDEC;
  border-radius : 10px;
  display : flex;
  flex-direction: row;
  justify-content : space-evenly;
  align-items: center;
  padding-left: 5%;
  padding-right : 5%;
  width : 100%;
`

const InvitCard = styled(Card)`
  padding: 25px 0px;
  height: 30vh;
  max-height : 325px;
`;

const InvitSubtitle = styled.div`
  text-align: center;
  padding: 0 20px;
  margin: 5px 0px;
`;

const Circle = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 60px;
  background-color: ${(props) => props.color};
  border: 2px;
  margin: -50px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Invitation;
