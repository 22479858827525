export const answer = {
  answer_mandatory: 'Pregunta obligatoria.',
  answer_noMandatory: 'Puedes omitir esta pregunta.',
  answer_common_constraint: 'Selecciona una respuesta',
  answer_checkbox_constraint_NONE: 'Selecciona una o varias respuestas',
  answer_checkbox_constraint_AT_LEAST: 'Selecciona al menos {{quantity}} respuestas',
  answer_checkbox_constraint_EXACTLY: 'Selecciona exactamente {{quantity}} respuestas',
  answer_checkbox_constraint_AT_MOST: 'Selecciona como máximo {{quantity}} respuestas',
  answer_dropdwon_placeholder: 'Selecciona una',
  answer_text_placeholder: 'Introduce tu respuesta aquí',
  answer_text_placeholderEmail: 'Escribe el correo electrónico',
  answer_number_placeholder: 'Escribe un número',
  answer_number_constraint_min_max: 'El valor debe estar entre {{min}} y {{max}}.',
  answer_number_constraint_decimal: 'Máximo {{nbDecimales}} decimales permitidos.',
  answer_date_format_YEAR: 'AAAA',
  answer_date_format_MONTH: 'MM/AAAA',
  answer_date_format_DAY: 'DD/MM/AAAA',
  answer_date_placeholder: 'Selecciona una fecha',
  answer_hour_placeholder: 'Selecciona una hora',
  answer_radio_constraint: 'Una respuesta posible',
  answer_dropdown_constraint: 'Selecciona tu respuesta',
  answer_text_constraint: 'Introduce tu respuesta libremente',
  answer_date_constraint: 'Selecciona una fecha',
  answer_hour_constraint: 'Selecciona una hora entre {{min}} y {{max}}',
};
