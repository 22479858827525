import { FC } from "react";
import styled from "styled-components";

interface RadioButtonPropsType {
    label: string;
    name: string;
    value: string;
    color?: string;
    onChange?(name: string, value: string): void;
}

export const RadioButton: FC<RadioButtonPropsType> = ({label, name,onChange, value, color, ...props})=>{
    return (
        <StyledRadioButton {...props} color={color}>
            {label}
            <input type="radio"  name={name} value={value} onChange={e => onChange(name, value)}/>
            <span className="checkmark"></span>
        </StyledRadioButton>
    )
}

const StyledRadioButton = styled.label`
    display: inline-block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 12px;

    /* Hide the browser's default radio button */
   input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #eee;
        border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    input:checked ~ .checkmark {
        background-color: ${props => props.color || props.theme.primary.main};
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the indicator (dot/circle) */
    .checkmark:after {
        top: 4px;
        left: 4px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: white;
    }
`

export default RadioButton;