import { SUN, EAGLE, RAINBOW, ANIMALS, FLOWERS, FIREWORKS, NIGHT, GREENERY } from '../../../../constants/images';

export interface LandscapeData {
    id: number;
    keyword: string;
    src: string;
    animation: {
      startOpacity: number;
      endOpacity: number;
      x: number;
      transition: {
        duration: number;
      };
    };
  }
  
export enum MyEnum {
  None = 0,
  Sun = 1,
  Flowers = 2,
  Rainbow = 4,
  Eagle = 8,
  Firework = 16,
  Greenery = 32,
  Animals = 64,
}

export const landscapeData: LandscapeData[] = [
  { id: 1, keyword: "sun", src: SUN, animation: { startOpacity: 0, endOpacity: 1, x: 0, transition: { duration: 2.5 } } },
  { id: 2, keyword: "eagle",  src: EAGLE, animation: { startOpacity: 0, endOpacity: 1, x: 0, transition: { duration: 2.5 } } },
  { id: 3, keyword: "rainbow",  src: RAINBOW, animation: { startOpacity: 0, endOpacity: 1, x: 0, transition: { duration: 2.5 } } },
  { id: 4, keyword: "firework",  src: FIREWORKS, animation: { startOpacity: 0, endOpacity: 1, x: 0, transition: { duration: 2.5 } } },
  { id: 5, keyword: "greenery",  src: GREENERY, animation: { startOpacity: 0, endOpacity: 1, x: 0, transition: { duration: 2.5 } } },
  { id: 6, keyword: "animals",  src: ANIMALS, animation: { startOpacity: 0, endOpacity: 1, x: 0, transition: { duration: 2.5 } } },
  { id: 7, keyword: "flowers",  src: FLOWERS, animation: { startOpacity: 0, endOpacity: 1, x: 0, transition: { duration: 2.5 } } },
  { id: 8, keyword: "sun",  src: NIGHT, animation: { startOpacity: 1, endOpacity: 0, x: 0, transition: { duration: 2.5 } } },
];
  