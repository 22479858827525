import { resonance } from './resonance.fr';
import { lang } from './lang.fr';
import { menu } from './menu.fr';
import { layout } from './layout.fr';
import { common } from './common.fr';
import { answer } from './answer.fr';
import { invite } from './invite.fr';
import { home } from './home.fr';
import { profile } from './profile.fr';
import { changePassword } from './change-password';
import finished_questionnaires from './finished_questionnaires.fr';
import { formError } from './formError.fr';
import { questionnaire } from './questionnaire.fr';
import { forgotPassword } from './forgot-password.fr';
import { sequence } from './sequences.fr';
import { onboarding } from './onboarding.fr';
import { gift } from './gift.fr';
import { consent } from './consent.fr';

export const resonanceTradFr = {
  ...resonance,
  ...answer,
  ...menu,
  ...layout,
  ...common,
  ...invite,
  ...lang,
  ...home,
  ...profile,
  ...changePassword,
  ...forgotPassword,
  ...finished_questionnaires,
  ...formError,
  ...questionnaire,
  ...sequence,
  ...onboarding,
  ...gift,
  ...consent
};
