import { IonDatetime, PickerOptions } from "@ionic/react";
import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { numberToIsoDate } from "../../helper/number";
import { DateFormatEnum } from "../../types/Question";

interface ResonanceDatePickerProps {
  minDate?: string;
  maxDate?: string;
  value: string;
  dateFormat: DateFormatEnum;
  canClear?: boolean;
  onChange: (value: string) => void;
  style?: any;
  className?: string;
  placeholder?: string;
}

interface PickerDataDetailsType {
  text: string;
  value: number;
}

interface PickerDateType {
  year: PickerDataDetailsType;
  month?: PickerDataDetailsType;
  day?: PickerDataDetailsType;
}

const formatToTradKey: Record<DateFormatEnum, string> = {
  YEAR: "answer_date_format_YEAR",
  MONTH: "answer_date_format_MONTH",
  DAY: "answer_date_format_DAY",
};

const ResonanceDatePicker: FC<ResonanceDatePickerProps> = ({
  minDate,
  maxDate,
  value,
  dateFormat,
  placeholder,
  canClear,
  onChange,
  style,
  className,
}): ReactElement => {
  const { t } = useTranslation();

  const cancelButton = {
    text: t("common_cancel"),
    role: "cancel",
  };

  const clearButton = {
    text: t("common_clear"),
    handler: (): void => onChange(""),
  };

  const saveButton = {
    text: t("common_save"),
    handler: ({ year, month, day }: PickerDateType): void => {
      console.log(year, month, day);
      onChange(
        numberToIsoDate(
          year.value,
          month?.value && month?.value - 1,
          day?.value
        )
      );
    },
  };

  const buttons = canClear
    ? [cancelButton, clearButton, saveButton]
    : [cancelButton, saveButton];

  const pickerOptions: Partial<PickerOptions> = { buttons };

  const format = t(formatToTradKey[dateFormat]);

  return (
    <IonDatetime
      style={style}
      displayFormat={format}
      value={value}
      placeholder={placeholder ?? format}
      min={minDate}
      max={maxDate}
      pickerOptions={pickerOptions}
      className={"res-text-input " + className}
    />
  );
};

export default ResonanceDatePicker;
