import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { ParamsType } from '../services/resonanceApi';

interface Options<T = any> {
  enabled?: boolean,
  onSuccess?: (data: T) => void
}

type Handler = (arg: any) => Promise<{ data: any }>

export const useFetch = <T = any>(key: string, handler: Handler, params: ParamsType, options: Options<T> = {}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<AxiosError>();

  useEffect(function () {

    async function LoadData() {
      try {
        if (options.enabled === false) return;
        setIsLoading(true);
        const { data } = await handler(params);
        setData(data);
        setIsError(false);
        setError(undefined);
        if (options.onSuccess) options.onSuccess(data);
      }
      catch (e) {
        setIsError(true);
        setError(error)
      }
      finally {
        setIsLoading(false)
      }
    }

    LoadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(params), options.enabled])

  return { data, isLoading, isError, error }
};
