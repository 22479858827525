import { FC, ReactElement } from "react";
import styled from "styled-components";
import Card from "../../atoms/Card";
import SmallButton from "../../atoms/smallButton";

interface MenuItemProps {
  icon: ReactElement;
  title: string;
  onClick: () => void;
  action: string;
}

const MenuItem: FC<MenuItemProps> = ({ icon, title, onClick, action }) => {
  return (
    <MenuItemCard onClick={onClick}>
      <ItemImage>{icon}</ItemImage>
      <ItemLabel>{title}</ItemLabel>
      <SmallButton color="primary">{action}</SmallButton>
    </MenuItemCard>
  );
};

const ItemImage = styled.div`
  height: 100%;
  width: 60px;
  height: 60px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.primary.main};
`;

const MenuItemCard = styled(Card)`
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ItemLabel = styled.div`
  width: 100%;
  padding: 0 10px;
`;

export default MenuItem;
