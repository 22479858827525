import { IonIcon } from "@ionic/react";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  IdentificationMethodEnum,
  ProfileType,
} from "../../../../types/profile";
import { chevronForwardOutline } from "ionicons/icons";
import NextPrevButtons from "../../../molecules/NextPrevButtons";
import styled from "styled-components";
import Container from "../../../atoms/Container";
import ScreenTitle from "../../../atoms/ScreenTitle";
import { useHomeContext } from "../../../../pages/Home/HomeContent";
import { useConfig } from "../../../../contexts/ConfigContext";
import { WelcompeStepEnum } from "../../../../types/welcome";

const DisplayProfileSelector = (): ReactElement => {
  const { t } = useTranslation();

  const { welcomeData, goPrevious, createUser, setCurrentStep } =
    useHomeContext();

  const { setRole } = useConfig();

  const roles = welcomeData?.roles;

  const onProfileSelection = (newRole: ProfileType): void => {
    setRole(newRole);
    if (
      newRole.signin_criteria.method ===
      IdentificationMethodEnum.WITHOUT_IDENTIFICATION
    ) {
      createUser(newRole);
    } else {
      setCurrentStep(WelcompeStepEnum.HAS_ACCOUNT);
    }
  };

  const renderProfileItem = (
    role: ProfileType,
    index: number
  ): ReactElement => {
    const { id, profile } = role;
    return (
      <Item key={id} onClick={(): void => onProfileSelection(role)}>
        <Circle index={index} />
        <ItemTitle>{profile}</ItemTitle>
        <IonIcon slot="end" icon={chevronForwardOutline} />
      </Item>
    );
  };

  return (
    <Container>
      <ScreenTitle>{t("home_profile_selector_text")}</ScreenTitle>

      {roles.map(renderProfileItem)}

      <NextPrevButtons onPreviousClick={goPrevious} />
    </Container>
  );
};

const Item = styled.div`
  background: white;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 10px 20px;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
`;

const ItemTitle = styled.h5`
  color: grey;
  font-weight: bold;
  flex: 1;
  margin: 0;
`;
const Circle = styled.div<{ index: number }>`
  background: ${(props) => (props.index % 2 === 0 ? "#00c5bd" : "#f8815c")};
  height: 50px;
  width: 50px;
  margin-right: 10px;
  border-radius: 50%;
`;

export default DisplayProfileSelector;
