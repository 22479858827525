import axios from 'axios';
import { ENV } from '../environments/environment';
import { interpolate } from '../helper/interpolate';

export type ParamsType = Record<string, string | number>;

export const getApi = async <T>(ressourceUrl: string, params: ParamsType = {}, isMocked: boolean = false, queryParams = {}) => {
  const formattedUrl = interpolate(ressourceUrl, params);
  const baseUrl = isMocked ? ENV.REACT_APP_BASE_URL_MOCK : ENV.REACT_APP_BASE_URL;
  const response = await axios.get<T>(`${baseUrl}/${formattedUrl}`, {params: queryParams});
  return response;
};

export const postApi = async <T>(ressourceUrl: string, params: ParamsType = {}, body: Object, isMocked: boolean = false) => {
  const formattedUrl = interpolate(ressourceUrl, params);
  const baseUrl = isMocked ? ENV.REACT_APP_BASE_URL_MOCK : ENV.REACT_APP_BASE_URL;
  const response = await axios.post<T>(`${baseUrl}/${formattedUrl}`, body);
  return response;
};

export const patchApi = async <T>(ressourceUrl: string, params: ParamsType = {}, body: Object, isMocked: boolean = false) => {
  const formattedUrl = interpolate(ressourceUrl, params);
  const baseUrl = isMocked ? ENV.REACT_APP_BASE_URL_MOCK : ENV.REACT_APP_BASE_URL;
  const response = await axios.patch<T>(`${baseUrl}/${formattedUrl}`, body);
  return response;
};

export const deleteApi = async <T>(ressourceUrl: string, params: ParamsType = {}) => {
  const formattedUrl = interpolate(ressourceUrl, params);
  const baseUrl = ENV.REACT_APP_BASE_URL;
  const response = await axios.delete<T>(`${baseUrl}/${formattedUrl}`);
  return response;
};
