import { ReactElement } from "react";
import { useHomeContext } from "../../../../pages/Home/HomeContent";
import { WelcomeContentEnum } from "../../../../types/welcome";
import Gap from "../../../atoms/Gap";
import DisplayContent from "../../../molecules/DisplayContent";
import NextPrevButtons from "../../../molecules/NextPrevButtons";

const DisplayStudyDetails = (): ReactElement => {

  const { welcomeData, goPrevious } = useHomeContext();

  const contentData =
    welcomeData?.contents[WelcomeContentEnum.DETAILED_HOME_STUDY].content;

  return (
    <DisplayContent
      key="study-details"
      editorJsData={contentData}
      buttonComp={
        <>
          <Gap />
          <NextPrevButtons onPreviousClick={goPrevious} />
        </>
      }
    />
  );
};

export default DisplayStudyDetails;
