import { IonIcon } from "@ionic/react";
import styled from "styled-components";

const Icon = styled(IonIcon)`
  font-weight: bold;
  font-size: ${(props) => props.theme.icon.size.regular};
  color: inherit;
  margin: 5px;
`;

export default Icon;
