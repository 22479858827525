/* eslint-disable @typescript-eslint/no-unused-vars */
import { lockClosed } from "ionicons/icons";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IdentificationMethodEnum,
  IdentificationType,
} from "../../../../types/profile";
import { FormGroup, TextField } from "../../../atoms/LoginTextField";
import ScreenTitle from "../../../atoms/ScreenTitle";
import DisplaySecretQuestions from "../Login/DisplaySecretQuestions";
import Subtitle from "../../../atoms/Subtitle";
import Description from "../../../atoms/Description";

interface InscriptionIdentifierProps {
  signinCriteria: IdentificationType;
  setFieldValue: (key: string | number, value: string) => void;
  values: any;
  errors: any;
  touched: any;
}

const InscriptionIdentifier = ({
  signinCriteria,
  setFieldValue,
  errors,
  values,
  touched,
}: InscriptionIdentifierProps): ReactElement => {
  const { t } = useTranslation();
  const { method, identification_questions: questions } = signinCriteria;

  return (
    <>
      {method === IdentificationMethodEnum.SECRET_QUESTIONS && (
        <>
          <ScreenTitle> {t("home_secret_questions")}</ScreenTitle>
          <DisplaySecretQuestions
            secretQuestions={questions}
            answers={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        </>
      )}
      {(method === IdentificationMethodEnum.EMAIL || method === IdentificationMethodEnum.NICKNAME_PASSWORD) && (
        <>
          <FormGroup>
            <Subtitle>{t("home_password_title")}</Subtitle>
            <Description>{t("home_password_description")}</Description>
            <TextField
              icon={lockClosed}
              value={values.password}
              error={touched.password && errors.password}
              type="password"
              onChange={(value) => setFieldValue("password", value)}
              placeholder={t("home_password_placeholder")}
            />

            <TextField
              icon={lockClosed}
              value={values.confirmPassword}
              error={touched.confirmPassword && errors.confirmPassword}
              type="password"
              onChange={(value) => setFieldValue("confirmPassword", value)}
              placeholder={t("home_password_placeholder_confirm")}
            />
          </FormGroup>
        </>
      )}
    </>
  );
};

export default InscriptionIdentifier;
