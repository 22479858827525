import { ReactElement } from "react";
import {
  IonButton,
  IonCheckbox,
  IonIcon,
  IonItem,
  IonLabel,
} from "@ionic/react";
import {
  CheckboxQuestionType,
  CheckboxAnswerType,
} from "../../../../types/Question";
import { micOutline, volumeHighOutline } from "ionicons/icons";
import useVocalisation from "../../../../hooks/useVocalisation";
import { useConfig } from "../../../../contexts/ConfigContext";
import { useTranslation } from "react-i18next";

interface AnswerCheckboxProps {
  question: CheckboxQuestionType;
  value?: number[];
  onChange: (value: number[]) => void;
}

const DISABLE_SPEECH_RECOGNITION = true;

const AnswerCheckbox = ({
  question,
  value = [],
  onChange,
}: AnswerCheckboxProps): ReactElement => {
  const {
    detail: { answers },
  } = question;
  const { t } = useTranslation();

  const lastAnswerIndex = answers.length - 1;

  const { selectedRole } = useConfig();
  const isVocalisationEnabled =
    selectedRole?.attributes?.is_vocalisation_enabled;
  const isSpeechRecEnabled =
    selectedRole?.attributes?.is_speech_recognition_enabled;

  const handleCheckboxChange = (answerId: number, isChecked: boolean): void => {
    if (isChecked) {
      onChange([...value, answerId]);
    } else {
      onChange([
        ...value.filter(
          (checkedItem: number): boolean => checkedItem !== answerId
        ),
      ]);
    }
  };

  const { startListnening, readText, isListening } = useVocalisation(
    answers.map((a) => a.label),
    (value) =>
      handleCheckboxChange(
        answers.find((answer) => answer.label === value).id as any,
        true
      )
  );

  const renderAnswer = (
    { label, id }: CheckboxAnswerType,
    index: number
  ): ReactElement => (
    <IonItem key={id} lines={lastAnswerIndex === index ? "none" : "full"} mode="md">
      <IonCheckbox
        slot="start"
        value={label}
        checked={value.includes(id)}
        onIonChange={(e: CustomEvent): void =>
          handleCheckboxChange(id, e.detail.checked)
        }
      />
      <IonLabel>
        <p className="answer-with-scroll">{label}</p>
      </IonLabel>
      {isVocalisationEnabled && (
        <IonButton
          slot="end"
          fill="clear"
          onClick={(): void => {
            readText(label);
          }}
        >
          <IonIcon slot="icon-only" icon={volumeHighOutline} />
        </IonButton>
      )}
    </IonItem>
  );

  return (
    <>
    <div style={{ overflow: 'auto', maxHeight: '300px' }}>
      {answers.map(renderAnswer)}
      </div>
      {!DISABLE_SPEECH_RECOGNITION && isSpeechRecEnabled && (
        <IonItem lines="full" mode="md">
          <IonLabel>
            <p>{t("common_click_to_speak")}</p>
          </IonLabel>

          <IonButton onClick={startListnening} slot="end" fill="clear">
            {isListening ? (
              <IonIcon slot="icon-only" src="/assets/icon/loading.svg" />
            ) : (
              <IonIcon slot="icon-only" icon={micOutline} />
            )}
          </IonButton>
        </IonItem>
      )}
    </>
  );
};

export default AnswerCheckbox;
