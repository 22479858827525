export const lang = {
  lang_fr: 'الفرنسية',
  lang_en: 'الإنجليزية',
  lang_es: 'الإسبانية',
  lang_ar: 'العربية',
  lang_be: 'البيلاروسية',
  lang_bg: 'البلغارية',
  lang_bs: 'البوسنية',
  lang_cs: 'التشيكية',
  lang_da: 'الدانماركية',
  lang_de: 'الألمانية',
  lang_hu: 'الهنغارية',
  lang_it: 'الإيطالية',
  lang_ja: 'اليابانية',
  lang_pl: 'البولندية',
  lang_ro: 'الرومانية'
};
