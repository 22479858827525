import React, { useEffect } from "react";

type MouseEventsType = "mouseover" | "mousedown" | "mouseup" | "click";

function triggerMouseEvent(node: HTMLElement, eventType: MouseEventsType) {
  var clickEvent = document.createEvent("MouseEvents");
  clickEvent.initEvent(eventType, true, true);
  node.dispatchEvent(clickEvent);
}

function simulateHumanClick(node: HTMLElement) {
  //--- Simulate a natural mouse-click sequence.
  triggerMouseEvent(node, "mouseover");
  triggerMouseEvent(node, "mousedown");
  triggerMouseEvent(node, "mouseup");
  triggerMouseEvent(node, "click");
}
/**
 * TabAccessibility Component
 * A component that enhances accessibility by handling TAB and ENTER key events.
 * Dear future maintainer,i tried to make the code documented as much as i can,
 * since i know it will be modified a lot afterwards,
 * if you are working on this file, it is likely to add an unhandled TAB access case
 * so if you spent some time to reach/debug here.
 * increase this number => {2}
 */

let curr_index = -1;
let curr_focused = null;
const TabAccessibility: React.FC = () => {
  useEffect(() => {
    // wait untill all element are loaded
    setTimeout(() => {
      var elements = document.querySelectorAll("*");
      // alert(elements.length)

      elements.forEach(function (element) {
        element.setAttribute("tabindex", "-1");
      });

      // Register the keydown event listener.
      document.addEventListener("keydown", handleTabPress);
    }, 2000);

    // Cleanup: Remove the listener when the component is unmounted.
    return () => {
      document.removeEventListener("keydown", handleTabPress);
    };
  }, []); // Effect runs only on mount and unmount.

  return null; // This component doesn't render anything.
};

/**
 * Handle TAB and ENTER key events.
 * - TAB: Focuses on the next or previous element based on the direction.
 * - ENTER: Triggers a click on the currently focused element.
 * @param e - KeyboardEvent object
 */
const handleTabPress = (e: KeyboardEvent) => {
  if (e.key === "Tab") {
    e.preventDefault(); // Prevent default tab behavior

    // TAB: Focus next or previous element based on the direction.
    // SHIFT+TAB: Focuses on the previous element.
    focusNextElement(e.shiftKey);
  } else if (e.key === "Enter") {
    // ENTER: Triggers a click on the currently focused element.
    e.preventDefault();
    clickFocusedElement();
  }
};

/**
 * Focus on the next or previous element based on the direction.
 * @param backwards - If true, focus on the previous element (SHIFT+TAB).
 */
const focusNextElement = (backwards: boolean) => {
  // List of selectors for focusable elements.
  // it is advised to order the very specefic slectors first, then
  // the general selectors at bottom.
  let accessibleComponents = [
    "button.picker-opt",
    "button[opt-index]",
    "div.alert-radio-label",
    "ion-content > div",
    ".alert-radio-group > button",
    'input:not([type="hidden"])',
    "label:has(input) > span",
    "textarea",
    "alert-radio-group",
    "ion-datetime",
    "ion-item",
    "ion-icon",
    "ion-radio",
    "ion-checkbox",
    "ion-select",
    "button",
  ];

  let priorityContainers = [
    {
      name: "date_picker",
      container: ".picker-wrapper",
      elements: [
        "button.picker-opt-selected",
        " div.picker-toolbar-button > button",
        ".picker-wrapper div.picker-toolbar-cancel > button",
      ],
    },
    {
      name: "drop_down",
      container: ".alert-wrapper.ion-overlay-wrapper",
      elements: [
        ".alert-wrapper.ion-overlay-wrapper .alert-button-inner",
        ".alert-wrapper.ion-overlay-wrapper .alert-button-group button",
      ],
    },
  ];
  let focusableElements;

  for (let i = 0; i < priorityContainers.length; i++) {
    if (
      priorityContainers[i].container &&
      document.querySelector(priorityContainers[i].container)
    ) {
      focusableElements = Array.from(
        document.querySelectorAll(priorityContainers[i].elements.join(","))
      ) as HTMLElement[];

      console.log(
        `The container [${priorityContainers[i].name}] detected. || [els: ${focusableElements.length}]`
      );
      break;
    }
  }

  if (!focusableElements?.length) {
    focusableElements = Array.from(
      document.querySelectorAll(accessibleComponents.join(","))
    ) as HTMLElement[];
  }

  // If no focusable elements found, quit the function.
  if (focusableElements.length === 0) return;

  focusableElements.forEach((element) => {
    element.classList.remove("alerts-border");
    const isOptBtnIdx =
      parseInt(element.getAttribute("opt-index")) || undefined;
    if (isOptBtnIdx) {
      const prevOpt = element.parentElement.querySelector(
        `button[opt-index="${isOptBtnIdx - 1}"]`
      );
      const newOpt = element.parentElement.querySelector(
        `button[opt-index="${isOptBtnIdx + 1}"]`
      );

      prevOpt?.classList.remove("alerts-border");
      newOpt?.classList.remove("alerts-border");

      focusableElements.push(prevOpt as HTMLElement);
      focusableElements.push(newOpt as HTMLElement);
    }
  });

  // use consistent indexing
  let currentIndex = curr_index;

  // If no element is focused, or the last element is focused, set focus to the first element.
  if (
    currentIndex === -1 || // If no element is focused yet.
    currentIndex === focusableElements.length - 1 || // If the last element is focused.
    (backwards && currentIndex === 0) // If moving backwards and the current element is the first in the list.
  ) {
    // Focus on the first element.
    focusableElements[0].classList.add("alerts-border");
    curr_focused = focusableElements[0];
    curr_index = 0;
  } else {
    // Otherwise, set focus to the next or previous element in the array.

    // Define the direction of movement (1 for TAB, -1 for SHIFT+TAB).
    const step = backwards ? -1 : 1;

    // Calculate the next index based on the direction.
    const nextIndex = currentIndex + step;

    // Focus on the next or previous element.
    if (focusableElements[nextIndex]) {
      curr_focused = focusableElements[nextIndex];
    } else {
      curr_focused = focusableElements[0];
      curr_index = 0;
    }
    curr_focused?.classList.add("alerts-border");

    curr_index += step;
  }
};

/**
 * Handle click on the currently focused element.
 */
const clickFocusedElement = () => {
  console.log("Trying to simulate click on: ", curr_focused);

  const prevFocusedElement = document.activeElement as HTMLInputElement;

  // If there is a focused element, blur it to remove focus
  if (prevFocusedElement) {
    prevFocusedElement.blur();
  }

  if (curr_focused?.["s-hn"]?.toLowerCase().match(/textarea|input/)) {
    curr_focused.focus();
  }

  simulateHumanClick(curr_focused);
};

export default TabAccessibility;
