import { IonCheckbox, IonModal } from "@ionic/react";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { useConfig } from "../../contexts/ConfigContext";
import useToast from "../../hooks/useToast";
import { leaveStudy } from "../../services/study.service";
import AuthContext from "../../contexts/AuthContext";

interface Props {
  isOpen: boolean;
  onDismiss(): void;
}

const LeaveStudyModal: FC<Props> = ({ isOpen, onDismiss }) => {
  const { t } = useTranslation();
  const [allow_access_data, set_allow_access_data] = useState(true);
  const history = useHistory();
  const { study } = useConfig();
  const { study_slug } = useParams<{ study_slug: string }>();
  const present = useToast();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user, endSession } = useContext(AuthContext);
  
  const handleConfirmOptOut = async () => {
    try {
      await leaveStudy(study.id, { allow_access_data });
      await endSession();
      history.push(`/${study_slug}`);
    } catch (e) {
      present(e?.response?.data?.message);
    }
  };

  return (
    <IonModal
      cssClass="resonance-modal"
      isOpen={isOpen}
      backdropDismiss={true}
      onWillDismiss={onDismiss}
    >
      <Container onClick={onDismiss}>
        <Card onClick={(e) => e.stopPropagation()}>
          <Title>{t("profile_opt_out")}</Title>
          <Message>{t("profile_opt_out_message")}</Message>

          <Question>
            <IonCheckbox
              style={{ marginRight: 10 }}
              onIonChange={(e) => set_allow_access_data(e.detail.checked)}
              checked={allow_access_data}
              mode="md"
            />
            {t("profile_allow_access_data")}
          </Question>

          <Buttons>
            <ModalButton onClick={onDismiss}>{t("common_cancel")}</ModalButton>
            <ModalButton onClick={handleConfirmOptOut}>
              {t("confirm_quit_study_button")}
            </ModalButton>
          </Buttons>
        </Card>
      </Container>
    </IonModal>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;

const Message = styled.div`
  padding: 10px 0;
`;

const Question = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  font-size: 14px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
`;

const ModalButton = styled.div`
  padding: 10px 20px;
  color: ${(props) => props.theme.primary.main};
`;

export default LeaveStudyModal;
