import { IonContent } from "@ionic/react";
import { chevronForward, stopwatchOutline } from "ionicons/icons";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Card from "../../components/atoms/Card";
import Info from "../../components/atoms/ErrorComponent";
import Icon from "../../components/atoms/Icon";
import ResonanceLoader from "../../components/atoms/ResonanceLoader/ResonanceLoader";
import SmallButton from "../../components/atoms/smallButton";
import { useAuth } from "../../contexts/AuthContext";
import { useConfig } from "../../contexts/ConfigContext";
import { useFetch } from "../../hooks/useFetch";
import { getComponents, getQuestionnairesByComponents } from "../../services/questionnaire.service";
import { ComponentType, QuestionnaireDetailsType } from "../../types/Questionnaire";
import dayjs from "dayjs";
import { useResonanceLayout } from "../../components/organisms/ResonnanceLayout/ResoncanceContext";

const SequenceComponents: FC = (): ReactElement => {
  const { sequenceId } = useParams<{ sequenceId: string }>();
  const history = useHistory();
  const { study } = useConfig();
  const { study_slug } = useParams<{ study_slug: string }>();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { user } = useAuth();
  const { setResonanceLayout, questionnaires } = useResonanceLayout();

  useEffect(() => {
    setResonanceLayout({
      disableHeader: false,
      disableNav: false,
      backgroundColor: "white",
      title: "questionnaire_title",
    });

    return () =>
      setResonanceLayout({
        disableHeader: false,
        disableNav: false,
        backgroundColor: "white",
        title: "questionnaire_title",
      });
  }, [setResonanceLayout]);

  const {
    isError: isErrorQuestionnaires,
    isLoading: isLoadingQuestionnaires,
    data: questionnairesBDD,
  } = useFetch<ComponentType[]>("questionnairesBDD", getQuestionnairesByComponents, {
    studyId: study.id,
    userId: user.id,
    lang: language,
  });

  useEffect(() => {
    if (questionnairesBDD) {
      if (questionnaires === undefined)
        setResonanceLayout({ questionnaires: questionnairesBDD });
    }
  }, [questionnairesBDD, setResonanceLayout, questionnaires]);

  const EmptySequencesMessage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    height: 100%;
    text-align: center;
    padding: 20px;
  `;

  if (study.is_gamified && questionnaires && questionnaires.length === 0) {
    return (
      <EmptySequencesMessage>{t("sequence_empty_list")}</EmptySequencesMessage>
    );
  }

  if (study.is_gamified) {
    
    const onComponentClick = (sequence_id: number, component_id: number) => {
      history.push(
        `/${study_slug}/resonance/sequences/${sequence_id}/components/${component_id}`
      );
    };

    if (isLoadingQuestionnaires && questionnaires == undefined) {
      return <ResonanceLoader />;
    }

    if (isErrorQuestionnaires)
      return (
        <Info
          title={t("common_server_error_title")}
          description={t("common_server_error_desc")}
          actionText={t("common_go_back")}
        />
      );

    return (
      <IonContent>
        {questionnaires?.map((questionnaire: ComponentType & QuestionnaireDetailsType) => {
          const Renderer = questionnaire.questionnaire_id ? QuestionnaireGamified : ContentGamified;
          const isFinished = questionnaire.answered_questions_count / questionnaire.questions_count === 1;

          if (!isFinished) {
            return (
              <Renderer
                key={questionnaire.questionnaire_id}
                questionnaire={questionnaire}
                onComponentClick={onComponentClick}
              />
            );
          } else {
            return null;
          }
        })}
        <Blank />
      </IonContent>
    );
  } else {
    const onComponentClick = (component: ComponentType) => {
      history.push(
        `/${study_slug}/resonance/sequences/${sequenceId}/components/${component.id}`
      );
    };

    if (isLoadingQuestionnaires) return <ResonanceLoader />;

    if (isErrorQuestionnaires)
      return (
        <Info
          title={t("common_server_error_title")}
          description={t("common_server_error_desc")}
          actionText={t("common_go_back")}
        />
      );

    return (
      <IonContent>
        {questionnaires?.map((component: ComponentType) => {
          const Renderer = component.questionnaire_id ? Questionnaire : Content;

          return (
            <Renderer
              key={component.id}
              component={component}
              onComponentClick={onComponentClick}
            />
          );
        })}
      </IonContent>
    );
  }
};

interface Props {
  component: ComponentType;
  onComponentClick(c: ComponentType): void;
}

interface PropsGamified {
  questionnaire: ComponentType & QuestionnaireDetailsType;
  onComponentClick(s: number, c: number): void;
}

const QuestionnaireGamified: FC<PropsGamified> = ({ questionnaire: questionnaire, onComponentClick }) => {
  const { study } = useConfig();
  const { t, i18n: { language }, } = useTranslation();
  // Utility function to get points based on sequence_id
const getPointsForSequence = (sequenceId: string): number => {
  const id = parseInt(sequenceId);
  if (id >= 71 && id <= 80) return 75;
  if ((id >= 81 && id <= 85) || (id >= 87 && id <= 91)) return 25;
  if ((id >= 92 && id <= 101) || (id >= 102 && id <= 111)) return 30;
  if (id >= 112 && id <= 121) return 20;
  return 0;
};

// Function to determine display points
const displayPoints = (questionnaire: ComponentType): number => {
  if (questionnaire.points === 0) {
    return getPointsForSequence(questionnaire.sequence_id.toString());
  }
  return questionnaire.points;
};

  const [imageSrc, setImageSrc] = useState('/assets/icon/landscapes/climbing.png');

  useEffect(() => {
    const checkImageExists = (src) => {
      const img = new Image();
      img.onload = () => setImageSrc(src);
      img.onerror = () => setImageSrc('/assets/icon/landscapes/climbing.png');
      img.src = src;
    };

    if (questionnaire.keywords) {
      const keywordImage = `/assets/icon/landscapes/${questionnaire.keywords}.png`;
      checkImageExists(keywordImage);
    }
  }, [questionnaire.keywords]);

  return (
    <QuestionnaireCard onClick={() => onComponentClick(questionnaire.sequenceId, questionnaire.id)}>
      <QuestionnaireTitle>{questionnaire.questionnaire_title}</QuestionnaireTitle>
      <QuestionnaireImage src={imageSrc} />
      <QuestionnaireIcons>
        <QuestionnaireIcon src="/assets/icon/doc.svg" />
        <QuestionnaireIconLabel>
          {questionnaire.questions_count} {t("questionnaire_questionnaire")}
        </QuestionnaireIconLabel>
        <QuestionnaireIcon src={stopwatchOutline} />
        <QuestionnaireIconLabel>
          {questionnaire.avg_amount_time_to_answer} {t("questionnaire_minutes")}
        </QuestionnaireIconLabel>
      </QuestionnaireIcons>
         
      <br/>
      
      <QuestionnaireIcons>
        <QuestionnaireIcon src="/assets/icon/gift.svg" />
        <QuestionnaireIconLabel>
        {displayPoints(questionnaire)} {t("questionnaire_point")}
        </QuestionnaireIconLabel>
      </QuestionnaireIcons>
      
      <QuestionnaireMeta>
        <div>Deadline: {dayjs(questionnaire.deadline).format("DD/MM/YYYY")}</div>
        <QuestionnaireButton>
          {questionnaire.answered_questions_count > 0
            ? t("questionnaire_continue")
            : t("questionnaire_start")}
        </QuestionnaireButton>
      </QuestionnaireMeta>

      <QuestionnaireProgress
        value={
          (questionnaire.answered_questions_count / questionnaire.questions_count) * 100
        }
      />
    </QuestionnaireCard>
  );
};

const ContentGamified: FC<PropsGamified> = ({ questionnaire: questionnaire, onComponentClick }) => {
  return (
    <ComponentCard
      onClick={() => onComponentClick(questionnaire.content_id, questionnaire.questionnaire_id)}
    >
      <div>
        <ComponentTitle>{questionnaire.content_label}</ComponentTitle>
      </div>

      <ComponentIcon icon={chevronForward} />
    </ComponentCard>
  );
};

const Questionnaire: FC<Props> = ({ component, onComponentClick }) => {
  const { t } = useTranslation();
  
  return (
    <QuestionnaireCard onClick={() => onComponentClick(component)}>
      <QuestionnaireTitle>{component.questionnaire_title}</QuestionnaireTitle>
      <QuestionnaireMeta>
        <div>Deadline: {dayjs(component.deadline).format("DD/MM/YYYY")}</div>
        <QuestionnaireButton>
          {component.answered_questions_count > 0
            ? t("questionnaire_continue")
            : t("questionnaire_start")}
        </QuestionnaireButton>
      </QuestionnaireMeta>
      <QuestionnaireIcons>
        <QuestionnaireIcon src="/assets/icon/doc.svg" />
        <QuestionnaireIconLabel>
          {component.questions_count} {t("questionnaire_questionnaire")}
        </QuestionnaireIconLabel>
        <QuestionnaireIcon src={stopwatchOutline} />
        <QuestionnaireIconLabel>
          {component.avg_amount_time_to_answer} {t("questionnaire_minutes")}
        </QuestionnaireIconLabel>
      </QuestionnaireIcons>

      <QuestionnaireProgress
        value={
          (component.answered_questions_count / component.questions_count) * 100
        }
      />
    </QuestionnaireCard>
  );
};

const Content: FC<Props> = ({ component, onComponentClick }) => {
  return (
    <ComponentCard
      onClick={() => onComponentClick(component)}
    >
      <div>
        <ComponentTitle>{component.content_label}</ComponentTitle>
      </div>

      <ComponentIcon icon={chevronForward} />
    </ComponentCard>
  );
};

const ComponentCard = styled(Card)`
  padding: 20px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const QuestionnaireCard = styled(Card)`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Blank = styled.div`
  width: 100%;
  height: 50px;
`;

const QuestionnaireProgress = styled.div<{ value: number }>`
  width: 100%;
  margin: 10px 0;
  height: 10px;
  background-color: ${(p) => p.theme.primary.main}33;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: ${(p) => p.value}%;
    height: 100%;
    background-color: ${(p) => p.theme.primary.main};
    z-index: 999999;
  }
`;

const QuestionnaireTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;
`;

const QuestionnaireButton = styled(SmallButton)`
  background-color: ${(p) => p.theme.secondary.main};
  color: ${(p) => p.theme.secondary.contrast};
  font-weight: 600;
`;

const QuestionnaireIcons = styled.div`
  display: flex;
  justify-content: row;
  align-items: center;
  width: 100%;
`;

const QuestionnaireIconLabel = styled.span`
  margin-left: 5px;
  margin-right: 10px;
`;
const QuestionnaireIcon = styled(Icon)`
  color: ${(p) => p.theme.primary.main};
  font-size: 25px;
  padding: 0;
  margin: 0;
`;

const QuestionnaireImage = styled.img`
  position: absolute;
  color: ${(p) => p.theme.primary.main};
  right: 30px;
  padding: 0;
  margin: 0;
`;

const QuestionnaireMeta = styled.div`
  display: flex;
  justify-content: row;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0 0px;
`;

const ComponentTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const ComponentIcon = styled(Icon)`
  color: #929292;
  font-size: 20px;
`;

export default SequenceComponents;
