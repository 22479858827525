import { IonModal } from "@ionic/react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import { useConfig } from "../../contexts/ConfigContext";
import useToast from "../../hooks/useToast";

interface Props {
  isOpen: boolean;
  onDismiss(): void;
  points: number;
}

const PointModal: FC<Props> = ({ isOpen, onDismiss, points }) => {
  const { t } = useTranslation();
  const [allow_access_data, set_allow_access_data] = useState(true);
  const history = useHistory();
  const { study } = useConfig();
  const { study_slug } = useParams<{ study_slug: string }>();
  const present = useToast();

  const handleConfirmOptOut = async () => {
      history.push(`/${study_slug}/resonance/sequences`);
  }

  return (
    <IonModal
      cssClass="resonance-modal"
      isOpen={isOpen}
      backdropDismiss={true}
      onWillDismiss={onDismiss}
    >
      <Container onClick={onDismiss}>
        <Card onClick={(e) => e.stopPropagation()}>
          <Title>{t("thank_you")} &#x1F973;</Title>
          <Subtitle>{t("you_earned_part1")}{points}{t("you_earned_part2")}</Subtitle>
          <Message>{t("other_questionnaire")}</Message>

          <Buttons>
              <ModalButton onClick={onDismiss}>{t("come_back_later_button")}</ModalButton>
              <ModalButton2 onClick={handleConfirmOptOut}>{t("doing_questionnaire_button")}</ModalButton2>
          </Buttons>
        </Card>
      </Container>
    </IonModal>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Card = styled.div`
  width: 90%;
  max-width: 400px;
  background-color: #FFFFFF;
  border-radius: 25px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.div`
  font-size: 22px;
  text-align: center;
  margin-bottom: 10px;
  color: black;
`;

const Subtitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: black;
`;

const Message = styled.div`
  font-size: 20px;
  padding: 0px 25px;
  text-align: left;
  color: black;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  align-self: flex-end;
  position: relative;
  bottom: -5vh;
`;

const ModalButton = styled.button`
  background-color: ${(props) => props.theme.secondary.background};
  margin: 0px 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: black;
`;

const ModalButton2 = styled.button`
  background-color: ${(props) => props.theme.secondary.main};
  margin: 0px 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: black;
`;

export default PointModal;
