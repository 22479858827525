export const forgotPassword = {
    "forgot_pwd_title": "Mot de passe oublié",
    "forgot_pwd_email": "Email",
    "forgot_pwd_phone": "Téléphone",
    "forgot_pwd_email_phone": "Email ou Téléphone",
    "forgot_pwd_error": "Erreur lors de l'envoi",
    "forgot_pwd_success": "Email envoyé",
    "forgot_pwd_submit": "Récupérer le mot de passe",
    "reset_pwd_title": "Réinitialiser votre mot de passe",
    "reset_pwd_submit": "Réinitialiser le mot de passe",
    
}