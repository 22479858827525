import { useEffect, useState } from "react";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { useHomeContext } from "../../../../pages/Home/HomeContent";
import ResonanceLoader from "../../../atoms/ResonanceLoader/ResonanceLoader";
import DisplayContent from "../../../molecules/DisplayContent";
import NextPrevButtons from "../../../molecules/NextPrevButtons";
import { useSetResonanceLayout } from "../../ResonnanceLayout/ResoncanceContext";
import useOnBoardingApi from "./OnBoarding/useOnBoardingApi";
import Button from "../../../atoms/Button";
import VeriticalCenter from "../../../atoms/VerticalCenter";
import { postTestAccount } from "../../../../services/user.service";
import { useAuth } from "../../../../contexts/AuthContext";

interface DisplayNotIncludedProps {
  contentId: number;
  onTryTheApp: () => void;
}

const DisplayNotIncluded = ({
  contentId,
  onTryTheApp
}: DisplayNotIncludedProps): ReactElement => {
  const {
    i18n: { language },
  } = useTranslation();

  const { welcomeData, goPrevious, previousStep } = useHomeContext();
  const [ copied, setCopied] = useState(false);
  const studyId = welcomeData.id;
  const { user: userAuth } = useAuth();

  const { content, loadContent } = useOnBoardingApi(
    studyId,
    contentId,
    language
  );

  useEffect(() => {
    loadContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();
  const { t } = useTranslation();

  useSetResonanceLayout({
    backgroundColor: theme["primary"].background,
    disableHeader: true,
  });

  const handleOnClick = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  const tryTheApp = async () => {
    try {
      const result = await postTestAccount(studyId, userAuth.id);
      if (result.status === 204) {
        onTryTheApp();
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  if (!content) {
    return <ResonanceLoader />;
  }

  return (
    <Container>
      <VeriticalCenter style={{alignItems: "center"}}>
      <Group>
        <SubTitle>{t('home_not_included')}</SubTitle>
        <SubTitle>{t('home_not_included_desc')}</SubTitle>
        <StyledButton onClick={handleOnClick}>{t("home_copy_link")}</StyledButton>
        {copied && (
          <SubTitle>{t('home_copied')}</SubTitle>
        )}
        <p 
          style={{ cursor: "pointer", textUnderlineOffset: "0.2em", textDecoration: "underline", color: "black"}}
          onClick={tryTheApp}>
          {t("not_eligible_but_try_the_app")}
        </p>
      </Group>
      </VeriticalCenter>
    </Container>
  );
};

const Container = styled.div`
  color: white;
  height: 100vh;

`;

const SubTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin: 10px 10px 10px 5px;
  display: flex;
  justify-content: center;
  color: black;
`;

const StyledButton = styled(Button)`
  padding: 0 50px;
  margin: 20px 0;
`;

const Group = styled.div`
  height: 100%;
  width: 80vw;
  display : flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export default DisplayNotIncluded;
