import { IonModal } from "@ionic/react";
import { useFormik } from "formik";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TextField } from "../../components/atoms/LoginTextField";
import * as Yup from "yup";
import { forgotPassword } from "../../services/user.service";
import { useConfig } from "../../contexts/ConfigContext";
import Icon from "../../components/atoms/Icon";
import { alertCircle } from "ionicons/icons";
import useToast from "../../hooks/useToast";

interface Props {
  isOpen: boolean;
  onDismiss(): void;
}

interface forgotPasswordFormType {
  login: string;
}

// const forgotPasswordSchema = Yup.object().shape({
//     login: Yup.string().email().required(),
// });

const frenchMobileRegExp = /^(06|07)[0-9]{8}$/;

const forgotPasswordSchema = Yup.object().shape({
  login: Yup.mixed()
    .test(
      'is-email-or-french-mobile',
      'Input must be a valid email or French mobile number',
      value => Yup.string().email().isValidSync(value) || frenchMobileRegExp.test(value)
    )
    .required('Email or Phone is required'),
});

const ForgotPwdModal: FC<Props> = ({ isOpen, onDismiss }) => {
  const { t } = useTranslation();
  const { study } = useConfig();
  const [isError, setIsError] = useState(false);
  const present = useToast();

  const handleSubmit = async (values: forgotPasswordFormType) => {
    try {
      setIsError(false);
      await forgotPassword(
            {
            studyId: study.id,
            },
            {
            login: values.login,
            }
      );
      onDismiss();
    } catch (e) {
      setIsError(true);
      present(e?.response?.data?.message);
    }
  };

  const { values, touched, errors, submitForm, setFieldValue } =
    useFormik<forgotPasswordFormType>({
      initialValues: {
        login: "",
      },
      onSubmit: handleSubmit,
      validationSchema: forgotPasswordSchema,
    });

  return (
    <IonModal isOpen={isOpen} backdropDismiss={true} onWillDismiss={onDismiss}>
      <Container onClick={onDismiss}>
      <Card onClick={(e) => e.stopPropagation()}>
        <Title>{t("forgot_pwd_title")}</Title>

        <TextField
          value={values.login}
          type="text"
          error={touched.login && errors.login}
          onChange={(value) => setFieldValue("login", value)}
          label={t("forgot_pwd_email_phone")}
          placeholder={t("forgot_pwd_email_phone")}
        />

        {isError && (
          <Error>
            <Icon icon={alertCircle} />
            {t("forgot_pwd_error")}
          </Error>
        )}
        <Buttons>
          <ModalButton onClick={onDismiss}>{t("common_cancel")}</ModalButton>
          <ModalButtonSubmit onClick={submitForm}> {t("forgot_pwd_submit")}</ModalButtonSubmit>
        </Buttons>
        </Card>
      </Container>
    </IonModal>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Error = styled.div`
  color: red;
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 15px;
`;

const Card = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ModalButton = styled.div`
  padding: 10px 20px;
  color: ${(props) => props.theme.primary.main};
  cursor: pointer;
`;

const ModalButtonSubmit = styled(ModalButton)`
  background-color: ${(props) => props.theme.primary.background};
  color: ${(props) => props.theme.primary.contrast};
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
`;

export default ForgotPwdModal;
