import { useFormik } from 'formik';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IdentificationMethodEnum, SecretQuestionType } from '../../../../types/profile';
import FormButton from '../../../atoms/FormButton';
import DisplaySecretQuestions from '../../../molecules/Home/Login/DisplaySecretQuestions';
import * as Yup from 'yup';

interface SecretQuestionsFormProps {
  secretQuestions: SecretQuestionType[];
  onConnect(arg: any): void;
}

const SecretQuestionsForm = ({ secretQuestions, onConnect }: SecretQuestionsFormProps): ReactElement => {
  const {t} = useTranslation();

  const connectUser = (values)=> {
    const body = {
      [IdentificationMethodEnum.SECRET_QUESTIONS] : Object.keys(values).map(key => ({
        identification_question_id: key,
        answer: values[key]
      }))
    }

    onConnect(body);
  }

  const formSchema = useMemo(()=>{

    const obj = {}

    secretQuestions.forEach(question => {
      obj[question.id] = Yup.string().required();
    })

    return Yup.object().shape(obj);
  }, [secretQuestions])

  const initialValues = useMemo(()=>{

    const obj = {}

    secretQuestions.forEach(question => {
      obj[question.id] = '';
    })

    return obj;
  }, [secretQuestions])

  const {values, touched, errors, submitForm, setFieldValue} = useFormik({
    initialValues: initialValues,
    onSubmit: connectUser,
    validationSchema: formSchema
  });

  const filteredErrors = useMemo(function(){
    return Object.keys(touched).reduce((acc, curr)=>{
      if(touched[curr]) return {
        ...acc,
        [curr]: errors[curr]
      }
      return acc
    }, {})
  }, [errors, touched])


  return (
    <>
      <DisplaySecretQuestions
        secretQuestions={secretQuestions}
        answers={values}
        setFieldValue={(key, value) => setFieldValue(key.toString(), value)}
        errors={filteredErrors}
        touched={touched}
      />
      <FormButton onClick={submitForm}>
        {t('home_login_button')}
      </FormButton>
    </>
  );
};

export default SecretQuestionsForm;
