import { Storage, Drivers } from '@ionic/storage';

let storage: Storage;

const init = (name: string) => {
  storage = new Storage({
      name,
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
  });
  storage.create();
}


const set = (key: string, val: unknown): Promise<void> => storage.set(key, val);

const get = async (key: string): Promise<any> => {
    const val = await storage.get(key);
    return val;
}

const remove = (key: string): Promise<void> => storage.remove(key);

const clear = async (): Promise<void> => storage.clear();

const ResonanceStore = {
  init,
  set,
  get,
  remove,
  clear
}

export default ResonanceStore;
