export const common = {
  common_cancel: 'Cancel',
  common_close: 'Close',
  common_questionnaire: 'Questionnaire',
  common_clear: 'Clear',
  common_save: 'Save',
  common_mail: 'Email',
  common_sms: 'Sms',
  common_copy: 'Copy',
  common_name: 'First name',
  common_surname: 'Last name',
  common_lang: 'Language',
  common_phone: 'Phone',
  common_next: 'Next',
  common_previous: 'Previous',
  common_details: 'Details',
  common_send: 'Send',
  common_start: 'Start',
  common_password: 'Password',
  common_click_to_speak: 'Click to speak',
  common_server_error_title: 'A server error has occurred',
  common_server_error_desc: 'Please try again or contact us',
  common_go_back: 'Go back',
  common_nodata_title: 'There is no data available',
  common_nodata_desc: 'Please try again or contact us',
  common_bad_request: 'Bad request',
  common_no_slug: 'Please click on the invitation link in your email or SMS messages',
  confirm_quit_study_button: 'Leave Permanently',
  come_back_later_button: 'I will come back later',
  doing_questionnaire_button: 'I am doing a questionnaire',
  help_link_part1: 'If you are not feeling well, you can ',
  help_link_part2: 'get help by clicking here',
  thank_you: 'Thank you!',
  you_earned_part1: 'You have earned ',
  you_earned_part2: ' points!',
  other_questionnaire: 'You can help us further by filling out another questionnaire.'
};
