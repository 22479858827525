//Por Synakene

export const onboarding = {
  onboarding_title: "Mentalo",
  onboarding_subtitle: 'Nuestra misión',
  onboarding_header_text: 'Conocimiento sobre el bienestar mental de los jóvenes.',
  onboarding_header_text2: "Para ayudarnos, ayudarte y ayudar a otros, necesitarás:",
  onboarding_step1: 'Paso 1',
  onboarding_step1_text: 'Habla sobre ti mismo',
  onboarding_step2: 'Paso 2',
  onboarding_step2_text: "Proporcionar actualizaciones 7 veces al año en 5 minutos",
  onboarding_step3: 'Paso 3',
  onboarding_step3_text: 'Sigue tu progreso',
  onboarding_step4: 'Paso 4',
  onboarding_step4_text: 'Sé un embajador invitando a tus amigos',
};
