export const common = {
  common_cancel: 'Annuler',
  common_close: 'Fermer',
  common_questionnaire: 'Questionnaire',
  common_clear: 'Effacer',
  common_save: 'Enregistrer',
  common_mail: 'Email',
  common_sms: 'Sms',
  common_copy: 'Copier',
  common_name: 'Prénom',
  common_surname: 'Nom',
  common_lang: 'Langue',
  common_phone: 'Téléphone',
  common_next: 'Suivant',
  common_previous: 'Précédent',
  common_details: 'Détails',
  common_send: 'Envoyer',
  common_start: 'Commencer',
  common_password: 'Mot de passe',
  common_click_to_speak: 'Cliquer pour parler',
  common_server_error_title: "Une erreur de serveur s'est produite",
  common_server_error_desc: "Veuillez réessayer ou contactez-nous",
  common_go_back: "Retour précédent",
  common_nodata_title: "Il n'y a pas de données disponibles",
  common_nodata_desc: "Veuillez réessayer ou contactez-nous",
  common_bad_request: "Requete mal formé",
  common_no_slug: "Merci de cliquer sur le lien de l'invitation sur votre boîte email ou messages SMS",
  confirm_quit_study_button: "Quitter Définitivement",
  come_back_later_button: "Je reviendrais plus tard",
  doing_questionnaire_button: "Je fais un questionnaire",
  help_link_part1: "Si tu ne te sens pas bien, tu peux ",
  help_link_part2: "obtenir de l'aide en cliquant ici",
  thank_you: "Merci !",
  you_earned_part1: "Tu as gagné ",
  you_earned_part2: " points !",
  other_questionnaire: "Tu peux encore nous aider en remplissant un autre questionnaire."
};
