import { CreatedByType } from './Common';

export enum QuestionTypeEnum {
  RADIOBUTTON = 'RADIOBUTTON',
  CHECKBOX = 'CHECKBOX',
  DROPDOWN = 'DROPDOWN',
  SLIDER = 'SLIDER',
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  HOUR = 'HOUR',
  DATE = 'DATE',
}

export enum QuestionTextTypeEnum {
  EMAIL = 'EMAIL',
  PARAGRAPH = 'PARAGRAPH',
  LINE = 'LINE'
}

export type DetailInterfaceByQuestionTypes = {
  CHECKBOX: CheckboxQuestionDetailsType,
  RADIOBUTTON: CommonQuestionDetailsType,
  DATE: DateQuestionDetailsType,
  DROPDOWN: CommonQuestionDetailsType,
  HOUR: HourQuestionDetailsType,
  NUMBER: NumberQuestionDetailsType,
  SLIDER: SliderQuestionDetailsType,
  TEXT: TextQuestionDetailsType,
};

// TODO: define integral_errorcode
interface IntegralType {
  is_integral: boolean,
  integral_errorcode: null
}

export interface CoreQuestionType {
  created_by?: CreatedByType;
  last_update_by?: CreatedByType;
  id: number;
  description: string;
  export_code: string;
  integral: IntegralType;
  is_mandatory_question: boolean;
  is_active: boolean;
  order: number;
  title: string;
  version: string;
  type: string;
}

// TODO: define rules object
interface CoreQuestionDetailsType {
  created_by?: CreatedByType;
  last_update_by?: CreatedByType;
  id?: number;
  version?: number;
}

interface CoreAnswerType {
  created_by?: CreatedByType;
  last_update_by?: CreatedByType;
  id: number;
  order?: number;
}

export interface CommonQuestionDetailsType extends CoreQuestionDetailsType {
  answers: CommonAnswersType[];
}

export interface CommonAnswersType extends CoreAnswerType {
  label: string;
}

export interface CheckboxQuestionDetailsType extends CoreQuestionDetailsType {
  answers: CheckboxAnswerType[];
  rules: CheckboxRulesType;
}

export interface CheckboxAnswerType extends CoreAnswerType {
  label: string;
  variable_name: string;
}

export interface RadioQuestionType extends CoreQuestionType {
  type: QuestionTypeEnum.RADIOBUTTON;
  detail: CommonQuestionDetailsType;
}

export interface CheckboxQuestionType extends CoreQuestionType {
  type: QuestionTypeEnum.CHECKBOX;
  detail: CheckboxQuestionDetailsType;
}

export interface DropdownQuestionType extends CoreQuestionType {
  type: QuestionTypeEnum.DROPDOWN;
  detail: CommonQuestionDetailsType;
}

export interface SliderQuestionType extends CoreQuestionType {
  type: QuestionTypeEnum.SLIDER;
  detail: SliderQuestionDetailsType;
}

export interface SliderQuestionDetailsType extends CoreQuestionDetailsType {
  minor_tick_interval: number;
  prefix: string;
  step: number;
  suffix: string;
  tick_interval: number;
  answers: SliderAnswerType[];
}

export interface SliderAnswerType extends CoreAnswerType {
  asset: string;
  label: string;
  value: number;
}

export interface TextQuestionType extends CoreQuestionType {
  type: QuestionTypeEnum.TEXT;
  detail: TextQuestionDetailsType;
}

export interface TextQuestionDetailsType extends CoreQuestionDetailsType {
  type: QuestionTextTypeEnum,
}

export interface NumberQuestionDetailsType extends CoreQuestionDetailsType {
  min_value: number;
  max_value: number;
  prefix: string;
  suffix: string;
  number_decimal: number;
  separator: NumberSeparatorEnum;
}

export interface HourQuestionDetailsType extends CoreQuestionDetailsType {
  format: HourFormatEnum,
  min_hour: string,
  max_hour: string
}

export interface DateQuestionDetailsType extends CoreQuestionDetailsType {
  format: DateFormatEnum;
  min_date: DateConstraintType;
  max_date: DateConstraintType;
}

export type DateConstraintType = (FixedDateType | RelativeDateType | DisableDateType) & { isMinDate?: boolean };

export interface FixedDateType {
  date_type: DateConstraintEnum.FIXED;
  todays_date_sens: null;
  todays_date_quantity: null;
  todays_date_unit: null;
  fixed_date: string;
}

export interface RelativeDateType {
  date_type: DateConstraintEnum.RELATIVE;
  todays_date_sens: DateSensEnum;
  todays_date_quantity: number;
  todays_date_unit: DateUnitEnum;
  fixed_date: null;
}

export interface DisableDateType {
  date_type: DateConstraintEnum.DISABLED;
  todays_date_sens: null;
  todays_date_quantity: null;
  todays_date_unit: null;
  fixed_date: null;
}

export type QuestionType =
  RadioQuestionType |
  CheckboxQuestionType |
  DropdownQuestionType |
  SliderQuestionType |
  TextQuestionType |
  NumberQuestionType |
  HourQuestionType |
  DateQuestionType;

export type QuestionDetailType =
  CommonQuestionDetailsType |
  NumberQuestionDetailsType |
  TextQuestionDetailsType |
  DateQuestionDetailsType |
  SliderQuestionDetailsType |
  HourQuestionDetailsType |
  DateQuestionDetailsType;

export interface NumberQuestionType extends CoreQuestionType {
  type: QuestionTypeEnum.NUMBER;
  detail: NumberQuestionDetailsType;
}

export interface HourQuestionType extends CoreQuestionType {
  type: QuestionTypeEnum.HOUR;
  detail: HourQuestionDetailsType;
}

export interface DateQuestionType extends CoreQuestionType {
  type: QuestionTypeEnum.DATE;
  detail: DateQuestionDetailsType;
}

export enum NumberSeparatorEnum {
  DOT = 'dot',
  COMMA = 'comma'
}

export enum HourFormatEnum {
  '12HOURS' = '12HOURS',
  '24HOURS' = '24HOURS',
  '24HOURS-AM/PM' = '24HOURS-AM/PM'
}

export enum DateFormatEnum {
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export enum DateConstraintEnum {
  RELATIVE = 'RELATIVE_DATE',
  FIXED = 'FIXED_DATE',
  DISABLED = 'DISABLED',
  NO_LIMIT = 'NO_LIMIT'
}

export enum DateSensEnum {
  MINUS = 'MINUS',
  PLUS = 'PLUS'
}

export enum DateUnitEnum {
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export interface CheckboxRulesType {
  constraint: CheckboxRulesEnum;
  quantity: number;
}

export enum CheckboxRulesEnum {
  NONE = 'NONE',
  AT_LEAST = 'AT_LEAST',
  EXACTLY = 'EXACTLY',
  AT_MOST = 'AT_MOST'
}
