import { AxiosResponse } from "axios";
import { getSequenceAnswersBodyType } from "../types/Answer";
import { setContentReadBodyType, setContentReadParamsType } from "../types/Questionnaire";
import { getApi, ParamsType, postApi } from "./resonanceApi";

export enum SequenceApiUrlsEnum {
  POST_CONTENT_READ = 'studies/{study_id}/users/{user_id}/sequences/{sequence_id}/contents/{content_id}/read',
  GET_SEQUENCE_ANSWERS = 'studies/{study_id}/users/{user_id}/sequences/{sequence_id}',
  SET_SEQUENCE_DONE = 'studies/{studyId}/users/{userId}/sequences/{sequenceId}/done',
}

export function setSequenceContentRead(params: setContentReadParamsType, body: setContentReadBodyType) {
  const url = SequenceApiUrlsEnum.POST_CONTENT_READ;
  return postApi(url, params, body)
}

export async function getSequenceAnswers(params: ParamsType): Promise<AxiosResponse<getSequenceAnswersBodyType>> {
  const url = SequenceApiUrlsEnum.GET_SEQUENCE_ANSWERS;
  return getApi<getSequenceAnswersBodyType>(url, params);
}

export function setSequenceDone(params: ParamsType): Promise<AxiosResponse> {
  const url = SequenceApiUrlsEnum.SET_SEQUENCE_DONE;
  return postApi(url, params, {});
}