import { chevronForwardOutline } from "ionicons/icons";
import { ReactElement, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import AuthContext from "../../../../contexts/AuthContext";
import { useConfig } from "../../../../contexts/ConfigContext";
import useQuery from "../../../../hooks/useQuery";
import { useHomeContext } from "../../../../pages/Home/HomeContent";
import { postDirectInclusion } from "../../../../services/invitation.service";
import {
  InvitationChannelEnum,
  sendInvitationFormType,
} from "../../../../types/invitation.types";
import Container from "../../../atoms/Container";
import Icon from "../../../atoms/Icon";
import NextPrevButtons from "../../../molecules/NextPrevButtons";
import InvitationFormModal from "../../Invitation/InvitationFormModal";
import { useHistory, useParams } from "react-router";
import useToast from "../../../../hooks/useToast";
import Title from "../../../atoms/Title";

const channels = [InvitationChannelEnum.EMAIL, InvitationChannelEnum.SMS];

function DisplayInvitation(): ReactElement {
  const { t } = useTranslation();
  const [channel, setChannel] = useState<InvitationChannelEnum>();
  const [isInvitModalVisible, setIsInvitModalVisible] = useState(false);
  const { study } = useConfig();
  const query = useQuery();
  const invitation_token = query.get("invitation");

  const { goPrevious } = useHomeContext();
  const history = useHistory();
  const { startSession } = useContext(AuthContext);
  const { study_slug } = useParams<{ study_slug: string }>();
  const present = useToast();


  function handleClickChannel(channel: InvitationChannelEnum) {
    setChannel(channel);
    setIsInvitModalVisible(true);
  }

  async function onSubmitForm(values: sendInvitationFormType) {
    try {
      const { data } = await postDirectInclusion(
        { studyId: study.id },
        {
          target_user: {
            firstname: values.firstname,
            lastname: values.lastname,
            email: values.email,
            phone_number: values.phone_number,
          },
          channel: channel,
          lang: values.lang,
          invitation_token: invitation_token,
        }
      );
      await startSession(data.token);
      history.replace(`/${study_slug}/resonance`);
    } catch (e) {
      present(e?.response?.data?.message);
      console.error(e);
    }
  }

  return (
    <Container>
      <Title>{t(`invite_channel_title`)}</Title>
      {channels.map((channel) => (
        <ChannelMethod onClick={() => handleClickChannel(channel)}>
          <ChannelLabel>{t(`invite_${channel}`)}</ChannelLabel>
          <ChannelIcon icon={chevronForwardOutline} />
        </ChannelMethod>
      ))}

      <InvitationFormModal
        isOpen={isInvitModalVisible}
        onDismiss={(): void => setIsInvitModalVisible(false)}
        channel={channel}
        onSaveClick={onSubmitForm}
      />
      <NextPrevButtons onPreviousClick={goPrevious} />
      
    </Container>
  );
}

const ChannelMethod = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px;
  margin: 0 20px;
  border-bottom: 1px solid #e3e3e3;
  cursor: pointer;
`;

const ChannelLabel = styled.div``;

const ChannelIcon = styled(Icon)`
  margin-left: auto;
  font-size: 18px;
  color: #9b9b9b;
`;

export default DisplayInvitation;
