import { FC } from "react";
import { useTranslation } from "react-i18next";
import Message from "../../components/organisms/Message";

interface Props {
  onClick(): void;
}

const FinishedQuestionnaires: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Message
      onClick={onClick}
      title={t("finished_questionnaire_title")}
      type="verified"
      buttonText={t("common_next")}
    />
  );
};

export default FinishedQuestionnaires;
