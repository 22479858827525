import { ReactElement } from "react";

import { arrowBack, arrowForward } from "ionicons/icons";

import Icon from "../atoms/Icon";
import styled from "styled-components";
import Button from "../atoms/Button";
import { useTranslation } from "react-i18next";

interface NextPrevButtonsProps {
  onPreviousClick?: () => void;
  onNextClick?: () => void;
  nextText?: string;
  prevText?: string;
  disablePrevious?: boolean;
  disableNext?: boolean;
}

const NextPrevButtons = ({
  onPreviousClick,
  onNextClick,
  nextText,
  prevText,
  disablePrevious,
  disableNext,
}: NextPrevButtonsProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Buttons>
      {onPreviousClick && (
        <PrevButton disabled={disablePrevious} onClick={onPreviousClick}>
          <Icon icon={arrowBack} />
          {prevText || t("common_previous")}
        </PrevButton>
      )}

      {onNextClick && (
        <NextButton disabled={disableNext} onClick={onNextClick}>
          {nextText || t("common_next")}
          <Icon icon={arrowForward} />
        </NextButton>
      )}
    </Buttons>
  );
};

const Buttons = styled.div`
 
  bottom: 0px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: auto;
  padding-bottom: 5px;
  
  @media (max-height: 400px) {
    position: fixed;
  }
`;

const PrevButton = styled(Button)`
  border-radius: 0 30px 30px 0;
  padding-left: 10px;
  margin-right: auto;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  @media (min-width: 800px) {
    border-radius: 30px 30px 30px 30px;
    padding-right: 20px;
    margin-left: 20px;
  }
`;

const NextButton = styled(Button)`
  border-radius: 30px 0 0 30px;
  padding-right: 10px;
  margin-left: auto;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  @media (min-width: 800px) {
    border-radius: 30px 30px 30px 30px;
    padding-left: 20px;
    margin-right: 20px;
  }
`;

export default NextPrevButtons;
