import { InvitationConfigType, InvitationDisplayType } from "../types/invitation.types";

export const convertInvitations = (configs: InvitationConfigType[]): InvitationDisplayType[] =>
  configs.reduce((acc: InvitationDisplayType[], {
    id,
    to_role,
    to_role_label,
    to_role_id,
    channel,
    to_role_config
  }: InvitationConfigType): InvitationDisplayType[] => {
    const newChannel = {
      channel,
      to_role_config,
      configId: id
    };

    const extistingIndex = acc.findIndex(({ to_role_id: roleId }: InvitationDisplayType): boolean => to_role_id === roleId);
    if (extistingIndex > -1) {
      acc[extistingIndex].channels.push(newChannel);

      return acc;
    }
    return [
      ...acc,
      {
        to_role,
        to_role_label,
        to_role_id,
        channels: [newChannel],
      }
    ];
  }, []);
