export const getDecimalStep = (nbDec: number): number => nbDec === 0 ? 1 : parseFloat(`0.${'0'.repeat(nbDec - 1)}1`);

export const numberToIsoDate = (year: number, month: number = 1, day: number = 1): string => {
  const date = new Date(Date.UTC(year, month, day));
  return date.toISOString().split('T')[0];
}

export const timeToIsoDate = (hour: number, minutes: number, ampm?: 'am' | 'pm'): string => {
  const date = new Date();
  const updatedHour = ampm === 'pm' && hour < 12 ? hour + 12 : ampm === 'am' && hour === 12 ? 0 : hour;
  date.setHours(updatedHour, minutes)
  const offset = date.getTimezoneOffset();
  date.setMinutes(minutes - offset);
  return date.toISOString();
}
