import { IonSpinner } from "@ionic/react";
import { ReactElement } from "react";
import styled from "styled-components";
import "./ResonanceLoader.scss";

const ResonanceLoader = (): ReactElement => (
  <Loader>
    <IonSpinner />
  </Loader>
);

const Loader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ResonanceLoader;
