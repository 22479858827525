import { CheckboxQuestionType, CheckboxRulesEnum, CheckboxRulesType, HourFormatEnum, HourQuestionType, NumberQuestionDetailsType, NumberQuestionType, QuestionTextTypeEnum, QuestionType, QuestionTypeEnum, TextQuestionType } from '../types/Question';
import { AnswerValueType, getSequenceAnswersAnswerType } from '../types/Answer';
import { validateEmail } from './string';
import moment from 'moment';

export const defaultAnswerValueByType: Record<QuestionTypeEnum, AnswerValueType> = {
  CHECKBOX: [],
  RADIOBUTTON: '',
  HOUR: '',
  NUMBER: null,
  DATE: '',
  SLIDER: null,
  DROPDOWN: '',
  TEXT: ''
}

const hasAnsweredCheckbox = (answer: number[]) => answer.length !== 0;
const hasAnsweredCommonString = (answer: string) => answer !== '';
const hasAnsweredCommonNumber = (answer: number) => answer !== null ? true : false;

export const hasAsweredFunctionsByType: Record<QuestionTypeEnum, (answer: AnswerValueType) => boolean> = {
  CHECKBOX: (answer: AnswerValueType): boolean => hasAnsweredCheckbox(answer as number[]),
  RADIOBUTTON: (answer: AnswerValueType): boolean => hasAnsweredCommonString(answer as string),
  HOUR: (answer: AnswerValueType): boolean => hasAnsweredCommonString(answer as string),
  NUMBER: (answer: AnswerValueType): boolean => hasAnsweredCommonNumber(answer as number),
  DATE: (answer: AnswerValueType): boolean => hasAnsweredCommonString(answer as string),
  SLIDER: (answer: AnswerValueType): boolean => hasAnsweredCommonNumber(answer as number),
  DROPDOWN: (answer: AnswerValueType): boolean => hasAnsweredCommonString(answer as string),
  TEXT: (answer: AnswerValueType): boolean => hasAnsweredCommonString(answer as string)
}

const validitateByConstraintType: Record<CheckboxRulesEnum, (nbValues: number, quantity: number) => boolean> = {
  NONE: (): boolean => true,
  EXACTLY: (nbValues: number, quantity: number): boolean => nbValues === quantity,
  AT_MOST: (nbValues: number, quantity: number): boolean => nbValues <= quantity,
  AT_LEAST: (nbValues: number, quantity: number): boolean => nbValues >= quantity
}

const isCheckboxAnswerValid = (
  values: number[] = [],
  rules: CheckboxRulesType
): boolean => {
  const { constraint, quantity } = rules;

  return validitateByConstraintType[constraint](values.length, quantity);
}

const isRadioAnswerValid = (): boolean => true;

const isHourAnswerValid = (question: HourQuestionType, answer: string): boolean => {
  
  const { min_hour, max_hour } = question.detail;
  const answerDate = new Date(answer)
  const offset = answerDate.getTimezoneOffset();
  answerDate.setMinutes(answerDate.getMinutes() + offset);
  const current = moment(answerDate);
  const min = moment(min_hour, 'HH:mm');
  const max = moment(max_hour, 'HH:mm');
 
  if (max.isBefore(min)) 
    return current.isSameOrBefore(max, 'minutes') || current.isSameOrAfter(min, 'minutes');
  return current.isSameOrBefore(max, 'minutes') && current.isSameOrAfter(min, 'minutes');
};

const checkDecimalNumber = (value: number, decNumber: number): boolean => {

  const numberOfDecimals = value.toString().split('.')[1]?.length || 0;

  return numberOfDecimals <= decNumber;
}

const checkMinMax = (value: number, min: number, max: number): boolean => value >= min && value <= max;

const isNumberAnswerValid = (svalue: string, details: NumberQuestionDetailsType): boolean => {
  const { number_decimal, min_value: min, max_value: max } = details;
  const value = parseFloat(svalue);
  return !isNaN(value) && checkDecimalNumber(value, number_decimal) && checkMinMax(value, min, max);
};

const isDateAnswerValid = (): boolean => true;

const isSliderAnswerValid = (): boolean => true;

const isDropdownAnswerValid = (): boolean => true;

const validitateByTextType: Record<QuestionTextTypeEnum, (value: string) => boolean> = {
  EMAIL: (value: string): boolean => validateEmail(value),
  PARAGRAPH: (): boolean => true,
  LINE: (): boolean => true
}

const isTextAnswerValid = (
  value: string = '',
  type: QuestionTextTypeEnum
): boolean => validitateByTextType[type](value);

export const validationFunctionsByType: Record<QuestionTypeEnum, (question: QuestionType, answer: AnswerValueType) => boolean> = {
  CHECKBOX: (question: QuestionType, answer: AnswerValueType): boolean => {
    const { detail: { rules } } = question as CheckboxQuestionType;
    return isCheckboxAnswerValid(answer as number[], rules)
  },
  RADIOBUTTON: isRadioAnswerValid,
  HOUR: isHourAnswerValid,
  NUMBER: (question: QuestionType, answer: AnswerValueType): boolean => {
    const { detail } = question as NumberQuestionType;
    return isNumberAnswerValid(answer as string, detail);
  },
  DATE: isDateAnswerValid,
  SLIDER: isSliderAnswerValid,
  DROPDOWN: isDropdownAnswerValid,
  TEXT: (question: QuestionType, answer: AnswerValueType): boolean => {
    const { detail: { type } } = question as TextQuestionType;
    return isTextAnswerValid(answer as string, type)
  }
}

export function getAnswerValue(
  type: QuestionTypeEnum,
  answer: getSequenceAnswersAnswerType
): AnswerValueType {
  switch (type) {
    case QuestionTypeEnum.CHECKBOX:
      return answer.answer_id;
    case QuestionTypeEnum.DROPDOWN:
    case QuestionTypeEnum.RADIOBUTTON:
      return answer.answer_id[0];
    default:
      return answer.value || defaultAnswerValueByType[type];
  }
}

export function formatAnswers(
  answers: getSequenceAnswersAnswerType[]
): getSequenceAnswersAnswerType[] {
  return answers.reduce((acc, curr: getSequenceAnswersAnswerType) => {
    const foundindex = acc.findIndex(
      (ans) => curr.question_id === ans.question_id
    );
    if (foundindex > -1) {
      acc[foundindex].answer_id.push(curr.answer_id);
    } else {
      acc.push({
        ...curr,
        answer_id: [curr.answer_id],
      });
    }
    return acc;
  }, []);
}


export function formatHour(hour: string, format: HourFormatEnum) {
  switch (format) {
    case HourFormatEnum['12HOURS']:
      return moment(hour, 'HH:mm').format('hh:mm A');
    case HourFormatEnum['24HOURS-AM/PM']:
    case HourFormatEnum['24HOURS']:
    default:
      return hour;
  }
}