import { Capacitor } from "@capacitor/core";
import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import AppUrlListener from "../AppUrlListener";
import AuthGuard from "../components/organisms/AuthGuard";
import { ResonanceLayoutProvider } from "../components/organisms/ResonnanceLayout/ResoncanceContext";
import ResonanceLayout from "../components/organisms/ResonnanceLayout/ResonnanceLayout";
import NoSlug from "../pages/Default/NoSlug";
import HomeContent from "../pages/Home/HomeContent";
import ResonanceRoutes from "../routes/ResonanceRoutes";

const StudyContent = () => {
  return (
    <ResonanceLayoutProvider>
      <ResonanceLayout>
        <HomeContent />
      </ResonanceLayout>
    </ResonanceLayoutProvider>
  )
}

const Navigator: React.FC = () => {
  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Redirect from="/" to="/no-slug" exact />
        <Route path="/" exact>
          <AppUrlListener />
          {!Capacitor.isNativePlatform() && <NoSlug />}
          {Capacitor.isNativePlatform() && <StudyContent />}
        </Route>
        <Route path="/:study_slug" exact>
          <StudyContent />
        </Route>
        <Route path="/:study_slug/resonance">
          <AuthGuard>
            <ResonanceLayoutProvider>
              <ResonanceLayout isConnected>
                <ResonanceRoutes />
              </ResonanceLayout>
            </ResonanceLayoutProvider>
          </AuthGuard>
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  );
};

export default Navigator;
