import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { InclusionQuestionType } from "../../../../types/profile";
import RadioButton from "../../../atoms/RadioButton";

interface DisplayInclusionQuestionsProps {
  inclusionQuestions: InclusionQuestionType[];
  values: { [key: string]: string };
  setFieldValue: (key: number, value: boolean) => void;
  errors: any;
  touched: any;
}

const DisplayInclusionQuestions = ({
  inclusionQuestions,
  touched,
  errors,
  values,
  setFieldValue,
}: DisplayInclusionQuestionsProps): ReactElement => {
  const { t } = useTranslation();

  const handleAnswer = (id: number | string, value: string) => {
    setFieldValue(Number(id), value === "true" ? true : false);
  };

  return (
    <>
      {inclusionQuestions.map(
        (
          { id, question }: InclusionQuestionType,
          index: number
        ): ReactElement => (
          <Question key={id}>
            <QuestionLabel>{question}</QuestionLabel>
            <div>
              <StyledRadionButton
                label={t("home_inclusion_answer_true")}
                value="true"
                onChange={handleAnswer}
                name={String(id)}
              />
              <StyledRadionButton
                label={t("home_inclusion_answer_false")}
                value="false"
                onChange={handleAnswer}
                name={String(id)}
              />
            </div>
          </Question>
        )
      )}
    </>
  );
};

const StyledRadionButton = styled(RadioButton)`
  margin-right: 20px;
`;

const Question = styled.div`
  padding: 10px 0;
  margin: 40px 20px;
  &:last-child {
    border: none;
  }
`;

const QuestionLabel = styled.div`
  font-size: 18px;
  margin-bottom: 8px;
  color: black;
  font-weight: bold;
`;

export default DisplayInclusionQuestions;
