export interface HikerStep {
  id: number;
  position: {
    x: number;
    y: number;
  };
}

export interface HikerIntermediatePosition {
  positions: {
    x: number;
    y: number;
  }[];
}

export const hikerSteps = [
  { id: 1, position: { x: 320, y: 720 }, flipOnLoading: false, flipAfterAnimation: false, scale: 1 },
  { id: 2, position: { x: 320, y: 720 }, flipOnLoading: false, flipAfterAnimation: false, scale: 1 },
  { id: 3, position: { x: 165, y: 695 }, flipOnLoading: false, flipAfterAnimation: false, scale: 0.75 },
  { id: 4, position: { x: 85, y: 635 }, flipOnLoading: true, flipAfterAnimation: true, scale: 0.55 },
  { id: 5, position: { x: 195, y: 550 }, flipOnLoading: true, flipAfterAnimation: false, scale: 0.5 },
  { id: 6, position: { x: 285, y: 482 }, flipOnLoading: false, flipAfterAnimation: true, scale: 0.5 },
  { id: 7, position: { x: 140, y: 440 }, flipOnLoading: true, flipAfterAnimation: true, scale: 0.45 },
  { id: 8, position: { x: 195, y: 358 }, flipOnLoading: true, flipAfterAnimation: false, scale: 0.40 },
  { id: 9, position: { x: 205, y: 214 }, flipOnLoading: true, flipAfterAnimation: false, scale: 0.35 },
];

export const hikerIntermediatePositions: HikerIntermediatePosition[] = [
  { positions: []},
  { positions: [{ x: 248, y: 707 }]},
  { positions: [{ x: 117, y: 668 }]},
  { positions: [{ x: 128, y: 585 }]},
  { positions: [{ x: 242, y: 521 }]},
  { positions: [{ x: 217, y: 464 }]},
  { positions: [{ x: 157, y: 399 }]},
  { positions: [{ x: 200, y: 281 }]}
];