import { FC } from "react";
import { useTranslation } from "react-i18next";
import Message from "../../components/organisms/Message";

interface Props {
  onClick: () => void;
}

const FinishedQuestionnaires: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Message
      onClick={onClick}
      title={t("finished_questionnaires_title")}
      type="finished"
      buttonText={t("finished_questionnaires_button")}
    />
  );
};

export default FinishedQuestionnaires;
