export const invite = {
  invite_title: 'دعوات',
  // Ajout Synakene
  invite_subtitle: 'ادع أصدقائك للمشاركة',
  invite_thank_you_participation: 'شكرًا لمشاركتك!',
  invite_friends_to_help: 'يمكنك مساعدتنا أكثر بدعوة أصدقائك.',
  invite_later: 'لاحقًا',
  invite_friends: 'أدعو أصدقائي',
  // invite_subtitle: 'اختر قناة لإرسال الدعوة',
  invite_modal_mail: 'إرسال دعوة عبر البريد الإلكتروني',
  invite_modal_EMAIL: 'إرسال دعوة عبر البريد الإلكتروني',
  invite_modal_sms: 'إرسال دعوة عبر الرسائل القصيرة',
  invite_modal_name_placeholder: 'مثل: John',
  invite_modal_surname_placeholder: 'مثل: Smith',
  invite_modal_email_placeholder: 'مثل: John.Smith@email.com',
  invite_modal_lang_placeholder: 'اختر',
  invite_modal_phone_placeholder: 'مثل: 0123456789',
  invite_copy_notification: 'تم النسخ إلى الحافظة',
  invite_empty_config: 'لا توجد خيارات دعوة متاحة',
  invite_channel_title: 'كيف تفضل أن تتم دعوتك؟',
  invite_EMAIL: 'البريد الإلكتروني',
  invite_SMS: 'الرسائل القصيرة',
  invite_PUBLIC_LINK: 'نسخ الرابط',
  invite_inclusion_direct: 'إدراج مباشر'
};
