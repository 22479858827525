import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResonanceLoader from "../../../atoms/ResonanceLoader/ResonanceLoader";
import { getStratificationQuestions } from "../../../../services/user.service";
import { StratQuestionType } from "../../../../types/welcome";
import DisplayStratQuestions from "../../../molecules/Home/Inscription/DisplayStratQuestions";
import Container from "../../../atoms/Container";
import NextPrevButtons from "../../../molecules/NextPrevButtons";
import ScreenTitle from "../../../atoms/ScreenTitle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useFetch } from "../../../../hooks/useFetch";
import Info from "../../../atoms/ErrorComponent";
import { useConfig } from "../../../../contexts/ConfigContext";
import { useHomeContext } from "../../../../pages/Home/HomeContent";
interface StratificationPageProps {
  onNextClick: (obj: any) => void;
}

const StratificationPage = ({
  onNextClick,
}: StratificationPageProps): ReactElement => {
  const { t } = useTranslation();

  const { selectedRole } = useConfig();
  const { welcomeData } = useHomeContext();
  const studyId = welcomeData.id;
  const roleId = selectedRole?.id;

  const {
    isError,
    isLoading,
    data: questions,
  } = useFetch<StratQuestionType[]>(
    "stratifications",
    getStratificationQuestions,
    { studyId, roleId }
  );

  const postAnswers = (values) => {
    const obj = questions.map(({ id, version, answers }) => ({
      question_id: id,
      question_version: version,
      answer_id: values[id],
      answer_version: answers.find((answer) => answer.id === Number(values[id]))
        .version,
    }));
    onNextClick(obj);
  };

  const validationSchema = useMemo(
    function () {
      const obj = {};
      questions?.forEach(({ id = 0 }) => {
        obj[id] = Yup.string().required();
      });

      return Yup.object().shape(obj);
    },
    [questions]
  );

  const initialValues = useMemo(
    function () {
      const obj = {};

      questions?.forEach(({ id = 0 }) => {
        obj[id] = "";
      });

      return obj;
    },
    [questions]
  );

  const { values, touched, errors, submitForm, setFieldValue } = useFormik({
    initialValues,
    onSubmit: postAnswers,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  if (isLoading) return <ResonanceLoader />;

  if (isError)
    return (
      <Info
        title={t("common_server_error_title")}
        description={t("common_server_error_desc")}
        actionText={t("common_go_back")}
      />
    );

  return (
    <Container>
      <ScreenTitle>{t("home_stratification_title")}</ScreenTitle>

      <DisplayStratQuestions
        stratQuestions={questions}
        values={values}
        setFieldValue={(key, value) => setFieldValue(key, value)}
        touched={touched}
        errors={errors}
      />

      <NextPrevButtons onNextClick={submitForm} />
    </Container>
  );
};

export default StratificationPage;
