import { ReactElement } from "react";
import { volumeHighOutline } from "ionicons/icons";
import { QuestionType } from "../../../../types/Question";
import Card from "../../../atoms/Card";
import styled from "styled-components";
import Icon from "../../../atoms/Icon";
import { useConfig } from "../../../../contexts/ConfigContext";

interface QuestionDisplayProps {
  answerComp: ReactElement;
  question: QuestionType;
  ruleMessage?: string;
  onReadQuestionClick: () => void;
}

const QuestionDisplay = ({
  answerComp,
  question,
  ruleMessage,
  onReadQuestionClick,
}: QuestionDisplayProps): ReactElement => {
  const { title, description, is_mandatory_question: mandatory } = question;

  const { selectedRole } = useConfig();
  const isVocalisationEnabled =
    selectedRole?.attributes?.is_vocalisation_enabled;

  return (
    <QuestionCard>
      {/* Question title + description */}
      <QuestionHeader>
        <div>
          <Title>{mandatory ? `${title} *` : title}</Title>
          <SubTitle>{description}</SubTitle>
        </div>
        {isVocalisationEnabled && (
          <HeaderIcon onClick={onReadQuestionClick} icon={volumeHighOutline} />
        )}
      </QuestionHeader>
      <Desc>{ruleMessage}</Desc>

      {answerComp}
    </QuestionCard>
  );
};

const QuestionCard = styled(Card)`
  padding: 20px;
  overflow: auto;
  max-height: 600px;

  @media (max-height: 730px) {
    max-height: 400px;
  }
  @media (max-height: 630px) {
    max-height: 350px;
  }
  @media (max-height: 580px) {
    max-height: 300px;
  }
  @media (max-height: 530px) {
    max-height: 250px;
  }
  @media (max-height: 480px) {
    max-height: 200px;
  }
`;

const QuestionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const HeaderIcon = styled(Icon)`
  color: ${(props) => props.theme.primary.main};
  padding: 5px;
  font-size: 30px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.primary.main};
  margin-bottom: 10px;
  font-size: 16px;
`;

const SubTitle = styled(Title)`
  font-size: 14px;
`;

const Desc = styled.div`
  color: grey;
  font-size: 14px;
  margin-top: 10px;
  border-bottom: 1px solid #a0a0a0;
  padding-bottom: 5px;
`;

export default QuestionDisplay;
