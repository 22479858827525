import { ReactElement, useEffect, useState } from 'react';
import styled, { createGlobalStyle } from "styled-components";
import { useTranslation } from 'react-i18next';
import { IonCheckbox, IonItem, IonLabel, IonRadio, IonRadioGroup } from '@ionic/react';

const ConsentChoice = ({consentValue, setConsentValue }): ReactElement => {
    const { t } = useTranslation();

    return (
      <div>
        <IonRadioGroup allowEmptySelection={true} value={consentValue} onIonChange={e => setConsentValue(e.detail.value)}>
            <CheckBox $bgcolor='yellow'>
                <Label>{t("home_content_check_text")}</Label>
                <Radio slot="start"value={true}/>
            </CheckBox>

            <CheckBox>
                <Label>{t("home_not_consent_text")}</Label>
                <Radio slot="start" value={false}/>
            </CheckBox>
        </IonRadioGroup>
      </div>
      );
    };


const Label = styled(IonLabel)`
    width : 90%;
    text-decoration : underline;
    font-weight: bold;
`

const Radio = styled(IonRadio)`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #B1ADA2;
    background-color : white;
    background-checked: #B1ADA2;

    margin: auto;
`

const CheckBox = styled.div<{$bgcolor?:string}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  border-radius : 15px;
  height : 5rem;
  margin-bottom : 14px;
  background-color : ${props => props.$bgcolor=='yellow' ? props.theme.secondary.main : props.theme.secondary.background};
`;


export default ConsentChoice;