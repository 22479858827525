import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IonButton, IonModal, IonIcon } from "@ionic/react";
import { checkmark } from "ionicons/icons";
import LanguageLabel from "./LanguageLabel";
import Card from "../../../atoms/Card";
import styled from "styled-components";
import { useConfig } from "../../../../contexts/ConfigContext";
import { getStudyLangs } from "../../../../services/study.service";

interface LanguageSelectorModalProps {
  modalVisible: boolean;
  onCloseClick: () => void;
}

const LanguageSelectorModal = ({
  modalVisible,
  onCloseClick,
}: LanguageSelectorModalProps): ReactElement => {
  const { i18n, t } = useTranslation();
  const { study } = useConfig();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [langs, setLangs] = useState<string[]>([]);

  useEffect(function () {
    loadLangs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadLangs() {
    const { data } = await getStudyLangs(study.id);
    setLangs([data.primary_lang, ...data.additional_langs]);
  }

  const handleItemClick = (lang: string): void => {
    i18n.changeLanguage(lang);
    localStorage.setItem('brows_lang', lang);
    onCloseClick();
  };

  const renderLang = (lang: string): ReactElement => {
    return (
      <Language key={lang} onClick={(): void => handleItemClick(lang)}>
        <LanguageLabel lang={lang} />
        {i18n.language === lang && (
          <IonButton slot="end" fill="clear">
            <IonIcon
              slot="icon-only"
              icon={checkmark}
              color="primary"
              size="medium"
            />
          </IonButton>
        )}
      </Language>
    );
  };

  return (
    <IonModal isOpen={modalVisible}>
      <Container>
        <Card>
          <Title>{t("menu_languages_modal_title")}</Title>
          {langs?.map(renderLang)}
          <Button onClick={onCloseClick}>{t("common_cancel")}</Button>
        </Card>
      </Container>
    </IonModal>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  margin: 20px 0;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.theme.primary.main};
  padding-bottom: 8px;
  border-bottom: 1px solid ${(props) => props.theme.primary.main};
`;

const Button = styled.div`
  color: ${(props) => props.theme.primary.main};
  font-weight: bold;
  border: 2px solid ${(props) => props.theme.primary.main};
  padding: 14px 0;
  border-radius: 4px;
  text-align: center;
  margin-top: 30px;
`;

const Language = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 5px 0;
  height: 40px;
  align-items: center;
`;

export default LanguageSelectorModal;
