import { useFormik } from "formik";
import { closeOutline } from "ionicons/icons";
import { ReactElement, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../../atoms/Button";
import Card from "../../atoms/Card";
import CardTitle from "../../atoms/CardTitle";
import Icon from "../../atoms/Icon";
import { TextField } from "../../atoms/LoginTextField";
import SelectField from "../../atoms/SelectField";
import * as Yup from "yup";
import {
  InvitationChannelEnum,
  sendInvitationFormType,
} from "../../../types/invitation.types";
import { IonModal } from "@ionic/react";
import { getStudyLangs } from "../../../services/study.service";
import { useConfig } from "../../../contexts/ConfigContext";

interface InvitationFormModalProps {
  onSaveClick: (values: sendInvitationFormType) => void;
  channel: InvitationChannelEnum;
  isOpen: boolean;
  onDismiss: () => void;
}

const InvitationFormModal = ({
  channel,
  onSaveClick,
  isOpen,
  onDismiss,
}: InvitationFormModalProps): ReactElement => {
  const handleSubmit = async (values: sendInvitationFormType) => {
    await onSaveClick(values);
    onDismiss();
  };

  const formSchema = useMemo(
    () =>
      Yup.object().shape({
        firstname: Yup.string().required(),
        lastname: Yup.string().required(),
        email: Yup.string().when([], {
          is: () => channel === InvitationChannelEnum.EMAIL,
          then: Yup.string().email().required(),
        }),
        phone_number: Yup.string().when([], {
          is: () => channel === InvitationChannelEnum.SMS,
          then: Yup.string().required(),
        }),
        lang: Yup.string().required(),
      }),
    [channel]
  );

  const { values, touched, errors, submitForm, setFieldValue } =
    useFormik<sendInvitationFormType>({
      initialValues: {
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        lang: "",
      },
      onSubmit: handleSubmit,
      validationSchema: formSchema,
    });

  const { t } = useTranslation();

  const { study } = useConfig();

  const [langs, setLangs] = useState<string[]>([]);

  useEffect(function () {
    loadLangs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadLangs() {
    const { data } = await getStudyLangs(study.id);
    setLangs([data.primary_lang, ...data.additional_langs]);
  }

  return (
    <IonModal
      isOpen={isOpen}
      cssClass="resonance-modal"
      backdropDismiss={true}
      onWillDismiss={onDismiss}
    >
      <ModalCard>
        <CardTitle color="primary">{t(`invite_modal_${channel}`)}</CardTitle>

        <CancelButton icon={closeOutline} onClick={onDismiss} />

        <TextField
          type="text"
          onChange={(text) => setFieldValue("firstname", text)}
          value={values.firstname}
          error={touched.firstname && errors.firstname}
          placeholder={t("common_name")}
          label={t("common_name")}
        />
        <TextField
          type="text"
          onChange={(text) => setFieldValue("lastname", text)}
          value={values.lastname}
          error={touched.lastname && errors.lastname}
          placeholder={t("common_surname")}
          label={t("common_surname")}
        />

        {channel === InvitationChannelEnum.EMAIL && (
          <TextField
            type="text"
            onChange={(text) => setFieldValue("email", text)}
            value={values.email}
            error={touched.email && errors.email}
            placeholder={t("common_mail")}
            label={t("common_mail")}
          />
        )}

        {channel === InvitationChannelEnum.SMS && (
          <TextField
            type="text"
            onChange={(text) => setFieldValue("phone_number", text)}
            value={values.phone_number}
            error={touched.phone_number && errors.phone_number}
            placeholder={t("common_phone")}
            label={t("common_phone")}
          />
        )}

        <SelectField
          onChange={(text) => setFieldValue("lang", text)}
          value={values.lang}
          error={touched.lang && errors.lang}
          label={t("common_lang")}
          placeholder={t("common_lang")}
          options={langs.map((lang) => ({
            text: t(`lang_${lang}`),
            id: lang,
          }))}

        />
        <Buttons>
          <PrimaryButton onClick={submitForm}>{t("common_send")}</PrimaryButton>
        </Buttons>
      </ModalCard>
    </IonModal>
  );
};

const ModalCard = styled(Card)`
  margin: auto 0;
  position: relative;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 30px 0;
  box-sizing: border-box;
`;
const PrimaryButton = styled(Button)`
  font-size: 14px;
  padding: 0 20px;
  line-height: 36px;
  margin: 0 10px;
  background: ${(props) => props.theme.secondary.main};
`;
const CancelButton = styled(Icon)`
  position: absolute;
  top: 5px;
  right: 5px;
  color: #b3b3b3;
  font-size: 20px;
  &:active {
    opacity: 0.5;
  }
`;

export default InvitationFormModal;
