import { FC, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import Button from "../atoms/Button";
import { useResonanceLayout } from "../organisms/ResonnanceLayout/ResoncanceContext";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";

interface Props {
  title: string;
  buttonText?: string;
  type?: "verified" | "finished";
  onClick?(): void;
}

const Message: FC<Props> = ({ title,type, buttonText, onClick }) => {
  const { setResonanceLayout } = useResonanceLayout();
  const theme = useTheme();

  useEffect(() => {
    setResonanceLayout({
      disableHeader: true,
      disableNav: true,
      backgroundColor: theme["primary"].background,
    });

    return () =>
      setResonanceLayout({
        disableHeader: false,
        disableNav: false,
        backgroundColor: "white",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Icon src={type} alt="registered successfully" color="white" />
      <Title>{title}</Title>

      {onClick && (
        <StyledButton onClick={onClick} icon={chevronForwardOutline} isIconBefore={false}>
          {buttonText}
        </StyledButton>
      )}
    </Container>
  );
};

const Container = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Title = styled.div`
  font-size: 24px;
  padding: 0 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  color:${(props) => props.theme.primary.contrast};
`;

const Icon = styled.img`
  width: 100px;
  margin: 0;
  content: url(${(props) => `/assets/images/svg/${props.theme.svg[props.src]}.svg`});
`;

const StyledButton = styled(Button)`
  padding: 0 50px;
  margin: 20px 0;
`;

export default Message;
