import styled from "styled-components";
import { black } from "../../theme/theme";

interface Props {
  color?: keyof typeof colors;
}

enum colors {
  primary = "primary",
  secondary = "secondary",
}

const CardTitle = styled.h5<Props>`
  color: ${black} || "inherit"};
  text-align: center;
  font-weight: bold;
  margin: 10px 0px;
`;

export default CardTitle;
