import { IonList, IonItem, IonLabel } from "@ionic/react";
import { ReactElement, useEffect } from "react";
import {
  InvitationChannelDisplayType,
  InvitationDisplayType,
} from "../../../types/invitation.types";
import { useTranslation } from "react-i18next";
import Invitation from "./Invitation";
import Card from "../../atoms/Card";
import styled from "styled-components";
import Icon from "../../atoms/Icon";
import { chevronForwardOutline } from "ionicons/icons";
import { getDirectInclusion } from "../../../services/invitation.service";
import { useConfig } from "../../../contexts/ConfigContext";
import useToast from "../../../hooks/useToast";
import { useAuth } from "../../../contexts/AuthContext";
import { AttributeEnum } from "../../../types/profile";
import { useResonanceLayout } from "../../../components/organisms/ResonnanceLayout/ResoncanceContext";
import CardTitle from "../../atoms/CardTitle";
import CardSubtitle from "../../atoms/CardSubtitle";

interface DisplayInvitationsProps {
  invitations: InvitationDisplayType[];
  onChannelClick(
    invit: InvitationDisplayType,
    channel: InvitationChannelDisplayType
  ): void;
}

const DisplayInvitations = ({
  invitations,
  onChannelClick,
}: DisplayInvitationsProps): ReactElement => {
  const { t } = useTranslation();

  const { study, selectedRole } = useConfig();
  const present = useToast();
  useAuth();

  const handleInclusionDirect = async () => {
    try {
      const { data: invitation_token } = await getDirectInclusion({
        studyId: study.id,
      });
      window.location.replace(`/${study.slug}?invitation=${invitation_token}`);
    } catch (e) {
      present("direct inclusion error");
    }
  };

  const { setResonanceLayout } = useResonanceLayout();

  useEffect(() => {
    setResonanceLayout({
      disableHeader: false,
      disableNav: false,
      backgroundColor: "white",
      title: "invite_title",
    });

    return () =>
      setResonanceLayout({
        disableHeader: false,
        disableNav: false,
        backgroundColor: "white",
        title: "invite_title",
      });
  }, []);

  return (
    <div>
      {invitations &&
        invitations.map((inv) => (
          <Invitation
            invitation={inv}
            key={inv.to_role_id}
            onChannelClick={onChannelClick}
          />
        ))}
      {!invitations ||
        (invitations.length === 0 && (
          <IonList>
            <IonItem>
              <IonLabel class="ion-text-wrap">
                {t("invite_empty_config")}
              </IonLabel>
            </IonItem>
          </IonList>
        ))}
      {selectedRole.attributes[AttributeEnum.DIRECT_INCLUSION] && (
        <InclusionDirect onClick={handleInclusionDirect}>
          <InclusionLabel>{t("invite_inclusion_direct")}</InclusionLabel>
          <InclusinIcon icon={chevronForwardOutline} />
        </InclusionDirect>
      )}
    </div>
  );
};

const InclusionDirect = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  cursor: pointer;
`;

const InclusionLabel = styled.div``;

const InclusinIcon = styled(Icon)`
  margin-left: auto;
  font-size: 18px;
  color: #9b9b9b;
`;

export default DisplayInvitations;
