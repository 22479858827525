import { createContext, useContext, useEffect, useState } from "react";
import { getStudySlug } from "../helper/study";
import { getWelcomeInfo } from "../services/weclome.service";
import { getConfig } from "../services/config.service";
import { ProfileType } from "../types/profile";
import { getStudyIsGamified } from "../services/study.service";

interface configValueType {
  study: {
    slug: string;
    id: number;
    is_gamified: boolean;
  };
  app: {
    name: string;
    logo: string;
    theme: string;
  };
  selectedRole: ProfileType;
  setRole: (role: ProfileType) => void;
  canChooseSite: { [key: string]: boolean };
}

const configValue: configValueType = {
  study: {
    slug: undefined,
    id: undefined,
    is_gamified: undefined,
  },
  app: undefined,
  selectedRole: undefined,
  canChooseSite: {},
  setRole: () => {},
};

const configContext = createContext<configValueType>(configValue);

enum CONFIG_PARAMS {
  APP_NAME = "APP_NAME",
  COLOR_THEME = "COLOR_THEME",
  LOGO = "LOGO",
  CAN_INTERVENORS_CHOOSE_SITES = "CAN_INTERVENORS_CHOOSE_SITES",
  CAN_INVESTIGATORS_CHOOSE_SITES = "CAN_INTERVENORS_CHOOSE_SITES",
  CAN_SUBJECTS_CHOOSE_SITES = "CAN_INTERVENORS_CHOOSE_SITES",
}

const defaultConfig = {
  [CONFIG_PARAMS.APP_NAME]: "Mentalo",
  [CONFIG_PARAMS.LOGO]: "/assets/images/mentalo-logo.png",
  [CONFIG_PARAMS.COLOR_THEME]: "GREEN",
};

const getConfigItem = (config, code: string) => {
  return config.find((item) => item.code === code)?.value;
};

const ConfigProvider = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [config, setConfig] = useState<configValueType>(configValue);
  const slug = getStudySlug();

  useEffect(
    function () {
      async function loadConfig() {
        try {
          const {
            data: { id },
          } = await getWelcomeInfo({ slug, lang: "en" });

          const { data: config } = await getConfig(id);
          // const is_gamified = true;
          const { data : is_gamified } = await getStudyIsGamified(id);

          const appName =
            getConfigItem(config, CONFIG_PARAMS.APP_NAME) ||
            defaultConfig.APP_NAME;
          const logo =
            getConfigItem(config, CONFIG_PARAMS.LOGO) || defaultConfig.LOGO;

          const theme =
            getConfigItem(config, CONFIG_PARAMS.COLOR_THEME) ||
            defaultConfig.COLOR_THEME

          const canInvestigatorChooseSite = getConfigItem(
            config,
            CONFIG_PARAMS.CAN_INTERVENORS_CHOOSE_SITES
          );
          const canIntervenorChooseSite = getConfigItem(
            config,
            CONFIG_PARAMS.CAN_INTERVENORS_CHOOSE_SITES
          );
          const canSubjectChooseSite = getConfigItem(
            config,
            CONFIG_PARAMS.CAN_INTERVENORS_CHOOSE_SITES
          );

          const canChooseSite = {
            intervenor: canIntervenorChooseSite,
            investigator: canInvestigatorChooseSite,
            subject: canSubjectChooseSite,
          };

          // const theme = getConfigItem(config, CONFIG_PARAMS.COLOR_THEME) || defaultConfig.COLOR_THEME;
          setConfig({
            ...config,
            study: { slug, id, is_gamified },
            app: {
              name: appName,
              logo: logo,
              theme: theme,
            },
            canChooseSite,
          });
        } catch (e) {
          console.log(e);
        } finally {
          setIsInitialized(true);
        }
      }

      loadConfig();
    },
    [slug]
  );

  const setRole = function (role) {
    setConfig({
      ...config,
      selectedRole: role,
    });
  };

  if (!isInitialized) return <div />;

  return (
    <configContext.Provider value={{ ...config, setRole }}>
      {children}
    </configContext.Provider>
  );
};

const useConfig = () => useContext(configContext);

export { ConfigProvider, useConfig };
