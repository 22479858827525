export const common = {
  common_cancel: 'إلغاء',
  common_close: 'إغلاق',
  common_questionnaire: 'استبيان',
  common_clear: 'مسح',
  common_save: 'حفظ',
  common_mail: 'البريد الإلكتروني',
  common_sms: 'رسالة نصية',
  common_copy: 'نسخ',
  common_name: 'الاسم الأول',
  common_surname: 'اسم العائلة',
  common_lang: 'اللغة',
  common_phone: 'الهاتف',
  common_next: 'التالي',
  common_previous: 'السابق',
  common_details: 'التفاصيل',
  common_send: 'إرسال',
  common_start: 'ابدأ',
  common_password: 'كلمة المرور',
  common_click_to_speak: 'اضغط للتحدث',
  common_server_error_title: 'حدث خطأ في الخادم',
  common_server_error_desc: 'يرجى المحاولة مرة أخرى أو الاتصال بنا',
  common_go_back: 'العودة',
  common_nodata_title: 'لا توجد بيانات متاحة',
  common_nodata_desc: 'يرجى المحاولة مرة أخرى أو الاتصال بنا',
  common_bad_request: 'طلب غير صالح',
  common_no_slug: 'يرجى النقر على رابط الدعوة في بريدك الإلكتروني أو رسائل SMS',
  confirm_quit_study_button: 'مغادرة نهائيًا',
  come_back_later_button: 'سأعود لاحقًا',
  doing_questionnaire_button: 'أقوم بملء استبيان',
  help_link_part1: 'إذا كنت لا تشعر بتحسن، يمكنك ',
  help_link_part2: 'الحصول على المساعدة بالنقر هنا',
  thank_you: 'شكرًا لك!',
  you_earned_part1: 'لقد كسبت ',
  you_earned_part2: ' نقاط!',
  other_questionnaire: 'يمكنك مساعدتنا أكثر عن طريق ملء استبيان آخر.'
};
