import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resonanceTradFr } from './locales/fr';
import { resonanceTradEn } from './locales/en';
import { resonanceTradAr } from './locales/ar'; // Arabic translations
import { resonanceTradEs } from './locales/es'; // Spanish translations

const resources = {
  en: {
    translation: {
      'Welcome to React': 'Welcome to React and react-i18next',
      ...resonanceTradEn
    }
  },
  fr: {
    translation: {
      'Welcome to React': 'Bienvenue à React et react-i18next',
      ...resonanceTradFr
    }
  },
  ar: {
    translation: {
      'Welcome to React': 'مرحبا بكم في React و react-i18next',
      ...resonanceTradAr
    }
  },
  es: {
    translation: {
      'Welcome to React': 'Bienvenido a React y react-i18next',
      ...resonanceTradEs
    }
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fr',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });
