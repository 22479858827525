import { ReactElement } from 'react';
import { SecretQuestionType } from '../../../../types/profile';
import { FormGroup } from '../../../atoms/LoginTextField';
import SecretQuestionItem from './SecretQuestionItem';

interface DisplaySecretQuestionsProps {
  secretQuestions: SecretQuestionType[];
  answers: Record<number, string>;
  setFieldValue?(key: number, value: any): void;
  errors: any;
  touched: any;
};

const DisplaySecretQuestions = ({ secretQuestions, touched, answers, errors, setFieldValue }: DisplaySecretQuestionsProps): ReactElement => {

  return (
    <FormGroup>
      { 
        secretQuestions.map((question: SecretQuestionType) => (
          <SecretQuestionItem
            key={question.id}
            secretQuestion={question}
            onChange={setFieldValue}
            answer={answers[question.id] || ''}
            error={touched[question.id] && errors[question.id]}
        />))
    }
    </FormGroup>
  );
};

export default DisplaySecretQuestions;
