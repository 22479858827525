import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  primary: {
    main: "#0D8C9B",
    contrast: "#fff",
  },
  secondary: {
    main: "#E76538",
    contrast: "#fff",
  },
  danger: {
    main: "#e64545",
    contrast: "#fff",
  },
  icon: {
    size: {
      small: "16px",
      regular: "20px",
      large: "24px",
    },
  },
  svg: {
    verified: "verified-green",
    finished: "finished-green",
  },
};

export const black = '#041723'