export const formError = {
  "form_error_required": "هذا الحقل مطلوب",
  "form_default_error": "إدخال غير صالح",
  "form_error_email": "هذا البريد الإلكتروني غير صالح",
  "form_error_email_password": "يجب أن تحتوي كلمة المرور على أحرف كبيرة وصغيرة ورموز",
  "form_error_min": "يجب أن يكون الحد الأدنى للطول {{min}} حرفًا",
  "form_error_email_confirm_password": "كلمة المرور غير متطابقة",
  "form_error_phone": "رقم الهاتف غير صالح",
  "form_error_email_or_phone_required": "البريد الإلكتروني أو رقم الهاتف مطلوب"
}
