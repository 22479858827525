import { ReactElement } from 'react';
import { SecretQuestionType } from '../../../../types/profile';
import {TextField} from '../../../atoms/LoginTextField';

interface SecretQuestionItemProps {
  secretQuestion: SecretQuestionType;
  answer: string;
  onChange: (key: number, value: string) => void;
  error: string;
}

const inputType = {
  TEXT: 'text',
  NUMBER: 'number',
  DATE: 'date'
}

const SecretQuestionItem = ({ secretQuestion, answer, error, onChange }: SecretQuestionItemProps): ReactElement => {

  const { question, type, id = 0 } = secretQuestion;

  return <TextField
    placeholder={question}
    label={question}
    value={answer}
    onChange={(text: string): void => onChange(id, text)}
    type={inputType[type]}
    error={error}
  />

};

export default SecretQuestionItem;
