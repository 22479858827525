export const consent = {
  consent_subtitle_1_1: "وثيقة المعلومات للمشاركين من 11 إلى 17 عامًا",
  consent_subtitle_1_2: "وثيقة المعلومات للمشاركين من 18 إلى 24 عامًا",
  consent_subtitle_1_3: "وثيقة المعلومات لأولياء أمور المشاركين من 11 إلى 17 عامًا",
  consent_subtitle_2: "الرفاه العقلي للمراهقين والشباب: الحقائق والتطورات (Mentalo)",
  consent_paragraph_1: "المسؤولة العلمية عن البحث هي البروفيسورة كارين شيفرول، مديرة وحدة ECEVE في المعهد الوطني للصحة والبحوث الطبية (Inserm) وجامعة باريس سيت. وهي أيضًا المسؤولة عن تنفيذ معالجة البيانات.",
  consent_paragraph_2: "مسؤول معالجة البيانات هو وحدة ECEVE - UMR 1123 المعلومات عن البحث للمشاركين من 11 إلى 17 عامًا",
  consent_paragraph_3_1: "مرحبًا،",
  consent_paragraph_3_2: "أنت مدعو للمشاركة في الدراسة بعنوان 'الرفاه العقلي للمراهقين والشباب: الحقائق والتطورات' (Mentalo)، التي تركز على الرفاه العقلي للأعمار من 11 إلى 24 عامًا والتي تقودها البروفيسورة كارين شيفرول.",
  consent_paragraph_4: "تهدف هذه الوثيقة إلى إبلاغك عن الدراسة وإجراءاتها لشرح ما تتضمنه مشاركتك. أنت حر في المشاركة أو عدمها. إذا لم ترغب في المشاركة، فلا يتعين عليك تبرير نفسك. قبل اتخاذ قرارك، يمكنك التحدث إلى شخص مقرب أو أي شخص تشعر بالراحة معه.",
  consent_paragraph_5: "إذا وافقت على المشاركة في هذه الدراسة، تتوفر أيضًا مذكرة معلومات لوالديك حتى يتمكنوا من معرفة هذه الدراسة وضمان عدم اعتراضهم على معالجة بياناتك.",
  consent_paragraph_6: "لا تتردد في طرح الأسئلة في أي وقت عن طريق البريد الإلكتروني بالاتصال بالباحثين المسؤولين عن الدراسة على mentalo@inserm.fr.",
  consent_paragraph_7: "يمكنك تغيير رأيك في أي وقت وطلب التوقف عن المشاركة في هذه الدراسة. نطلب منك فقط إبلاغ الباحثين عن طريق البريد الإلكتروني على mentalo@inserm.fr.",

  consent_subtitle_3: "1. السياق، الأهداف والتبرير",

  consent_paragraph_8: "كان لجائحة كوفيد-19 والإجراءات الصحية التي تبعتها مثل الحجر الصحي تأثير كبير على الرفاه العقلي للسكان كافة وخاصة الشباب. تظهر الدراسات الأخيرة التي تناولت الموضوع تدهورًا في حالة الرفاه العقلي للمراهقين والشباب.",
  consent_paragraph_9: "تم إنشاء دراسة Mentalo لفهم الوضع بشكل أفضل. هدفها هو دراسة الرفاه العقلي للشباب، تطوره على مدار عام، والعوامل التي قد تؤثر عليه.",
  consent_paragraph_10: "تستهدف الشباب الذين تتراوح أعمارهم بين 11 و24 عامًا ويعيشون في فرنسا، وتخطط لاستجواب 50,000 شاب.",

  consent_subtitle_4: "2. إجراءات الدراسة",

  consent_paragraph_11: "تتم الدراسة عبر الإنترنت، من خلال استبيان. سيتم استجوابك في أوقات مختلفة طوال سنة الدراسة، عند التسجيل ثم بعد 15 يومًا، ثم بعد 1، 2، 3، 6، 9، و12 شهرًا.",
  consent_paragraph_12: "لن يستغرق الأمر أكثر من 10 دقائق في كل مرة.",
  consent_paragraph_13: "بعد الموافقة على المشاركة، سيُطلب منك إنشاء حساب على الموقع الذي يوجد به الاستبيان، باستخدام تسجيل دخول (اسم مستعار أو بريد إلكتروني) وكلمة مرور. سيُطلب منك أيضًا تقديم رقم هاتف أو بريد إلكتروني لإبلاغك عندما تكون الاستبيانات الجديدة متاحة. سيتم إرسال هذه الرسائل النصية أو رسائل البريد الإلكتروني تلقائيًا ولن يكون لدى الباحثين إمكانية الوصول إلى هذه المعلومات.",
  consent_paragraph_14: "بمجرد إنشاء حسابك، يمكنك الإجابة على استبيان الدراسة. يتكون من وحدات إلزامية ووحدات اختيارية. عند التسجيل، تتناول الوحدات الإلزامية رفاهك العقلي (كيف حالك، ما الذي يشغلك...) وبعض الأسئلة حول خصائصك (عمرك، ما إذا كنت طالبًا في المدرسة المتوسطة أو الثانوية...).",
  consent_paragraph_15: "أثناء المتابعة، تتناول وحدة المتابعة الإلزامية رفاهك العقلي، لمعرفة كيف حالك مقارنة بالمرة السابقة، وما إذا كانت هناك تغييرات، ولماذا.",
  consent_paragraph_16: "منذ تسجيلك، سيكون لديك أيضًا إمكانية الوصول إلى وحدات اختيارية، يمكنك اختيار ملئها أو لا. تتناول حياتك العائلية، الاجتماعية، العاطفية، الدراسية أو المهنية. يمكنك الإجابة على هذه الوحدات في أي وقت خلال سنة الدراسة.",
  consent_paragraph_17: "كشكر لمشاركتك، ستكسب نقاطًا في كل مرة تكمل فيها وحدة، سواء كانت إلزامية أو لا. ستتيح لك نقاطك الوصول إلى الهدايا بمجرد وصولك إلى حد معين. يمكنك اختيار هديتك من بين تلك المتاحة والتي تتوافق مع عدد نقاطك. كلما تقدمت في الدراسة، زادت قيمة الهدايا.",
  consent_paragraph_18: "يمكنك أيضًا دعوة شباب آخرين (أصدقاء، عائلة...) للمشاركة في الدراسة من خلال رابط سيتم إرساله إليهم.",
  consent_paragraph_19: "جميع المعلومات حول الدراسة موجودة على الموقع https://etude-mentalo.fr/.",
  consent_paragraph_20: "ستساعدنا مشاركتك في فهم الرفاه العقلي للشباب في فرنسا بشكل أفضل.",
  consent_paragraph_21: "مدة مشاركتك: سنة واحدة",
  consent_paragraph_22: "المدة الإجمالية للبحث: 3 سنوات.",

  consent_subtitle_5: "3. السرية ومعالجة البيانات",
  consent_paragraph_23: "كجزء من مشاركتك في هذا البحث، سيتم تنفيذ معالجة لبياناتك الشخصية لتحليل النتائج. هذه المعالجة تهدف إلى البحث العلمي وتستند إلى تنفيذ مهمة ذات مصلحة عامة.",
  consent_paragraph_24: "البيانات التي ستقدمها في الاستبيانات ستكون سرية ولن يتمكن من الوصول إليها سوى الباحثين الذين يعملون في الدراسة. لن يكون من الممكن للباحثين تحديد هويتك. لن يتم أبدًا مشاركة بيانات تسجيل الدخول الخاصة بك، رقم هاتفك أو بريدك الإلكتروني مع الباحثين.",
  consent_paragraph_25: "سيتم الاحتفاظ ببياناتك بشكل آمن حتى نهاية التحليل، لمدة أقصاها 3 سنوات. بعد ذلك، سيتم إخفاء هويتك. لن يتم أرشفة بياناتك الشخصية بعد هذه الفترة.",

  consent_paragraph_26: "وفقًا للقانون، لديك الحق:",
  consent_paragraph_27: "في الوصول إلى البيانات التي قدمتها أثناء البحث وطلب حذفها أو تعديلها.",
  consent_paragraph_28: "في الاعتراض في أي وقت على استمرار جمع بياناتك.",
  consent_paragraph_29: "في منع استخدام بياناتك مؤقتًا.",
  consent_paragraph_30: "لا يمكن ممارسة هذه الحقوق إذا كانت من المحتمل أن تجعل من المستحيل أو تعرقل بشدة تحقيق أهداف البحث.",
  consent_paragraph_31: "إذا كنت ترغب في ممارسة هذه الحقوق، يمكنك الاتصال بفريق البحث على mentalo@inserm.fr. سيتم الرد عليك في أقرب وقت ممكن، وفي موعد لا يتجاوز شهرًا من تلقي طلبك. في حالة الصعوبات، يمكنك أيضًا، من خلال والديك، الاتصال بمسؤولة حماية البيانات (DPD) بجامعة باريس سيت.",
  consent_paragraph_32: "إذا كنت تعتقد، بعد الاتصال بنا، أن حقوقك في 'المعلومات والحريات' لا يتم احترامها، يمكن لوالديك تقديم شكوى إلى CNIL (www.cnil.fr).",
  consent_paragraph_33: "إذا قررت التوقف عن المشاركة في البحث، سيتم استخدام البيانات التي تم جمعها حتى تلك اللحظة وفقًا للقانون. في هذه الحالة، لن يتم استخدام بياناتك لاحقًا أو في بحث آخر.",
  consent_paragraph_34: "لديك أيضًا الحق في الخصوصية وسرية بياناتك. إذا كان عمرك خمسة عشر عامًا أو أكثر، لديك الحق في رفض وصول والديك/أولياء أمرك إلى البيانات المتعلقة بك التي تم جمعها خلال الدراسة. إذا كنت ترغب في ممارسة هذا الحق، يمكنك الاتصال بفريق البحث (mentalo@inserm.fr).",

  consent_subtitle_6: "4. معلومات عن النتائج العامة",

  consent_paragraph_35: "ستتمكن من الوصول إلى النتائج العامة للدراسة على موقع الدراسة https://etude-mentalo.fr/.",
  consent_paragraph_36: "يمكن أيضًا تقديم نتائج البحث في المؤتمرات، في المنشورات العلمية، أو في وسائل الإعلام العامة.",

  consent_subtitle_7: "5. إعادة استخدام البيانات في إطار بحوث جديدة",

  consent_paragraph_37: "يمكن إعادة استخدام البيانات التي قدمتها في الاستبيانات في دراسات أخرى حول الرفاه العقلي للمراهقين، ولكنها لن تسمح أبدًا بتحديد هويتك.",
  consent_paragraph_38: "لديك الحق في الحصول على معلومات إضافية حول هذه الدراسات من المسؤول عن الدراسة.",
  consent_paragraph_39: "في جميع الأحوال، لديك أنت وأولياء أمرك الحق في رفض إعادة استخدام بياناتك في دراسات أخرى.",

  consent_subtitle_8: "6. الأحكام التشريعية والتنظيمية",

  consent_paragraph_40: "حصل هذا البحث على رأي إيجابي من لجنة تقييم الأخلاقيات في Inserm (CEEI/IRB) في 6 يونيو 2023 (الرأي رقم 23-1018).",
  consent_paragraph_41: "باختصار...",
  consent_paragraph_42: "تركز الدراسة على الرفاه العقلي للشباب الذين تتراوح أعمارهم بين 11 و24 عامًا ويعيشون في فرنسا.",
  consent_paragraph_43: "تتطلب ملء الاستبيانات عبر الإنترنت عدة مرات في السنة.",
  consent_paragraph_44: "سيكون وقت الاستجابة أقل من 10 دقائق في كل مرة.",
  consent_paragraph_45: "مشاركتك في الدراسة طوعية ومجانية.",
  consent_paragraph_46: "يتم التعامل مع بياناتك بسرية تامة.",
  consent_paragraph_47: "أنت حر في اتخاذ القرار بشأن المشاركة في البحث أم لا.",
  consent_paragraph_48: "نظرًا لأنك تحت سن 18، ستحتاج إلى مشاركة معلومات الدراسة وتنظيمها الموجودة في الرسالة المعلوماتية الموجهة لوالديك/أولياء أمرك معهم.",
  consent_paragraph_49: "لديك أنت ووالديك/أولياء أمرك الحق في الاعتراض على معالجة بياناتك الشخصية.",
  consent_paragraph_50: "بعد التحدث معهم، نطلب منك تأكيد مشاركتك قبل بدء الاستبيان.",
  consent_paragraph_51: "بمتابعة التسجيل، أؤكد أن عمري بين 11 و17 عامًا.",
  consent_paragraph_52: "بمتابعة التسجيل، أؤكد أنني قد أبلغت والديّ/أوليائي القانونيين بالرسالة المعلوماتية الموجهة إليهم وأنه لا أنا ولا هم نعارض معالجة بياناتي الشخصية.",
  consent_paragraph_53: "بمتابعة التسجيل، أؤكد أنني ولا والديّ/أوليائي القانونيين نعارض إعادة استخدام بياناتي في أبحاث مستقبلية مع احترام سرية هويتي.",

  // النسخة الثانية من النص

  consent_paragraph_54: "وفقًا لأحكام القانون رقم 78-17 بشأن المعلوماتية والملفات والحريات واللائحة العامة لحماية البيانات (اللائحة (الاتحاد الأوروبي) 2016/679)، لديك الحقوق التالية:",
  consent_paragraph_55: "الحق في الوصول إلى المعلومات المتعلقة بك، للتحقق من دقتها وإذا لزم الأمر، لتصحيحها، استكمالها، أو تحديثها.",
  consent_paragraph_56: "الحق في الاعتراض: الحق في الاعتراض في أي وقت على نقل بياناتك والحصول على عدم جمع بياناتك في المستقبل. ممارسة هذا الحق يؤدي إلى توقف مشاركتك في البحث.",
  consent_paragraph_57: "الحق في تقييد معالجة البيانات: الحق في حظر استخدام بياناتك مؤقتًا: لا يمكن إجراء أي عمليات عليها.",
  consent_paragraph_58: "سيكون لديك إمكانية ممارسة حقوقك لمدة 3 سنوات بعد انتهاء البحث. بالفعل، سيتم حذف بيانات التعريف في نهاية هذه الفترة.",

  consent_paragraph_59: "إذا كنت ترغب في ممارسة هذه الحقوق، يجب عليك الاتصال بفريق البحث على mentalo@inserm.fr أو مسؤول حماية البيانات (DPD) بجامعة باريس سيت.",
  consent_paragraph_60: "بالإضافة إلى ذلك، لديك الحق في تقديم شكوى إلى اللجنة الوطنية للمعلوماتية والحريات – CNIL (السلطة الفرنسية لحماية البيانات الشخصية) عبر الرابط التالي https://www.cnil.fr/fr/cnil-direct/question/adresser-une-reclamation-plainte-la-cnil-quelles-conditions-et-comment أو CNIL - إدارة الشكاوى - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.",
  consent_paragraph_61: "إذا قررت التوقف عن المشاركة في البحث، سيتم استخدام البيانات التي تم جمعها قبل هذا التوقف وفقًا للوائح، وبشكل حصري لتحقيق أهداف هذا البحث. بالفعل، قد يؤدي حذفها إلى تعريض صحة نتائج البحث للخطر. في هذه الحالة، لن يتم استخدام بياناتك لاحقًا أو في بحث آخر.",

  consent_paragraph_62: "يمكن إعادة استخدام البيانات التي قدمتها في الاستبيانات في أبحاث أخرى تتعلق بالرفاه العقلي للمراهقين والشباب، لكنها لن تسمح أبدًا بتحديد هويتك.",
  consent_paragraph_63: "لديك الحق في الحصول على معلومات إضافية، مثل الغرض من هذه الأبحاث الجديدة، من فريق البحث.",
  consent_paragraph_64: "في جميع الحالات، لديك الحق في الاعتراض على أي إعادة استخدام لبياناتك في الأبحاث المستقبلية.",
  consent_paragraph_65: "أنت حر في اتخاذ القرار بشأن المشاركة في البحث أم لا. نطلب منك تأكيد مشاركتك قبل بدء الاستبيان.",
  consent_paragraph_66: "بمتابعة التسجيل، أؤكد أن عمري بين 18 و24 عامًا.",
  consent_paragraph_67: "بمتابعة التسجيل، أؤكد أنني لا أعارض معالجة بياناتي الشخصية.",
  consent_paragraph_68: "بمتابعة التسجيل، أؤكد أنني لا أعارض إعادة استخدام بياناتي في أبحاث مستقبلية مع احترام سرية هويتي.",

  // النسخة الثالثة من النص

  consent_paragraph_69: "حقوق طفلك:",
  consent_paragraph_70: "سيكون لديك إمكانية ممارسة حقوق طفلك لمدة 3 سنوات بعد انتهاء البحث. بالفعل، سيتم حذف بيانات التعريف الخاصة بطفلك في نهاية هذه الفترة.",
  consent_paragraph_71: "إذا كنت ترغب في ممارسة هذه الحقوق والحصول على التواصل مع معلوماتك، يجب عليك الاتصال بفريق البحث على mentalo@inserm.fr أو مسؤول حماية البيانات (DPD) بجامعة باريس سيت.",
  consent_paragraph_72: "بالإضافة إلى ذلك، لديك الحق في تقديم شكوى إلى اللجنة الوطنية للمعلوماتية والحريات – CNIL (السلطة الفرنسية لحماية البيانات الشخصية) عبر الرابط التالي https://www.cnil.fr/fr/cnil-direct/question/adresser-une-reclamation-plainte-la-cnil-quelles-conditions-et-comment أو CNIL - إدارة الشكاوى - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.",
  consent_paragraph_73: "إذا قرر طفلك التوقف عن المشاركة في البحث، سيتم استخدام البيانات التي تم جمعها قبل هذا التوقف وفقًا للوائح، وبشكل حصري لتحقيق أهداف هذا البحث. بالفعل، قد يؤدي حذفها إلى تعريض صحة نتائج البحث للخطر. في هذه الحالة، لن يتم استخدام بيانات طفلك لاحقًا أو في بحث آخر.",

  consent_paragraph_74: "يمكن إعادة استخدام البيانات الشخصية لطفلك المقدمة في الاستبيانات في أبحاث مستقبلية حول الرفاه العقلي للشباب، لكنها لن تسمح أبدًا بتحديد هوية طفلك. لديك الحق في الحصول على معلومات إضافية، مثل الغرض من هذه الأبحاث الجديدة، من فريق البحث.",
  consent_paragraph_75: "في جميع الحالات، لديك الحق في الاعتراض، في أي وقت، على أي إعادة استخدام لبيانات طفلك في الأبحاث المستقبلية. يمكنك القيام بذلك عن طريق الاتصال بفريق البحث على mentalo@inserm.fr.",

  consent_paragraph_76: "تركز الدراسة على الرفاه العقلي للشباب الذين تتراوح أعمارهم بين 11 و24 عامًا ويعيشون في فرنسا.",
  consent_paragraph_77: "تتطلب ملء الاستبيانات عبر الإنترنت عدة مرات على مدار عام.",
  consent_paragraph_78: "سيكون وقت الاستجابة أقل من 10 دقائق في كل مرة.",
  consent_paragraph_79: "مشاركة طفلك في هذا البحث طوعية ومجانية.",
  consent_paragraph_80: "لديك الحق في الاعتراض على معالجة البيانات الشخصية لطفلك.",
  consent_paragraph_81: "يتم التعامل مع بيانات طفلك بسرية تامة.",
};
