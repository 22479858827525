import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { langToCountryCode } from "../../../../helper/lang";
import Flag from "react-flags";

interface LanguageLabelProps {
  lang: string;
}

const LanguageLabel = ({ lang }: LanguageLabelProps): ReactElement => {
  const { t } = useTranslation();
  const label = t(`lang_${lang}`);

  return (
    <Label>
      <Flag
        name={langToCountryCode[lang] || lang}
        format="svg"
        pngSize={64}
        shiny={false}
        basePath="/assets/images/flags"
      />
      {label}
    </Label>
  );
};

const Label = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
    width: 24px;
  }
`;

export default LanguageLabel;
