export const invite = {
  invite_title: 'Invitations',
  // Ajout Synakene
  invite_subtitle: 'Invite tes amis à participer',
  invite_thank_you_participation: "Merci pour ta participation !",
  invite_friends_to_help: "Tu peux encore nous aider en invitant des amis.",
  invite_later: "Plus tard",
  invite_friends: "J'invite mes amis",
  // invite_subtitle: 'Sélectionner un canal pour envoyer une invitation',
  invite_modal_mail: 'Envoyer une invitation par email',
  invite_modal_EMAIL: 'Envoyer une invitation par email',
  invite_modal_sms: 'Envoyer une invitation par SMS',
  invite_modal_name_placeholder: 'Ex: Jean',
  invite_modal_surname_placeholder: 'Ex: Dupont',
  invite_modal_email_placeholder: 'Ex: Jean.Dupont@email.com',
  invite_modal_lang_placeholder: 'Choisir',
  invite_modal_phone_placeholder: 'Ex: 0123456789',
  invite_copy_notification: 'Copié dans le presse-papier',
  invite_empty_config: 'Aucune option d\'invitation disponible',
  invite_channel_title: 'Comment préférez-vous être invité ?',
  invite_EMAIL: 'Email',
  invite_SMS: "SMS",
  invite_PUBLIC_LINK: 'Copier le lien',
  invite_inclusion_direct: "Inclusion directe"
};
