export const answer = {
  answer_mandatory: 'Mandatory question.',
  answer_noMandatory: 'You can skip this question.',
  answer_common_constraint: 'Select an answer',
  answer_checkbox_constraint_NONE: 'Select one or several answers',
  answer_checkbox_constraint_AT_LEAST: 'Select at least {{quantity}} answers',
  answer_checkbox_constraint_EXACTLY: 'Select exactly {{quantity}} answers',
  answer_checkbox_constraint_AT_MOST: 'Select at most {{quantity}} answers',
  answer_dropdwon_placeholder: 'Select one',
  answer_text_placeholder: 'Enter your answer here',
  answer_text_placeholderEmail: 'Type email',
  answer_number_placeholder: 'Type number',
  answer_number_constraint_min_max: 'The value must be between {{min}} and {{max}}.',
  answer_number_constraint_decimal: 'Maximum {{nbDecimales}} decimales allowed.',
  answer_date_format_YEAR: 'YYYY',
  answer_date_format_MONTH: 'MM/YYYY',
  answer_date_format_DAY: 'MM/DD/YYYY',
  answer_date_placeholder: 'Select date',
  answer_hour_placeholder: 'Select time',
  answer_radio_constraint: 'One possible answer',
  answer_dropdown_constraint: 'Select yout answer',
  answer_text_constraint: 'Enter your answer freely',
  answer_date_constraint: 'Select a date',
  answer_hour_constraint: 'Select an hour between {{min}} and {{max}}',
};
