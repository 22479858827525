import { resonance } from './resonance.es';
import { lang } from './lang.es';
import { menu } from './menu.es';
import { layout } from './layout.es';
import { common } from './common.es';
import { invite } from './invite.es';
import { answer } from './answer.es';
import { home } from './home.es';
import { profile } from './profile.es';
import { changePassword } from './change-password.es';
import finished_questionnaires from './finished_questionnaires.es';
import { formError } from './formError.es';
import { questionnaire } from './questionnaire.es';
import { forgotPassword } from './forgot-password.es';
import { sequence } from './sequences.es';
import { gift } from './gift.es';
import { onboarding } from './onboarding.es';
import { consent } from './consent.es';

export const resonanceTradEs = {
  ...resonance,
  ...answer,
  ...menu,
  ...layout,
  ...invite,
  ...common,
  ...lang,
  ...home,
  ...profile,
  ...changePassword,
  ...forgotPassword,
  ...finished_questionnaires,
  ...formError,
  ...questionnaire,
  ...sequence,
  ...onboarding,
  ...gift,
  ...consent
};
