import styled from "styled-components";
import { useRanger } from "react-ranger";

interface RangeInputProps {
  min: number;
  max: number;
  value: number[];
  tick: number;
  subTick: number;
  step: number;
  options: { asset: string; label: string; value: number }[];
  onChange(value: number[]): void;
  formatPin(value: number): string;
}

function RangeInput({
  step,
  value,
  options,
  onChange,
  min,
  max,
  tick,
  subTick,
  formatPin = (value) => value.toString(),
}: RangeInputProps) {
  const { getTrackProps, ticks, segments, handles } = useRanger({
    min: min,
    max: max,
    stepSize: step,
    tickSize: tick,
    values: value,
    onChange: onChange,
  });

  const { ticks: subTicks } = useRanger({
    min: min,
    max: max,
    stepSize: step,
    tickSize: subTick,
    values: [],
  });

  const { ticks: values } = useRanger({
    min: min,
    max: max,
    stepSize: step,
    steps: options.map((o) => o.value),
    values: [],
  });

  return (
    <Track {...getTrackProps()}>
      {ticks.map(({ value, getTickProps }) => (
        <Tick {...getTickProps()}>
          <TickLabel>{value}</TickLabel>
        </Tick>
      ))}

      {subTicks.map(({ value, getTickProps }) => (
        <SubTick {...getTickProps()} />
      ))}

      {values.map(({ value, getTickProps }, index) => (
        <div {...getTickProps()}>
          <Value>{value}</Value>
          <ValueLabel
            isFirst={index === 0}
            isLast={index === values.length - 1}
          >
            {options.find((o) => o.value === value).label || "default label"}
          </ValueLabel>
        </div>
      ))}

      {segments.map(({ getSegmentProps }, i) => (
        <Segment {...getSegmentProps()} index={i} />
      ))}
      {handles.map(({ value, active, getHandleProps }) => (
        <button
          {...getHandleProps({
            style: {
              appearance: "none",
              border: "none",
              background: "transparent",
              outline: "none",
            },
          })}
        >
          <Handle active={active}>{formatPin(value)}</Handle>
        </button>
      ))}
    </Track>
  );
}

const Track = styled("div")`
  display: flex;
  height: 8px;
  align-items: center;
  width: 70%;
  margin: 0.9rem auto 80px auto;
`;

const Tick = styled("div")`
  :before {
    content: "";
    position: absolute;
    left: 0;
    background: #b0b0b0;
    height: 8px;
    width: 1px;
    border-radius: 7px;
    transform: translate(-50%, -50%);
  }
`;

const SubTick = styled("div")`
  :before {
    content: "";
    position: absolute;
    left: 0;
    background: #b0b0b0;
    height: 4px;
    width: 1px;
    border-radius: 7px;
    transform: translate(-50%, -50%);
  }
`;

const Value = styled("div")`
  position: absolute;
  background: ${(props) => props.theme.secondary.background};
  color: ${(props) => props.theme.primary.contrast};
  width: 20px;
  height: 20px;
  border-radius: 20px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 1rem);
`;

const ValueLabel = styled("div")<{ isFirst: boolean; isLast: boolean }>`
  position: absolute;
  font-size: 12px;
  color: ${(props) => props.theme.primary.main};
  /* right: 50%; */
  transform: ${(props) =>
    props.isFirst
      ? `translate(-10px, 2.6rem)`
      : props.isLast
      ? `translate(calc(-100% + 10px), 2.6rem)`
      : `translate(-50%, 2.6rem)`};
  white-space: nowrap;
`;

const TickLabel = styled("div")`
  position: absolute;
  font-size: 0.6rem;
  color: rgba(0, 0, 0, 0.5);
  top: 100%;
  transform: translate(-50%, 1.2rem);
  white-space: nowrap;
`;

const Segment = styled("div")<{ index: number }>`
  background: ${(props) =>
    props.index === 0 ? props.theme.primary.background : "#b0b0b0"};
  height: ${(props) => (props.index === 0 ? "100%" : "20%")};
  border-radius: 2px;
`;

const Handle = styled("div")<{ active: boolean }>`
  background: ${(props) => props.theme.primary.background};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 100%;
  font-size: 0.7rem;
  white-space: nowrap;
  color: ${(props) => props.theme.primary.contrast};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  transform: ${(props) =>
    props.active ? "translateY(-100%) scale(1.3)" : "translateY(0) scale(0.9)"};
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`;

export default RangeInput;
