export const answer = {
  answer_mandatory: 'سؤال إلزامي.',
  answer_noMandatory: 'يمكنك تخطي هذا السؤال.',
  answer_common_constraint: 'اختر إجابة',
  answer_checkbox_constraint_NONE: 'اختر إجابة واحدة أو عدة إجابات',
  answer_checkbox_constraint_AT_LEAST: 'اختر على الأقل {{quantity}} إجابات',
  answer_checkbox_constraint_EXACTLY: 'اختر بالضبط {{quantity}} إجابات',
  answer_checkbox_constraint_AT_MOST: 'اختر كحد أقصى {{quantity}} إجابات',
  answer_dropdwon_placeholder: 'اختر واحدًا',
  answer_text_placeholder: 'أدخل إجابتك هنا',
  answer_text_placeholderEmail: 'اكتب البريد الإلكتروني',
  answer_number_placeholder: 'اكتب الرقم',
  answer_number_constraint_min_max: 'يجب أن تكون القيمة بين {{min}} و {{max}}.',
  answer_number_constraint_decimal: 'الحد الأقصى {{nbDecimales}} منازل عشرية مسموح بها.',
  answer_date_format_YEAR: 'YYYY',
  answer_date_format_MONTH: 'MM/YYYY',
  answer_date_format_DAY: 'MM/DD/YYYY',
  answer_date_placeholder: 'اختر التاريخ',
  answer_hour_placeholder: 'اختر الوقت',
  answer_radio_constraint: 'إجابة واحدة ممكنة',
  answer_dropdown_constraint: 'اختر إجابتك',
  answer_text_constraint: 'أدخل إجابتك بحرية',
  answer_date_constraint: 'اختر تاريخًا',
  answer_hour_constraint: 'اختر ساعة بين {{min}} و {{max}}',
};
