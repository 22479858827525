import { ReactElement, useEffect, useMemo, useState } from "react";
import useAnswerRules from "../../../hooks/useAnswerRules";
import { AnswerType, AnswerValueType } from "../../../types/Answer";
import { QuestionType } from "../../../types/Question";
import { QuestionnaireSession } from "../../../types/Questionnaire";
import Question from "../../molecules/Question/Question";
import Container from "../../atoms/Container";
import NextPrevButtons from "../../molecules/NextPrevButtons";
import useVocalisation from "../../../hooks/useVocalisation";
import FinishedQuestionnaires from "../../../pages/FinishedQuestionnaires/FinishedQuestionnaire";
import { getNextQuestion } from "../../../helper/questionnaire";
import useToast from "../../../hooks/useToast";
import { useResonanceLayout } from "../ResonnanceLayout/ResoncanceContext";

interface DisplayQuestionsProps {
  questionnaireTitle: string;
  session: QuestionnaireSession;
  onSessionChange: (session: QuestionnaireSession) => void;
  onSubmitAnswer: (question: QuestionType, answer: AnswerType) => Promise<void>;
  onNextClick(): void;
  onPrevClick(): void;
}

const Questionnaire = ({
  questionnaireTitle,  
  session,
  onSessionChange,
  onSubmitAnswer,
  onNextClick,
  onPrevClick,
}: DisplayQuestionsProps): ReactElement => {
  const { questions, answers, history, dependencies } = session;

  const { setResonanceLayout } = useResonanceLayout();

  useEffect(() => {
    setResonanceLayout({
      disableHeader: false,
      disableNav: true,
      backgroundColor: "white",
      title: questionnaireTitle,
    });

    return () =>
      setResonanceLayout({
        disableHeader: false,
        disableNav: true,
        backgroundColor: "white",
        title: questionnaireTitle,
      });
  }, []);

  const currentQuestion: QuestionType = history[history.length - 1];
  const present = useToast();

  const currentAnswer: AnswerType = useMemo(
    () => answers.find((ans) => ans.questionId === currentQuestion.id),
    [answers, currentQuestion.id]
  );

  const [isFinishedQuestionnaire, setIsFinishedQuestionnaire] = useState(false);

  const { translatedRuleMsg, isAnswerValid } = useAnswerRules(
    currentQuestion,
    currentAnswer.value
  );

  const changeAnswer = (answer: AnswerValueType): void => {
    if (currentAnswer.questionType === "SLIDER" && answer === null) {
      answer = 0
    }
    const newAnswers = [...answers];

    const index = newAnswers.findIndex(
      (a) => a.questionId === currentQuestion.id
    );

    newAnswers[index].value = answer;

    onSessionChange({ ...session, answers: newAnswers });
  };

  const goToPrevQuestion = (): void => {
    let sliceValue = -1 
    let choseAnswerWithDependency = null

    //Try to find if the current question is a dependency of another question's answer
    if (session.dependencies && session.dependencies.length) {
      const possiblePreviousQuestions = session.dependencies.filter( dependency => dependency.answers?.find( answer => answer.next_question && answer.next_question.id === currentQuestion.id))
  
      if (possiblePreviousQuestions.length) {
        // We re getting the question with the highest order, means the most recent one
        const mostRecentAnsweredQuestion = possiblePreviousQuestions.reduce((max, obj) => obj.order > max.order ? obj : max);
        // index in history
        const previousQuestionIndex = history.findIndex( item => item.id === mostRecentAnsweredQuestion.id)
  
        // in order to go back and skip a previous question we need to make sure that we arrived at the current question via the correct answer
        // to avoid going back and skiping a question, when the user didnt skip the question before (ex: user chose 'femme')
        // the saved answer in the session needs to have the same answer id as a value, the same mostRecentAnsweredQuestion id as questionId
        // and the same nextQuestion id as the currentQuestion id
        choseAnswerWithDependency = mostRecentAnsweredQuestion.answers.find( answer => session.answers.some( sessionAnswer => sessionAnswer.value === answer.id && sessionAnswer.questionId === mostRecentAnsweredQuestion.id && answer.next_question.id === currentQuestion.id) )      
  
        if (choseAnswerWithDependency) {
          // if indeed our object is not null then we calculate and earase the history after the targeted question we re going back to
          sliceValue = (previousQuestionIndex - history.length) + 1
        }
      }
    }

    if (history.length > 1) {
      onSessionChange({
        ...session,
        history: history.slice(0, sliceValue),
      });
    } else {
      onPrevClick();
    }
  };

  const { readText } = useVocalisation();

  const goToNextQuestion = async (): Promise<void> => {
    try {
      await onSubmitAnswer(currentQuestion, currentAnswer);

      const nextQuestion = getNextQuestion(
        questions,
        dependencies,
        currentAnswer
      );

      if (!nextQuestion) {
        setIsFinishedQuestionnaire(true);
        return;
      }

      const newHistory = [...history, nextQuestion];

      onSessionChange({
        ...session,
        history: newHistory,
        answers,
      });
    } catch (e) {
      console.log(e);
      present(e?.response?.data?.message);
    }
  };

  const isNextDisabled = !isAnswerValid;

  if (isFinishedQuestionnaire) {
    return <FinishedQuestionnaires onClick={onNextClick} />;
  }

  return (
    <Container>
      <Question
        ruleMessage={translatedRuleMsg}
        answer={currentAnswer?.value}
        question={currentQuestion}
        onAnswerChange={changeAnswer}
        readText={readText}
      />

      <NextPrevButtons
        disableNext={isNextDisabled}
        onPreviousClick={goToPrevQuestion}
        onNextClick={goToNextQuestion}
      />
    </Container>
  );
};

export default Questionnaire;
