import { useState } from "react";
import { getStudyContent } from "../../../../../services/study.service";
import { StudyContentType } from "../../../../../types/welcome";

interface OnBoardingApiReturn {
  content: StudyContentType | null;
  loadContent: () => Promise<void>;
}

const useOnBoardingApi = (
  studyId: number,
  contentId: number,
  lang: string
): OnBoardingApiReturn => {
  const [content, setContent] = useState<StudyContentType | null>(null);

  const loadContent = async (): Promise<void> => {
    try {
      const { data } = await getStudyContent({ studyId, contentId, lang });
      setContent(data);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    content,
    loadContent,
  };
};

export default useOnBoardingApi;
