import { FC, ReactNode, useContext } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import AuthContext from "../../contexts/AuthContext";
import ResonanceLoader from "../atoms/ResonanceLoader/ResonanceLoader";
import { getStudySlug } from "../../helper/study";

interface AuthGuardProps {
  children: ReactNode;
}

const study_slug = getStudySlug();
//

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const { user, isInitialized } = useContext(AuthContext);

  if (!isInitialized) return <ResonanceLoader />;

  if (isInitialized && !user) {
    return <Redirect to={`/${study_slug}`} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
