export const lang = {
  lang_fr: 'French',
  lang_en: 'English',
  lang_es: 'Spanish',
  lang_ar: 'Arabic',
  lang_be: 'Belarussian',
  lang_bg: 'Bulgarian',
  lang_bs: 'Bosnian',
  lang_cs: 'Czech',
  lang_da: 'Danish',
  lang_de: 'German',
  lang_hu: 'Hungarian',
  lang_it: 'Italian',
  lang_ja: 'Japanese',
  lang_pl: 'Polish',
  lang_ro: 'Romanian'
};
