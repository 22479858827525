import { ReactElement } from "react";
import { IonItem } from "@ionic/react";
import { DateQuestionType } from "../../../../types/Question";
import { computeDateLimit } from "../../../../helper/answers/dateAnswer";
import ResonanceDatePicker from "../../../atoms/ResonanceDatePicker";

interface AnswerDateProps {
  question: DateQuestionType;
  value?: string;
  onChange: (value: string) => void;
}

const AnswerDate = ({
  question,
  value = "",
  onChange,
}: AnswerDateProps): ReactElement => {
  const { detail } = question;
  const { format, max_date, min_date } = detail;

  const minDate: string | undefined = computeDateLimit({ ...min_date, isMinDate: true });
  const maxDate: string | undefined = computeDateLimit(max_date);

  return (
    <IonItem>
      <ResonanceDatePicker
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        dateFormat={format}
        onChange={onChange}
        canClear
      />
    </IonItem>
  );
};

export default AnswerDate;
