import { OutputData } from '@editorjs/editorjs';
import { RoleEnum } from './invitation.types';
import { ProfileType } from './profile';

export interface WelcomeSlugType {
  slug: string;
}

export enum WelcomeContentEnum {
  HOME_STUDY = 'home-study',
  DETAILED_HOME_STUDY = 'detailed-home-study',
  ONBOARDING_STUDY = 'onboarding-study',
  INELIGIBLE_PAGE = 'ineligible-page',
}

export interface WelcomeType {
  id: number;
  contents: {
    [WelcomeContentEnum.HOME_STUDY]: WelcomeContentType
    [WelcomeContentEnum.DETAILED_HOME_STUDY]: WelcomeContentType
    [WelcomeContentEnum.ONBOARDING_STUDY]: { id: number }
  },
  invitations: Invitations,
  roles: ProfileType[]
}

export interface Invitations {
  from_user: invitUser,
  to_user: invitUser
}

export interface invitUser {
  id: number,
  firstname: string,
  lastname: string,
  email: string,
  phone_number: string,
  external_id: string,
  user_status: UserStatus,
  role: RoleEnum
  profile: number
}


export enum UserStatus {
  INCLUSION_EN_ATTENTE_DE_VALIDATION = "INCLUSION_EN_ATTENTE_DE_VALIDATION",
  INCLUSION_VALIDEE = "INCLUSION_VALIDEE",
  ACTIVE = "ACTIVE",
}

export enum RoleKeyEnum {
  INVESTIGATOR = 'investigator',
  INTERVENORS = 'intervenor',
  SUBJECT = 'subject',
}

export interface WelcomeRoleType {
  [RoleKeyEnum.INVESTIGATOR]: ProfileType,
  [RoleKeyEnum.INTERVENORS]: ProfileType[],
  [RoleKeyEnum.SUBJECT]: ProfileType,
}

export interface WelcomeContentType {
  id: number,
  content: OutputData,
  avg_amount_time_to_read: number
}

export interface StudyContentType extends WelcomeContentType {
  type: string,
  label: string,
  version: number
}

export enum WelcompeStepEnum {
  HOME = 'HOME',
  HOME_DETAILS = 'HOME_DETAILS',
  PROFILE_SELECTOR = 'PROFILE_SELECTOR',
  HAS_ACCOUNT = 'HAS_ACCOUNT',
  LOGIN = 'LOGIN',
  ON_BOARDING = 'ON_BOARDING',
  CONSENT = 'CONSENT',
  INCLUSION = 'INCLUSION',
  INSCRIPTION = 'INSCRIPTION',
  STRATIFICATION = 'STRATIFICATION',
  SITES = 'SITES',
  NOT_INCLUDED = 'NOT_INCLUDED',
  REGISTRED_SUCCESSFULLY = 'REGISTRED_SUCCESSFULLY',
  INVITATION = 'INVITATION',
  INIT_IDENTIFICATION = 'INIT_IDENTIFICATION',
}

export interface InclusionAnswerType {
  id: number,
  version: number,
  answer: boolean | '',
}

export interface PostUserType {
  role_id: number,
  invitation_token?: string,
  lang: string,
}

export interface PatchUserType {
  firstname: string,
  lastname: string,
  email: string,
  phone_number: string,
  external_id: string,
  nickname: string,
}

export interface PostUserGamification {
  points: number,
  step: number,
  animation_success: number
}

export interface UserGamificationDetails extends PostUserGamification {
  pointsEarned: number,
  stepEarned: number,
  animation_successEarned: number,
}

export interface PostUserResponseType {
  token: string,
  user_id: number,
}

export interface LoginResponseType {
  token: string,
}

export type LoginType = EmailLoginType | NicknameLoginType


export interface EmailLoginType {
  "LOGIN-PASSWORD": {
    login: string,
    password: string,
  }
}

export interface NicknameLoginType {
  "NICKNAME-PASSWORD": {
    nickname: string,
    password: string,
  }
}

export interface StratQuestionType {
  id: number,
  version: number,
  export_code: string,
  label: string,
  order: number,
  answers: StratAnswerType[]
}

export interface StratAnswerType {
  id: number,
  version: number,
  export_code: string,
  label: string,
  order: number,
}

export interface SiteType {
  id: number,
  label: string,
  description: string,
  address: string,
  subsites: SubsiteType[],
}

export interface SubsiteType {
  id: number,
  label: string
}

export interface PostInclusionQuestionType {
  is_eligible: boolean
  not_eligible_content_id?: number
}

export interface PostSiteType {
  sites: {
    site_id: number;
    subsites: number[];
  }[]
}

export interface postConsentRequestType {
  "user": {
    "firstname": string;
    "lastname": string;
    "email": string;
    "nickname": string;
    "password": string;
    "phone_number": string;
    "external_id": string;
  },
  "consent": {
    "id": string;
    "is_validated": string;
    "validation_date": string;
  },
  "identification_questions"?: {
    "id": number;
    "version": number;
    "answer": string;
  }[];
  "stratification_questions"?: {
    "question_id": number;
    "question_version": number,
    "answer_id": number,
    "answer_version": number
  }[]
}
