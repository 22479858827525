export const forgotPassword = {
    "forgot_pwd_title": "Forgot password",
    "forgot_pwd_email": "Email",
    "forgot_pwd_email_phone": "Email or Phone",
    "forgot_pwd_error": "Error in sending",
    "forgot_pwd_success": "Email sent",
    "forgot_pwd_submit": "Recover password",
    "reset_pwd_title": "Reset your password",
    "reset_pwd_submit": "Reset password",
    "forgot_pwd_phone": "Phone",
}