import { ComponentType } from "../../types/Questionnaire";

export const sequence = {
    sequence_empty_list: 'Aucun questionnaire n\'est disponible pour cette période.',
};

export const sequenceIsDone = (components: ComponentType[]): boolean => {
    return components.every(component => {
        if (component.answered_questions_count / component.questions_count != 1)
            return false;
        
        return true;
    });
}