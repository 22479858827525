import { getApi, postApi } from "./resonanceApi";
import { AxiosResponse } from 'axios';
import { InvitationConfigType, PostDirectInclusionRequestType, SendInvitationBodyType, SendInvitationParamsType } from "../types/invitation.types";

export enum InvitationUrlsEnum {
  GET_INVITATIONS = 'studies/{studyId}/users/current/invitations/configs',
  POST_INVITATION = 'studies/{study_id}/users/current/invitations',
  GET_INVITATION_PUB_LINK = 'studies/{studyId}/users/current/invitations/link',
  DIRECT_INCLUSION = 'studies/{studyId}/users/current/invitations/direct_inclusion',
  POST_DIRECT_INCLUSION = 'studies/{studyId}/users/current/invitations/direct_inclusion_invitation',
}

export const getInvitations = ({ studyId, lang }): Promise<AxiosResponse<InvitationConfigType[]>> => {
  const url = InvitationUrlsEnum.GET_INVITATIONS;
  return getApi<InvitationConfigType[]>(url, { studyId, lang });
};

export const sendInvitation = (params: SendInvitationParamsType, body: SendInvitationBodyType): Promise<AxiosResponse> => {
  const url = InvitationUrlsEnum.POST_INVITATION;
  return postApi(url, params, body);
}

export const getInvitPubLink = (params: { studyId: number }, body: { invitation_config_id: number }): Promise<AxiosResponse> => {
  const url = InvitationUrlsEnum.GET_INVITATION_PUB_LINK;
  return postApi(url, params, body);
};

export const getDirectInclusion = (paramas: { studyId: number }): Promise<AxiosResponse<string>> => {
  const url = InvitationUrlsEnum.DIRECT_INCLUSION;
  return getApi(url, paramas);
}

export const postDirectInclusion = (params: { studyId: number }, body: PostDirectInclusionRequestType): Promise<AxiosResponse> => {
  const url = InvitationUrlsEnum.POST_DIRECT_INCLUSION;
  return postApi(url, params, body);
}
