export const lang = {
  lang_fr: 'Français',
  lang_en: 'Anglais',
  lang_es: 'Espagnol',
  lang_ar: 'Arabe',
  lang_be: 'Biélorusse',
  lang_bg: 'Bulgare',
  lang_bs: 'Bosniaque',
  lang_cs: 'Tchèque',
  lang_da: 'Danois',
  lang_de: 'Allemand',
  lang_hu: 'Hongrois',
  lang_it: 'Italien',
  lang_ja: 'Japonais',
  lang_pl: 'Polonais',
  lang_ro: 'Roumain'
};
