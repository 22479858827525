import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { useResonanceLayout } from "../../organisms/ResonnanceLayout/ResoncanceContext";
import { ComponentType, QuestionnaireDetailsType } from "../../../types/Questionnaire";

const Stickers = (): ReactElement => {
    const { questionnaires } = useResonanceLayout();  

    const [totalQuestionnaire, setTotalQuestionnaire] = useState(0);
    const [isLoading,setIsLoading] = useState(true);
      
    useEffect(()=> {
      if (questionnaires) {
        let questionnairesCount = 0;
        for (const index in questionnaires) 
        {
          const questionnaire = questionnaires[index] as ComponentType;
          
          if ((questionnaire.answered_questions_count / questionnaire.questions_count !== 1))
            questionnairesCount++;
        }

        setTotalQuestionnaire(questionnairesCount);
        setIsLoading(false);
      }
    }, [questionnaires]);

    if(!isLoading && totalQuestionnaire > 0){
      return (
          <Dot>{totalQuestionnaire}</Dot>
      )
  } else {
    return <></>
  }
}

const Dot = styled.div`
    position: absolute;
    top: 6%;
    left: 34%;
    transform: translateX(-50%);
    width: 16px;
    height: 16px;
    background-color: #EA4F3D;
    border-radius: 50%;
    z-index: 1; 
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1200px) {
        /* Large screens */
        left: 31%;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        /* Medium screens */
        left: 32.5%;
    }

    @media (max-width: 767px) {
        /* Small screens */
        left: 34%;
    }
`;

export default Stickers;