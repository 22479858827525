import { ReactElement } from "react";
import {
  InvitationChannelDisplayType,
  InvitationChannelEnum,
} from "../../../types/invitation.types";
import {
  chevronForwardOutline,
  linkOutline,
  mailOutline,
  phonePortraitOutline,
} from "ionicons/icons";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Icon from "../../atoms/Icon";
import { EMAIL,LINK, SMS } from "../../../constants/images";

interface Props {
  channel: InvitationChannelDisplayType;
  onClick(): void;
}

const ChannelToIcon: Record<InvitationChannelEnum, string> = {
  [InvitationChannelEnum.EMAIL]: mailOutline,
  [InvitationChannelEnum.SMS]: phonePortraitOutline,
  [InvitationChannelEnum.PUBLICLINK]: linkOutline,
};

const ChannelToImage: Record<InvitationChannelEnum, string> = {
  [InvitationChannelEnum.EMAIL]: EMAIL,
  [InvitationChannelEnum.SMS]: SMS,
  [InvitationChannelEnum.PUBLICLINK]: LINK,
};

const ChannelToEndIcon: Record<InvitationChannelEnum, string> = {
  [InvitationChannelEnum.EMAIL]: chevronForwardOutline,
  [InvitationChannelEnum.SMS]: chevronForwardOutline,
  [InvitationChannelEnum.PUBLICLINK]: linkOutline,
};
const Channel = ({
  channel: { channel, configId },
  onClick,
}: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <ChannelItem onClick={onClick} key={configId}>
      <ChannelImage src={ChannelToImage[channel]}/>
      <ChannelLabel>{t(`invite_${channel}`)}</ChannelLabel>
    </ChannelItem>
  );
};

const ChannelImage = styled.img`
  width : 65%;
  max-width: 40px;
  height : auto;
  color: ${(props) => props.theme.primary.main};
`;

const ChannelItem = styled.div`
  display: flex;
  width : 30%;
  max-width : 100px;
  flex-direction : column;
  align-items: center;
  margin: 15px 0;
  transition: opacity 1s ease;
  &:active {
    opacity: 0.4;
  }
`;

const ChannelLabel = styled.div`
  text-align : center;
  font-size : min(3vw, 15px);
  font-weight : 500;
  margin-top : 2px;
`;

export default Channel;
