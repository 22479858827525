import { ReactElement } from "react";
import EditorJsReader from "../atoms/EditorJsReader/EditorJsReader";
import { OutputData } from "@editorjs/editorjs";
import Container from "../atoms/Container";
import styled from "styled-components";
import Icon from "../atoms/Icon";
import { pauseCircleOutline, volumeHighOutline } from "ionicons/icons";
import useVocalisation from "../../hooks/useVocalisation";
import { useConfig } from "../../contexts/ConfigContext";

interface DisplayContentPRops {
  editorJsData: OutputData;
  buttonComp?: ReactElement;
}

const DisplayContent = ({
  editorJsData, 
  buttonComp,
}: DisplayContentPRops) => {
 
  const { readText, isReading, stopReading } = useVocalisation();
  const { selectedRole } = useConfig();

 const isVocalisationEnabled = selectedRole?.attributes?.is_vocalisation_enabled === false ? false : true;

  const handleRead = async () => {
    let textToRead = "";

    editorJsData.blocks.forEach((block) => {
      switch (block.type) {
        case "list":
          const text = block.data.items.join(".\n");
          textToRead += text + ".\n";
          break;
        case "header":
        case "paragraph":
          textToRead += block.data.text + ".\n";
          break;
      }
    });
    const div = document.createElement("div");
    div.innerHTML = textToRead;
    readText(div.textContent);
    div.remove();
  };
  return (
    <Container>
      <Buttons>
        {isVocalisationEnabled &&
          (isReading ? (
            <IconButton icon={pauseCircleOutline} onClick={stopReading} />
          ) : (
            <IconButton icon={volumeHighOutline} onClick={handleRead} />
          ))}
      </Buttons>
      <div style={{ flex: 1, marginBottom: 20, padding: 20, overflow: "auto" }}>
        <EditorJsReader data={editorJsData} />
      </div>
      {buttonComp}
    </Container>
  );
};

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 20px;
`;

const IconButton = styled(Icon)`
  font-size: 26px;
  color: ${(props) => props.theme.primary.main};
  &:active {
    opacity: 0.5;
  }
`;

export default DisplayContent;
