import { ParamsType } from "../services/resonanceApi"

export interface InvitationConfigType {
  id: number,
  to_role: RoleEnum,
  to_role_label: string,
  to_role_id: number,
  channel: InvitationChannelEnum,
  to_role_config: RoleConfigType,
}

export interface InvitationDisplayType {
  to_role: RoleEnum,
  to_role_label: string,
  to_role_id: number,
  channels: InvitationChannelDisplayType[],
}

export interface InvitationChannelDisplayType {
  configId: number,
  channel: InvitationChannelEnum,
  to_role_config: RoleConfigType,
}

export interface RoleConfigType {
  has_firstname: boolean,
  has_lastname: boolean,
  has_email: boolean,
  has_phonenumber: boolean,
  has_external_id: boolean
}

export enum RoleEnum {
  SUBJECT = 'SUBJECT',
  INTERVENOR = 'INTERVENOR',
  INVESTIGATOR = 'INVESTIGATOR',
}

export enum InvitationChannelEnum {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  PUBLICLINK = 'PUBLIC_LINK'
}

export interface SendInvitationParamsType extends ParamsType {
  study_id: number
}

export interface SendInvitationBodyType {
  target_user: {
    firstname: string,
    lastname: string,
    email?: string,
    phone_number?: string,
  },
  channel: string,
  invitation_config_id: number,
  lang: string
}
export interface sendInvitationFormType {
  firstname: string;
  lastname: string;
  phone_number?: string;
  email?: string;
  lang: string;
}

export interface PostDirectInclusionRequestType {
  target_user: {
    firstname: string,
    lastname: string,
    email?: string,
    phone_number?: string,
  },
  channel: string,
  lang: string,
  invitation_token: string,
}