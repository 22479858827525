import styled from "styled-components";

const ScreenTitle = styled.h1`
  position: absolute;
  text-align: center;
  color: #393939;
  font-weight: bold;
  width: 80%;
  top: 40%;
  left: 10%;
  transform: translateY(-50%);
  margin: 0;
`;

export default ScreenTitle;