import { IonIcon } from '@ionic/react';
import { ReactElement } from 'react';
import { chevronForwardOutline, volumeHighOutline } from 'ionicons/icons';
import { SubsiteType } from '../../../types/welcome';
import styled from 'styled-components';
import useVocalisation from '../../../hooks/useVocalisation';
import { useConfig } from '../../../contexts/ConfigContext';

interface DisplaySitesProps {
  sites: SubsiteType[];
  onSiteClick: (sites: SubsiteType) => void;
}

const DisplaySitesList = ({ sites, onSiteClick }: DisplaySitesProps): ReactElement => {
  const { readText } = useVocalisation();
  const { selectedRole } = useConfig();
  const isVocalisationEnabled =
  selectedRole?.attributes?.is_vocalisation_enabled;
  
  const renderSiteItem = (site: SubsiteType, index: number): ReactElement => {
    const { label, id } = site;

    return (
      <SiteItem key={id} >
        <SiteLabel onClick={(): void => onSiteClick(site)}>
          {label}
        </SiteLabel>
        {isVocalisationEnabled  && <SiteIcon slot="end" icon={volumeHighOutline} onClick={() => readText(label)}/>}
        <SiteIcon slot="end" icon={chevronForwardOutline} onClick={(): void => onSiteClick(site)}/>
      </SiteItem>
    );
  };

  return (
    <>
      {sites.map(renderSiteItem)}
    </>
  );
};


const SiteItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 14px 0px;
  margin: 0 20px;
  border-bottom: 1px solid #dedede;
  align-items: center;
`

const SiteLabel = styled.div`
  flex: 1;
`

const SiteIcon = styled(IonIcon)`
  margin-left: auto;
  font-size: 18px!important;
`

export default DisplaySitesList;
