import { ReactElement, useEffect, useState } from "react";
import Questionnaire from "./Questionnaire";
import {
  defaultAnswerValueByType,
  formatAnswers,
  getAnswerValue,
  hasAsweredFunctionsByType,
} from "../../../helper/answer";
import {
  getApiQuestionnaire,
  getDependencies,
  postAnswers,
} from "../../../services/questionnaire.service";
import ResonanceStore from "../../../storage/resonanceStorage";
import { QuestionnaireSession } from "../../../types/Questionnaire";
import ResonanceLoader from "../../atoms/ResonanceLoader/ResonanceLoader";
import { useConfig } from "../../../contexts/ConfigContext";
import { QuestionType, QuestionTypeEnum } from "../../../types/Question";
import {
  AnswerType,
  PostAnswersBodyType,
  PostAnswersParamsType,
} from "../../../types/Answer";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Info from "../../atoms/ErrorComponent";
import { getSequenceAnswers } from "../../../services/sequence.service";
import { useAuth } from "../../../contexts/AuthContext";
import useToast from "../../../hooks/useToast";

const generateNewSession = async (
  studyId: number,
  sequenceId: number,
  questionnaireId: number,
  userId: number,
  lang: string
): Promise<QuestionnaireSession | void> => {
  const { data } = await getApiQuestionnaire(studyId, questionnaireId, lang);
  const {
    data: { answers: savedAnswers },
  } = await getSequenceAnswers({
    study_id: studyId,
    user_id: userId,
    sequence_id: sequenceId,
  });

  const questionners_answers = savedAnswers.filter(
    (answer) => answer.questionnaire_id === questionnaireId
  );

  const { data: dependencies } = await getDependencies({
    studyId,
    questionnaireId,
  });

  const formatted_answers = formatAnswers(questionners_answers);

  const { questions, version } = data;

  if (questions.length === 0) return;

  const answers = [];

  let lastResponse = 0;
  questions.forEach((question, index) => {
    const ans = formatted_answers.find((a) => a.question_id === question.id);
    if (ans) lastResponse = index;
    answers.push({
      value: ans
        ? getAnswerValue(question.type, ans)
        : defaultAnswerValueByType[question.type],
      questionId: question.id,
      questionType: question.type,
    });
  });

  let history: QuestionType[] = questions.slice(0, lastResponse + 1);

  return {
    questions,
    answers,
    history,
    version,
    dependencies,
  };
};

const defaultSession: QuestionnaireSession = {
  questions: [],
  answers: [],
  history: [],
  dependencies: [],
  version: 0,
};

interface SequenceQuestionnaireProps {
  questionnaireId: number;
  questionnaireTitle: string;
  onNextClick(): void;
  onPrevClick(): void;
}

const SequenceQuestionnaire = ({
  questionnaireTitle,
  questionnaireId,
  onNextClick,
  onPrevClick,
}: SequenceQuestionnaireProps): ReactElement => {
  const [session, setSession] = useState<QuestionnaireSession>(defaultSession);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { sequenceId } = useParams<{ sequenceId: string }>();
  const { user } = useAuth();
  const present = useToast();

  const { questions } = session;
  const storeKey = `study_${questionnaireId}_session`;
  const { study } = useConfig();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  // used to retrieve from store or init a new session for current questionnaire
  useEffect((): void => {
    const getFromStore = async (): Promise<void> => {
      try {
        setIsLoading(true);
        setIsError(false);
        // Initialize a new session in store if doesn't exists
        const newSession = await generateNewSession(
          study.id,
          Number(sequenceId),
          questionnaireId,
          user.id,
          language
        );

        await ResonanceStore.set(storeKey, newSession);
        if (newSession) {
          setSession(newSession);
        }
      } catch (error) {
        setIsError(true);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getFromStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeKey, questionnaireId, language]);

  // used to save session in store on each change
  useEffect((): void => {
    ResonanceStore.set(storeKey, session);
  }, [session, storeKey]);

  const onSubmitAnswer = async (
    question: QuestionType,
    answer: AnswerType
  ): Promise<void> => {
    try {
      const params: PostAnswersParamsType = {
        studyId: study.id,
        userSequenceId: sequenceId,
        questionnaireId: questionnaireId,
        questionId: question.id,
      };

      let answer_id: any = [];
      let value;
      const is_empty_answer = !hasAsweredFunctionsByType[answer.questionType](
        answer.value
      );

      if (!is_empty_answer) {
        switch (answer.questionType) {
          case QuestionTypeEnum.NUMBER:
            value = [answer.value];
            break;
          case QuestionTypeEnum.CHECKBOX:
            answer_id = answer.value;
            break;
          case QuestionTypeEnum.RADIOBUTTON:
          case QuestionTypeEnum.DROPDOWN:
            answer_id = [answer.value];
            break;
          default:
            value = Array.isArray(answer.value) ? answer.value : [answer.value];
        }
      } else {
        value = [];
      }

      const body: PostAnswersBodyType = {
        question_type: question.type,
        questionnaire_version: session.version,
        question_version: Number(question.version),
        is_empty_answer: is_empty_answer,
        question_type_id: question.detail.id,
        question_type_version: question.detail.version,
        answer_id: answer_id,
        answer_date: new Date().toISOString(),
        value: value,
      };

      await postAnswers(params, body);
    } catch (e) {
      console.log(e);
      present(e?.response?.data?.message);
    }
  };

  if (isLoading) return <ResonanceLoader />;

  if (isError)
    return (
      <Info
        title={t("common_server_error_title")}
        description={t("common_server_error_desc")}
        actionText={t("common_go_back")}
        onClick={onPrevClick}
      />
    );

  if (!questions || questions.length === 0)
    return (
      <Info
        title={t("common_nodata_title")}
        description={t("common_nodata_desc")}
        actionText={t("common_go_back")}
        onClick={onPrevClick}
      />
    );

  return (
    <Questionnaire
      questionnaireTitle={questionnaireTitle}
      session={session}
      onSessionChange={setSession}
      onSubmitAnswer={onSubmitAnswer}
      onNextClick={onNextClick}
      onPrevClick={onPrevClick}
    />
  );
};

export default SequenceQuestionnaire;
