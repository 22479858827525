import { IonIcon } from "@ionic/react";
import { FC } from "react";
import styled from "styled-components";

interface ButtonProps {
  icon?: string;
  isIconBefore?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

const Button: FC<ButtonProps> = ({ icon, isIconBefore, children, ...props }) => {
  return (
    <StyledButton {...props}>
      {isIconBefore && icon && <StyledIcon src={icon} isIconBefore={isIconBefore}/>}
      {children}
      {!isIconBefore && icon && <StyledIcon src={icon} isIconBefore={isIconBefore}/>}
    </StyledButton>
  );
};

interface StyledIconProps {
  isIconBefore?: boolean;
}

const StyledIcon = styled(IonIcon)<StyledIconProps>`
  font-weight: bold;
  font-size: ${(props) => props.theme.icon.size.regular};
  color: inherit;
  margin: 5px;
  position: absolute;
  top: 50%;
  bottom: 50%;
  margin-top: auto;
  margin-bottom: auto;
  font-size: ${(props) => props.theme.icon.size.large};
  ${(props) => (props.isIconBefore ? 'left: 10px;' : 'right: 10px;')}
`;

const StyledButton = styled.button`
  background-color: ${(props) => props.theme.secondary.main};
  color: ${(props) => props.theme.secondary.contrast};
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 0px 20px;
  line-height: 50px;
  font-size: 18px;
  display: flex;
  width: fit-content;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export default Button;
