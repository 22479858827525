import { IonModal } from "@ionic/react";
import { useFormik } from "formik";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TextField } from "../../components/atoms/LoginTextField";
import * as Yup from "yup";
import { changePassword } from "../../services/user.service";
import { useAuth } from "../../contexts/AuthContext";
import { useConfig } from "../../contexts/ConfigContext";
import Icon from "../../components/atoms/Icon";
import { alertCircle } from "ionicons/icons";
import useToast from "../../hooks/useToast";

interface Props {
  isOpen: boolean;
  onDismiss(): void;
}

interface changePasswordFormType {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required(),
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])(?!.*\s).{8,100}$/,
      "form_error_email_password"
    )
    .required(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "form_error_email_confirm_password")
    .required(),
});

const ChangePwdModal: FC<Props> = ({ isOpen, onDismiss }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { study } = useConfig();
  const [isError, setIsError] = useState(false);
  const present = useToast();

  const handleSubmit = async (values: changePasswordFormType) => {
    try {
      setIsError(false);
      await changePassword(
        {
          studyId: study.id,
        },
        {
          login: user.attributes.email,
          old_password: values.oldPassword,
          new_password: values.newPassword,
        }
      );
      onDismiss();
    } catch (e) {
      setIsError(true);
      present(e?.response?.data?.message);
    }
  };

  const { values, touched, errors, submitForm, setFieldValue } =
    useFormik<changePasswordFormType>({
      initialValues: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      onSubmit: handleSubmit,
      validationSchema: changePasswordSchema,
    });

  return (
    <IonModal isOpen={isOpen} backdropDismiss={true} onWillDismiss={onDismiss}>
      <Container onClick={onDismiss}>
        <Card onClick={(e) => e.stopPropagation()}>
          <Title>{t("change_pwd_title")}</Title>

          <TextField
            value={values.oldPassword}
            type="password"
            error={touched.oldPassword && errors.oldPassword}
            onChange={(value) => setFieldValue("oldPassword", value)}
            label={t("change_pwd_old_password")}
            placeholder={t("change_pwd_old_password")}
          />

          <TextField
            value={values.newPassword}
            type="password"
            error={touched.newPassword && errors.newPassword}
            onChange={(value) => setFieldValue("newPassword", value)}
            label={t("change_pwd_new_password")}
            placeholder={t("change_pwd_new_password")}
          />

          <TextField
            value={values.confirmPassword}
            type="password"
            error={touched.confirmPassword && errors.confirmPassword}
            onChange={(value) => setFieldValue("confirmPassword", value)}
            label={t("change_pwd_confirm_password")}
            placeholder={t("change_pwd_confirm_password")}
          />

          {isError && (
            <Error>
              <Icon icon={alertCircle} />
              {t("change_pwd_error")}
            </Error>
          )}

          <Buttons>
            <ModalButton onClick={onDismiss}>{t("common_cancel")}</ModalButton>
            <ModalButton onClick={submitForm}>{t("common_next")}</ModalButton>
          </Buttons>
        </Card>
      </Container>
    </IonModal>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Error = styled.div`
  color: red;
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 15px;
`;

const Card = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ModalButton = styled.div`
  padding: 10px 20px;
  color: ${(props) => props.theme.primary.main};
`;

export default ChangePwdModal;
