import { useTranslation } from 'react-i18next';
import { formatHour, hasAsweredFunctionsByType, validationFunctionsByType } from '../helper/answer';
import { AnswerValueType } from '../types/Answer';
import { CheckboxQuestionType, HourQuestionType, NumberQuestionType, QuestionType, QuestionTypeEnum } from '../types/Question';

interface AnswerRuleHook {
  isAnswerValid: boolean;
  translatedRuleMsg: string;
}

const useAnswerRules = (question: QuestionType, answer: AnswerValueType): AnswerRuleHook => {
  const { t } = useTranslation();
  const { is_mandatory_question: isMandatory, type } = question;

  const userHasAnswered = hasAsweredFunctionsByType[type](answer);

  const isAnswerValid: boolean = userHasAnswered ?
    validationFunctionsByType[type](question, answer) :
    !isMandatory;

  const translatedRuleByTypes: Record<QuestionTypeEnum, (question: QuestionType) => string> = {
    CHECKBOX: (question: QuestionType): string => {
      const { detail: { rules: { constraint, quantity } } } = question as CheckboxQuestionType;
      return t(`answer_checkbox_constraint_${constraint}`, { quantity });
    },
    RADIOBUTTON: () => t('answer_radio_constraint'),
    HOUR: (question: HourQuestionType): string => {
      const { detail: { format, min_hour, max_hour } } = question;

      const min = formatHour(min_hour, format);
      const max = formatHour(max_hour, format);

      return t(`answer_hour_constraint`, { min, max });

    },
    NUMBER: (question: QuestionType): string => {
      const { detail: { min_value: min, max_value: max, number_decimal: nbDecimales } } = question as NumberQuestionType;
      const minMaxString = t(`answer_number_constraint_min_max`, { min, max });
      const decString = t(`answer_number_constraint_decimal`, { nbDecimales });

      return `${minMaxString} ${decString}`;
    },
    DATE: () => t('answer_date_constraint'),
    SLIDER: () => '',
    DROPDOWN: () => t('answer_dropdown_constraint'),
    TEXT: () => t('answer_text_constraint')
  }

  return {
    translatedRuleMsg: translatedRuleByTypes[question.type](question),
    isAnswerValid
  };
}

export default useAnswerRules;
