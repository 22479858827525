import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { StratQuestionType } from '../../../../types/welcome';
import { FormGroup } from '../../../atoms/LoginTextField';
import SelectField from '../../../atoms/SelectField';

interface DisplayStratQuestionsProps {
  stratQuestions: StratQuestionType[];
  values: {[key: string]: string};
  setFieldValue(key: string, value: string): void;
  errors: any;
  touched: any;
};

const DisplayStratQuestions = ({ stratQuestions, values, setFieldValue, touched, errors}: DisplayStratQuestionsProps): ReactElement => {
  const { t } = useTranslation();


  const renderItem = (question: StratQuestionType, index: number): ReactElement => {
    const { id: questionId = 0, label, answers } = question;

    const options = answers.map(({id, label})=>({ text: label, id: id.toString() }))

    return (
      <SelectField 
        key={questionId}
        value={values[questionId]}
        placeholder={t('home_stratification_placeholder')}
        label={label}
        options={options}
        error={touched[questionId] && errors[questionId]}
        onChange={value => setFieldValue(questionId.toString(), value)}
      />
    );
  };

  return (
    <FormGroup>
      {
        stratQuestions.map(renderItem)
      }
    </FormGroup>
  );
};

export default DisplayStratQuestions;
