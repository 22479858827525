import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { UserGamificationDetails } from "../../../types/welcome";
import { ComponentType, QuestionnaireDetailsType } from "../../../types/Questionnaire";


interface ResonanceContextValueInterface {
    disableHeader: boolean;
    disableNav: boolean; 
    isMenuVisible: boolean;
    backgroundColor: string;
    title: string;
    userGamificationDetails : UserGamificationDetails;
    questionnaires: ComponentType[];
    welcomePage: boolean;
}

interface ResonaceContextInterface extends ResonanceContextValueInterface {
    setResonanceLayout(arg: Partial<ResonanceContextValueInterface>): void;
}

const ResonanceLayoutDefaultStateValue: ResonanceContextValueInterface = {
    disableHeader: false,
    disableNav: false,
    isMenuVisible: false,
    backgroundColor: 'white',
    title: undefined,
    userGamificationDetails: undefined,
    questionnaires: undefined,
    welcomePage: false,
}

const ResonanceLayoutDefaultValue: ResonaceContextInterface = {
    ...ResonanceLayoutDefaultStateValue,
    setResonanceLayout: () => console.warn('context not intialized')
}

const ResonanceLayoutContext = createContext<ResonaceContextInterface>(ResonanceLayoutDefaultValue)

const ResonanceLayoutProvider:any= ({children})=>{
    const [state, setState] = useState(ResonanceLayoutDefaultStateValue)

    const setResonanceLayout = (arg: Partial<ResonanceContextValueInterface>) => {
        setState(prevState => ({
            ...prevState,
            ...arg
        }))
    }
    
    const contextValue = useMemo(()=>({
        ...state,
        setResonanceLayout
    }), [state]);

    

    return (
        <ResonanceLayoutContext.Provider value={contextValue}>
            {children}
        </ResonanceLayoutContext.Provider>
    )
};

const useResonanceLayout = () => useContext(ResonanceLayoutContext);

const useSetResonanceLayout = (state: Partial<ResonanceContextValueInterface>)=>{
    const {setResonanceLayout} = useResonanceLayout();

    useEffect(()=>{
        setResonanceLayout(state)

        return ()=> setResonanceLayout(ResonanceLayoutDefaultStateValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}


export {
    ResonanceLayoutContext,
    ResonanceLayoutProvider,
    useResonanceLayout,
    useSetResonanceLayout
}