export const answer = {
  answer_mandatory: 'Question obligatoire',
  answer_noMandatory: 'Vous pouvez passer cette question',
  answer_common_constraint: 'Sélectionner une réponse',
  answer_checkbox_constraint_NONE: 'Sélectionner une ou plusieurs réponses',
  answer_checkbox_constraint_AT_LEAST: 'Sélectionner au moins {{quantity}} réponses',
  answer_checkbox_constraint_EXACTLY: 'Sélectionner exactement {{quantity}} réponses',
  answer_checkbox_constraint_AT_MOST: 'Sélectionner au plus {{quantity}} réponses',
  answer_dropdwon_placeholder: 'Choisir réponse',
  answer_text_placeholder: 'Saisir votre texte ici',
  answer_text_placeholderEmail: 'Saisir email',
  answer_number_placeholder: 'Saisir nombre',
  answer_number_constraint_min_max: 'La valeur doit être comprise entre {{min}} et {{max}}.',
  answer_number_constraint_decimal: 'Maximum {{nbDecimales}} décimales autorisées.',
  answer_date_format_YEAR: 'YYYY',
  answer_date_format_MONTH: 'MM/YYYY',
  answer_date_format_DAY: 'DD/MM/YYYY',
  answer_date_placeholder: 'Choisir date',
  answer_hour_placeholder: 'Choisir horaire',
  answer_radio_constraint: 'Une réponse possible',
  answer_dropdown_constraint: 'Sélectionner votre réponse',
  answer_text_constraint: 'Saisissez votre réponse de manière libre',
  answer_date_constraint: 'Sélectionner une date',
  answer_hour_constraint: 'Sélectionner un horaire entre {{min}} et {{max}}',
};
