import { IonButton, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { micOutline } from "ionicons/icons";
import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useConfig } from "../../../../contexts/ConfigContext";
import useVocalisation from "../../../../hooks/useVocalisation";
import { SliderQuestionType } from "../../../../types/Question";
import RangeInput from "../../RangeInput";

interface AnswerSliderProps {
  question: SliderQuestionType;
  value?: number | null;
  onChange: (value: number | null) => void;
}

const DEFAULT_MIN = 0;
const DEFAULT_MAX = 100;

const DISABLE_SPEECH_RECOGNITION = true;

const AnswerSlider = ({
  question,
  value,
  onChange,
}: AnswerSliderProps): ReactElement => {
  const {
    detail: {
      step,
      answers,
      tick_interval,
      minor_tick_interval,
      prefix,
      suffix,
    },
  } = question;

  const { selectedRole } = useConfig();

  const { t } = useTranslation();

  const [min, max] = useMemo(
    function getSliderMinMax() {
      if (answers.length < 2) return [DEFAULT_MIN, DEFAULT_MAX];

      const values = answers.map((a) => a.value);

      const _min = Math.min(...values);
      const _max = Math.max(...values);

      return [_min, _max];
    },
    [answers]
  );

  const possibleValues = useMemo<string[]>(
    function () {
      const result = [];

      for (let i = min; i <= max; i += step) {
        result.push(i.toString());
      }

      return result;
    },
    [min, max, step]
  );

  const { startListnening, isListening } = useVocalisation(
    possibleValues,
    (value) => onChange(value as any)
  );

  const isSpeechRecEnabled =
    selectedRole?.attributes?.is_speech_recognition_enabled;

  return (
    <>
      <RangeInput
        min={min}
        max={max}
        step={step}
        formatPin={(value) => value && `${prefix}${value}${suffix}`}
        tick={tick_interval}
        subTick={minor_tick_interval}
        value={[value ?? min]}
        options={answers}
        onChange={(values) => onChange(values[0])}
      />
      {!DISABLE_SPEECH_RECOGNITION && isSpeechRecEnabled && (
        <IonItem lines="full">
          <IonLabel>
            <p>{t("common_click_to_speak")}</p>
          </IonLabel>

          <IonButton onClick={startListnening} slot="end" fill="clear">
            {isListening ? (
              <IonIcon slot="icon-only" src="/assets/icon/loading.svg" />
            ) : (
              <IonIcon slot="icon-only" icon={micOutline} />
            )}
          </IonButton>
        </IonItem>
      )}
    </>
  );
};

export default AnswerSlider;
