import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DisplayInvitations from "../../components/molecules/Invitations/DisplayInvitations";
import InvitationFormModal from "../../components/organisms/Invitation/InvitationFormModal";
// import InvitationFormEmail from "../../components/organisms/Invitation/InvitationFormEmail";
import { convertInvitations } from "../../helper/invitation";
import {
  getInvitations,
  getInvitPubLink,
  sendInvitation,
} from "../../services/invitation.service";
import {
  InvitationChannelDisplayType,
  InvitationChannelEnum,
  InvitationConfigType,
  InvitationDisplayType,
  sendInvitationFormType,
} from "../../types/invitation.types";
import { useConfig } from "../../contexts/ConfigContext";
import { useResonanceLayout } from "../../components/organisms/ResonnanceLayout/ResoncanceContext";
import { useFetch } from "../../hooks/useFetch";
import ResonanceLoader from "../../components/atoms/ResonanceLoader/ResonanceLoader";
import Info from "../../components/atoms/ErrorComponent";
import useToast from "../../hooks/useToast";
import Goal from "../../components/molecules/Invitations/Goal";

const InvitationPage = (): ReactElement => {
  const [isInvitModalVisible, SetIsInvitModalVisible] =
    useState<boolean>(false);
  const { study } = useConfig();
  const { setResonanceLayout } = useResonanceLayout();

  const [channel, setChannel] = useState<InvitationChannelDisplayType>();

  const present = useToast();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    isError,
    isLoading,
    data: invitationConfigs,
  } = useFetch<InvitationConfigType[]>("invitations", getInvitations, {
    studyId: study.id,
    lang: language,
  });

  useEffect(function () {
    setResonanceLayout({
      backgroundColor: "#eee",
    });

    return () =>
      setResonanceLayout({
        backgroundColor: "#fff",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyToClipboard = (value: string): void => {
    const el = document.createElement("textarea");
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  // if (!invitationConfigs) {
  //   return <div>loading...</div>;
  // }

  if (isLoading) return <ResonanceLoader />;

  if (isError)
    return (
      <Info
        title={t("common_server_error_title")}
        description={t("common_server_error_desc")}
        actionText={t("common_go_back")}
      />
    );

  const formattedInvitations = convertInvitations(invitationConfigs);

  async function onChannelClick(
    invit: InvitationDisplayType,
    channel: InvitationChannelDisplayType
  ) {
    setChannel(channel);
    switch (channel.channel) {
      case InvitationChannelEnum.EMAIL:
      case InvitationChannelEnum.SMS:
        SetIsInvitModalVisible(true);
        break;
      case InvitationChannelEnum.PUBLICLINK:
        const { data: link } = await getInvitPubLink(
          { studyId: study.id },
          { invitation_config_id: channel.configId }
        );
        copyToClipboard(link);
        present(t("invite_copy_notification"));
        break;
    }
  }

  async function onSubmitForm(values: sendInvitationFormType) {
    const body = {
      target_user: {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        phone_number: values.phone_number,
      },
      channel: channel.channel,
      invitation_config_id: channel.configId,
      lang: values.lang,
    };
    await sendInvitation({ study_id: study.id }, body);
  }

  return (
    <>
      <DisplayInvitations
        invitations={formattedInvitations}
        onChannelClick={onChannelClick}
      />

      <Goal/>

      {isInvitModalVisible && (
        <InvitationFormModal
          isOpen={isInvitModalVisible}
          onDismiss={(): void => SetIsInvitModalVisible(false)}
          channel={channel?.channel}
          onSaveClick={onSubmitForm}
        />
      )}
    </>
  );
};

export default InvitationPage;
