import styled from "styled-components";

const Card = styled.div`
    background: white;
    margin: 30px 20px;
    padding: 5px 10px;
    border-radius: 25px;
    box-shadow: 2px 2px 4px 4px rgba(0,0,0,0.1);
`

export default Card;