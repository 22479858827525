import { MyEnum, landscapeData } from "./LandscapeData";

export const animatedLandscape = (id, currentLandscapeAnimationPlayed: boolean[], newLandscapeAnimationPlayed: boolean[]) => {
  const baseAnimation = landscapeData.find((image) => image.id === id)?.animation || { x: 0, startOpacity: 0, endOpacity: 0, transition: { duration: 0 } };
  let customAnimation = { x: 0, opacity: 0, transition: { duration: 0 } };
  let index = id-1;

  if (currentLandscapeAnimationPlayed[index])
  {
    customAnimation = {
      ...({ opacity: baseAnimation.endOpacity }),
      ...({ x: baseAnimation.x }),
      ...({ transition: { duration: 0 } }),
    };
  }
  else 
  {
    if(newLandscapeAnimationPlayed && newLandscapeAnimationPlayed[index]) 
    {
      customAnimation = {
        ...({ opacity: baseAnimation.endOpacity }),
        ...({ x: 0 }),
        ...({ transition: baseAnimation.transition }),
      };
    }
    else
    {
      customAnimation = {
        ...({ opacity: baseAnimation.startOpacity }),
        ...({ x: 0 }),
        ...({ transition: { duration: 0 } }),
      };
    }
  }


  return customAnimation;
};

export const findElementsByKey = (value: string): number => {
  const lowerCaseValue = value.toLowerCase();
  
  for (const key in MyEnum) {
    if (key.toLowerCase() === lowerCaseValue) {
      return MyEnum[key as keyof typeof MyEnum];
    }
  }

  return 0;
};

export const findElementsByValue = (value: number): string[] => {
  const keys: string[] = [];

  for (const key in MyEnum) {
    const enumValue = MyEnum[key as keyof typeof MyEnum];
    if (enumValue && (value & enumValue) === enumValue) {
      keys.push(key);
    }
  }

  return keys.length > 0 ? keys : ["Unknown"];
};