export const home = {
  home_profile_selector_text: 'اختر ملفًا شخصيًا',
  home_has_account_true: 'لا، أنا أشارك بالفعل',
  home_has_account_false: 'نعم، أنضم إلى الدراسة!',
  home_is_new: 'هل أنت جديد؟',
  home_forgot_password: 'هل نسيت كلمة المرور؟',
  home_inscription_title: 'التسجيل',
  home_consent_title: 'الموافقة',
  home_content_check_text: 'بتحديد هذه الخانة، أؤكد أنني أوافق على جميع شروط وثيقة المعلومات',
  home_content_none_text: 'بدون موافقة',
  home_not_consent_text: 'لا أوافق على جميع الشروط',
  home_content_signature_text: 'وقع أدناه لتقديم موافقتك',
  home_inclusion_placeholder: 'اختر واحدًا',
  home_inclusion_answer_true: 'نعم',
  home_inclusion_answer_false: 'لا',
  home_inclusion_on: 'حولك',
  home_inclusion_off: 'لا سؤال حول الإدراج',
  home_identifier_title: 'اختر معرفك',
  home_contact_title: 'الاتصال بك',
  home_contact_description: 'لكي تعمل الدراسة، ستتلقى تذكيرات ومعلومات تصل إلى مرتين في الشهر.',
  home_inscription_identifier: 'البريد الإلكتروني أو اللقب',
  home_inscription_firstname: 'الاسم الأول',
  home_inscription_lastname: 'اسم العائلة',
  home_inscription_nickname: 'اللقب',
  home_inscription_email: 'البريد الإلكتروني',
  home_inscription_phone: 'الهاتف',
  home_inscription_external_id: 'رقم الملف',
  home_inscription_off: 'بدون تعريف',
  home_secret_questions: 'الأسئلة السرية',
  home_secret_question_date_placeholder: 'اختر تاريخًا',
  home_secret_question_number_placeholder: 'أدخل الرقم',
  home_secret_question_text_placeholder: 'أدخل النص',
  home_password_title: 'أنشئ كلمة المرور الخاصة بك',
  home_password_placeholder: 'كلمة المرور',
  home_password_placeholder_confirm: 'تأكيد كلمة المرور',
  home_login_email_placeholder: 'البريد الإلكتروني أو اللقب',
  home_login_nickname_placeholder: 'اللقب أو البريد الإلكتروني أو الهاتف',
  home_login_file_placeholder: 'أدخل رقم الملف',
  home_password_description: 'يجب أن تكون كلمة المرور بطول 8 أحرف على الأقل وتحتوي على أحرف كبيرة وصغيرة ورموز وأرقام.',
  home_login_title: 'تسجيل الدخول',
  home_login_subtitle: 'سعيد برؤيتك مرة أخرى!',
  home_login_button: 'تسجيل الدخول',
  home_login_password_placeholder: 'كلمة المرور الخاصة بك',
  home_stratification_title: '',
  home_stratification_placeholder: 'اختر واحدًا',
  home_sites_text: 'الانتماء إلى الموقع',
  home_subsites_text: 'الانتماء إلى الفرع',
  home_do_you_have_account: 'هل لديك حساب؟',
  home_researchers_website: 'موقع الباحثين',
  home_registred_successfully: 'تهانينا، تم تسجيلك بنجاح.',
  home_inscription_error_EMAIL_EXIST: 'يوجد حساب بهذا البريد الإلكتروني بالفعل',
  home_inscription_error_NICKNAME_EXIST: 'يوجد حساب بهذا اللقب بالفعل',

  // إضافة Synakene
  home_welcome: "مرحبًا بك في Mentalo!",
  home_description: "شارك في هذه الدراسة الكبيرة وقدم أبحاثًا حول الرفاهية العقلية للشباب من سن 11 إلى 24 عامًا.",
  home_anonymous_data: "بياناتك سرية؛ لن يتمكن الباحثون من الوصول إلى هويتك.",
  home_new: "هل أنت جديد؟",
  home_copy_link: "انسخ رابط الدراسة",
  home_copied: "تم نسخ الرابط بنجاح!",
  test_the_app: "جرب التطبيق",
  not_eligible_but_try_the_app: "لا، ليس لدي بين 11 و 24 عامًا، ولكن أود اكتشاف التطبيق",
  home_add_mentalo: "أضيف Mentalo",
  home_story_telling_title: "رواية القصص",
  home_story_telling_text: "رواية القصص",

  home_not_included: "شكرًا لاهتمامك، ولكن يجب أن تكون بين 11 و 24 عامًا وتعيش في فرنسا.",
  home_not_included_desc: "لمساعدتنا، يمكنك مشاركة الدراسة مع من حولك."
};
