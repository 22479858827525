import { alertCircle } from "ionicons/icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DateFormatEnum } from "../../types/Question";
import Icon from "./Icon";
import ResonanceDatePicker from "./ResonanceDatePicker";

interface TextFieldProps {
  icon?: string;
  value: string;
  onChange(value: string): void;
  placeholder: string;
  type: string;
  defaultValue?: string;
  label?: string;
  error?: string | { key: string; values: Record<string, number | string> };
  disabled?: boolean;
}

const TextField: FC<TextFieldProps> = function ({
  value,
  defaultValue,
  label,
  type,
  error,
  onChange,
  placeholder,
  icon,
  disabled=false,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <StyledTextField error={error as any}>
        {icon && <FieldIcon icon={icon} />}
        <Field>
          {value && <Label>{label}</Label>}

          {type === "date" ? (
            <DateTimeInput
              value={value}
              placeholder={placeholder}
              dateFormat={DateFormatEnum.DAY}
              onChange={onChange}
            />
          ) : (
            <Input
              value={value}
              type={type as any}
              onChange={(e: any): void => onChange(e.target.value)}
              placeholder={placeholder}
              disabled={disabled}
            />
          )}
        </Field>
        {error && <ErrorIcon icon={alertCircle} />}
      </StyledTextField>
      {error &&
            (typeof error === "string" ? (
              <Error>{t(error)}</Error>
            ) : (
              <Error>{t(error["key"], error["values"])}</Error>
            ))}
    </div>
  );
};

const StyledTextField = styled.div<{ error: string }>`
  display: flex;
  box-shadow: 0px 0px 25px -12px #000000;
  background-color:#F7F7F7;
  border-radius:30px;
  color:#bdbdbd;
  font-size:18px;
  margin: 20px 0 10px 0;
  padding: 14px 10px;
  height: 5vh;
  align-items: center;
  color: ${(props) => (props.error ? "red" : "auto")}!important;
  input::placeholder {
    color: ${(props) => (props.error ? "red" : "auto")}!important;
  }
`;

const DateTimeInput = styled(ResonanceDatePicker)`
  margin: 3px;
  padding: 0;
`;

const FieldIcon = styled(Icon)`
  color: ${(props) => props.theme.primary.main};
  font-size: 20px;
  margin-right: 10px;
`;

const Field = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Label = styled.div`
  font-size: 11px;
  color: #afafaf;
  margin-bottom: 2px;
  margin-left: 2px;
`;

const FormGroup = styled.div`
  margin: 0 50px 0 50px;
`;

const Input = styled.input`
  background: none;
  border: none;
  width: 100%;
  outline: none;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #969696;
  }
`;
const ErrorIcon = styled(Icon)`
  color: ${(props) => props.theme.danger.main};
  font-size: 22px;
`;

const Error = styled.div`
  position: relative;
  margin-bottom: 10px;
  color: ${(props) => props.theme.danger.main};
  font-size: 16px;
`;

export { TextField, FormGroup };
