
import { getApi } from "./resonanceApi";
import { AxiosResponse } from 'axios';

export enum urls {
  GET_CONFIG = 'studies/{study_id}/configs',
}

export const getConfig = async (study_id: number): Promise<AxiosResponse> => {
  const url = urls.GET_CONFIG;
  return getApi(url, { study_id });
};
