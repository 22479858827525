import { IonApp } from "@ionic/react";
import "./i18n";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./App.scss";
import "./theme/variables.scss";
import "./theme/buttons.scss";
import "./theme/common.scss";
import "./theme/modal.scss";
import "./theme/list.scss";
import "./theme/card.scss";
import "./theme/label.scss";
import "./theme/content.scss";
import "./theme/select.scss";
import { useEffect } from "react";
import ResonanceStore from "./storage/resonanceStorage";
import { AuthProvider } from "./contexts/AuthContext";
import Theme from "./contexts/ThemeContext";
import Navigator from "./navigation/Navigator";

import { ConfigProvider } from "./contexts/ConfigContext";
import QueryProvider from "./contexts/QueryProvider";
import { configLocales } from "./helper/locales";
import TabAccessibility from "./Accessibility";

configLocales();

const App: React.FC = () => {
  useEffect(() => {
    const setupStore = async () => {
      await ResonanceStore.init("RES_STORE");
    };

    setupStore();
  }, []);

  return (
    <ConfigProvider>
      <QueryProvider>
        <IonApp>
          <AuthProvider>
            <Theme>
              <Navigator />
            </Theme>
          </AuthProvider>

          <TabAccessibility />
        </IonApp>
      </QueryProvider>
    </ConfigProvider>
  );
};

export default App;
