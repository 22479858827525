import FuzzySet from "fuzzyset";
import { SpeechRecognition } from "@ionic-native/speech-recognition";
import { TextToSpeech } from "@capacitor-community/text-to-speech";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LangEnum } from "../types/Common";
import { isPlatform } from "@ionic/react";

interface VocalisationResult {
  word: string,
  result: [number, string][] | null,
}

const langToLocale: Record<string, string> = {
  en: 'en-EN',
  fr: 'fr-FR',
  es: 'es-ES',
  ar: 'ar-SA',
  be: 'be-BY',
  bg: 'bg-BG',
  bs: 'bs-BA',
  cs: 'cs-CZ',
  da: 'da-DK',
  de: 'de-DE',
  hu: 'hu-HU',
  it: 'it-IT',
  ja: 'ja-JP',
  pl: 'pl-PL',
  ro: 'ro-RO',
};

function splitBySentences(txt: string) {
  return txt.split(/,|\./).filter(l => l)
}

function ExtractTranscript(phrases, results) {
  for (let result in results[0]) {
    const found = phrases.find(phrase => phrase?.toLowerCase() === results[0][result].transcript?.toLowerCase());
    return found || results[0][0].transcript;
  }
}

const useVocalisation = (possibleValues?: string[], setValue?: (value: string) => void) => {
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const fuzzy = FuzzySet();
  const { i18n: { language } } = useTranslation();
  const [isReading, setIsReading] = useState(false);
  const [isListening, setIsListening] = useState(false);

  const locale = langToLocale[language as LangEnum];

  const stop = async () => {
    await TextToSpeech.stop();
  };

  const checkAvailable = async (): Promise<void> => {
    try {
      setIsAvailable(true);
      if (isPlatform('cordova'))
        await SpeechRecognition.isRecognitionAvailable()
    } catch (e) {
      setIsAvailable(false);
    }
  }

  useEffect(() => {
    checkAvailable();
    
    // since the app is registered PWA , it keep running in background, so 
    // we have stop text speech after reload or quit
    return () => {
      stop()
    };
  }, [])

  useEffect(() => possibleValues?.forEach((label: string) => fuzzy.add(label)), [possibleValues, fuzzy]);


  const readText = async (textToRead: string): Promise<void> => {
    try {
      setIsReading(true);
      const parts = splitBySentences(textToRead)
      
      for (let i = 0; i < parts.length; i++) {
        await TextToSpeech.speak({
          text: parts[i],
          lang: locale,
          rate: 0.8
        });
      }

    } catch (error) {
    } finally {
      setIsReading(false)
    }
  }


  const startListnening = async () => {
    try {
      setIsListening(true);
      console.log("is cordova: ", isPlatform('cordova'))
      console.log("is capacitor: ", isPlatform('capacitor'))
      console.log("is hybrid: ", isPlatform('hybrid'))
      console.log("is mobile: ", isPlatform('mobile'))
      console.log("is mobileweb: ", isPlatform('mobileweb'))


      if (isPlatform('cordova')) {

        await SpeechRecognition.isRecognitionAvailable();
        const hasPermission = await SpeechRecognition.hasPermission();

        if (!hasPermission) {
          await SpeechRecognition.requestPermission();
        }

        SpeechRecognition.startListening({
          language: locale,
          prompt: 'Ecoute en cours..',
          showPopup: true
        }).subscribe((text: string[]) => {
          const arrayResult: VocalisationResult[] = text.map((t: string): VocalisationResult => ({ word: t, result: fuzzy.values().length > 0 ? fuzzy.get(t) : [[0, t]] }));
          const [firstResult] = arrayResult;
          if (firstResult?.result && firstResult.result[0]) {
            setValue(firstResult.result[0][1]);
          }
          setIsListening(false);
        });
      } else {
        // @ts-expect-error
        const SpeechRecog = window.SpeechRecognition || webkitSpeechRecognition;
        const recognition = new SpeechRecog();

        let grammar = `#JSGF V1.0; grammar options; public <option> = ${possibleValues?.join(' | ')};`
        
        // @ts-expect-error
        if (typeof window.SpeechGrammarList !== 'undefined'|| typeof webkitSpeechGrammarList !== 'undefined') {
          // @ts-expect-error
          const SpeechGrammarList = window.SpeechGrammarList || webkitSpeechGrammarList;
          const speechRecognitionList = new SpeechGrammarList();
          speechRecognitionList.addFromString(grammar, 1);
          grammar = speechRecognitionList;
        } 


        recognition.grammars = grammar;

        recognition.continuous = false;
        recognition.lang = locale;
        recognition.interimResults = false;
        recognition.maxAlternatives = 10;

        recognition.onresult = (event) => {
          const val = ExtractTranscript(possibleValues, event.results);
          console.log(event);
          console.log('possibleValues', possibleValues);
          console.log('Speech recognition results', [...event.results[0]].map(result => result.transcript));
          console.log('returned value', val);
          setValue(val);
          setIsListening(false);
        }

        recognition.onspeechend = () => {
          recognition.stop();
          setIsListening(false);
        }

        recognition.onnomatch = (event) => {
          console.log("speech recognition - no match");
        }

        recognition.onerror = (event) => {
          console.log("speech recognition - error occured");
          setIsListening(false);
          console.log(event);
        }

        recognition.start();

      }

    } catch (error) {
      console.log(error);
      setIsListening(false);
      alert("Permissions required !")
    }
  }

  const stopReading = () => {
    setIsReading(false);
    stop();
  }

  return {
    isAvailable,
    startListnening,
    readText,
    isReading,
    stopReading,
    isListening,
  }
};

export default useVocalisation;
