
import { getApi } from "./resonanceApi";
import { AxiosResponse } from 'axios';
import { RoleKeyEnum, WelcomeType } from "../types/welcome";
import { ProfileType } from "../types/profile";
import { RoleEnum } from "../types/invitation.types";

export enum CommonApiUrlsEnum {
  GET_WELCOME_INFOS = 'studies/welcome/{slug}'
}

export const getWelcomeInfo = async ({ slug, lang, invitation_token }: { slug: string, lang: string, invitation_token?: string }): Promise<AxiosResponse<WelcomeType>> => {
  const url = CommonApiUrlsEnum.GET_WELCOME_INFOS;
  const response = await getApi<WelcomeType>(url, { slug }, false, {
    lang, invitation_token
  });

  const roles: ProfileType[] = [
    { ...response.data.roles[RoleKeyEnum.SUBJECT], name: RoleEnum.SUBJECT },
    { ...response.data.roles[RoleKeyEnum.INVESTIGATOR], name: RoleEnum.INVESTIGATOR },
    ...response.data.roles[RoleKeyEnum.INTERVENORS].map(role => ({ ...role, name: RoleEnum.INTERVENOR }))
  ]

  return {
    ...response,
    data: {
      ...response.data,
      roles
    }
  }
}
