import { resonance } from './resonance.ar';
import { lang } from './lang.ar';
import { menu } from './menu.ar';
import { layout } from './layout.ar';
import { common } from './common.ar';
import { invite } from './invite.ar';
import { answer } from './answer.ar';
import { home } from './home.ar';
import { profile } from './profile.ar';
import { changePassword } from './change-password.ar';
import finished_questionnaires from './finished_questionnaires.ar';
import { formError } from './formError.ar';
import { questionnaire } from './questionnaire.ar';
import { forgotPassword } from './forgot-password.ar';
import { sequence } from './sequences.ar';
import { gift } from './gift.ar';
import { onboarding } from './onboarding.ar';
import { consent } from './consent.ar';

export const resonanceTradAr = {
  ...resonance,
  ...answer,
  ...menu,
  ...layout,
  ...invite,
  ...common,
  ...lang,
  ...home,
  ...profile,
  ...changePassword,
  ...forgotPassword,
  ...finished_questionnaires,
  ...formError,
  ...questionnaire,
  ...sequence,
  ...onboarding,
  ...gift,
  ...consent
};
