import { IonCheckbox, IonModal } from "@ionic/react";
import { FC, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import styled, { css, keyframes } from "styled-components";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";
import { arrowForward, toggle, volumeHigh } from "ionicons/icons";

const audioUrl = '/assets/storytelling/storytelling-speech.wav'; 
interface Props {
    isOpen: boolean;
    onDismiss(): void;
    animate?(): void;
  }

  
  type TextProps = {
    phrase: string;
  }

const StoryTelling: FC<Props> = ({ isOpen, onDismiss, animate }) => {
    const { t } = useTranslation();

    const [index, setIndex] = useState(0);
    const [volume, setVolume] = useState(1);
    const audioRef = useRef(new Audio(audioUrl));
    const [animationStartTime, setAnimationStartTime] = useState(Date.now());

    useEffect(() => {
      const delayPerCharacter = 55;

      const currentPhraseLength = phrases[index].length;
      const delay = currentPhraseLength * delayPerCharacter;
        const timer = setTimeout(() => {
          if(index < phrases.length - 1){
            setIndex((prevIndex) => (prevIndex+1) % phrases.length);
          }
        }, delay);
        return () => clearTimeout(timer);

    },[index]);

    const phrases = [
      "Aide les chercheurs à accéder au sommet de la connaissance sur le bien-être mental des jeunes pour changer ton avenir et celui de ceux qui en ont besoin",
      "Gravis la montagne en 8 points.", // Animation : Bonhomme qui gravis la montagne
      "Révèle l'environnement en répondant à des questionnaires optionnels.", // Animation : Elements de décors qui s'ajoutent
      "Si tu ne parviens pas au sommet pas de soucis, grâce à toi les chercheurs seront déjà allés plus loin !",
      "Deviens un de nos ambassadeurs et invite tes amis à participer.", //Animation : bonhomme qui lève le drapeau
  ]

  const playAtSpecificTime = () => {

    if(!audioRef.current.paused) {
      const newVolume = volume === 0 ? 1 : 0;
      setVolume(newVolume);
      console.log(volume);
      audioRef.current.volume = newVolume;
    }

    if (audioRef.current) {
      const elapsedTimeMs = Date.now() - animationStartTime;
      
      audioRef.current.currentTime = elapsedTimeMs/1000;
      audioRef.current.play();
    }
  };

  const ModalClose = () => {

    if(audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
    }
  };

  return (
<IonModal
      cssClass="resonance-modal"
      isOpen={isOpen}
      backdropDismiss={true}
      onWillDismiss={ModalClose}
    >
      <Container>
        <IconWrapper>
            <Icon icon={volumeHigh} onClick={playAtSpecificTime} style={{fontSize : '40px'}}></Icon>
          <TypingText>
            <Text key={index} phrase={phrases[index]}>
              {phrases[index]}
            </Text>
          </TypingText>
          </IconWrapper>
          <Buttons>
            <ModalButton onClick={animate}>Passer<Icon icon={arrowForward} /></ModalButton>
          </Buttons>
      </Container>
    </IonModal>
  );
};

const typing = keyframes`
  from { background-size: 0 200%; }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction : row;
`

const Text = styled.span<TextProps>`
  --n: ${props => props.phrase.length};
  font-family: monospace;
  color: transparent;
  font-weight : bolder;
  background:
    linear-gradient(-90deg, black 0px, transparent 0) 10px 0,
    linear-gradient(black 0 0) 0 0,
    linear-gradient(0deg, #fecf48 100%, transparent 0) 0 0;
  background-size: calc(var(--n) * 1ch) 200%;
  background-clip: padding-box, text;
  background-repeat: no-repeat;
  animation:   
    ${typing} calc(var(--n) * .05s) steps(var(--n)) forwards;
`;

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: auto;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
`;

const TypingText = styled.div`
  color: #000;
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 20px;
  margin-left: 10px;
  width: 100%;
  overflow: hidden;
  text-align: center;
`;


const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  width : calc(100% - 40px);
  z-index: 10;
`;

const ModalButton = styled(Button)`
  background-color: ${(props) => props.theme.secondary.main};
  border-radius: 30px;
  padding-right: 10px;
  margin-left-auto : auto;
  @media (min-width: 800px) {
    border-radius: 30px 30px 30px 30px;
    padding-left: 20px;
    margin-right: 20px;
  }
`;


export default StoryTelling;
