import { ReactElement } from "react";
import {
  IonRadioGroup,
  IonLabel,
  IonItem,
  IonRadio,
  IonButton,
  IonIcon,
} from "@ionic/react";
import {
  RadioQuestionType,
  CommonAnswersType,
} from "../../../../types/Question";
import { micOutline, volumeHighOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import useVocalisation from "../../../../hooks/useVocalisation";
import { useConfig } from "../../../../contexts/ConfigContext";

interface AnswerRadioProps {
  question: RadioQuestionType;
  value?: string;
  onChange: (value: string) => void;
}

const AnswerRadio = ({
  question,
  value = "",
  onChange,
}: AnswerRadioProps): ReactElement => {
  const { t } = useTranslation();
  const {
    detail: { answers },
  } = question;
  const lastAnswerIndex = answers.length - 1;

  const { selectedRole } = useConfig();
  const isVocalisationEnabled =
    selectedRole?.attributes?.is_vocalisation_enabled;

  const { startListnening, readText, isListening } = useVocalisation(
    answers.map((a) => a.label),
    (value) =>
      onChange(answers.find((answer) => answer.label === value)?.id as any)
  );
  const isSpeechRecEnabled =
    selectedRole?.attributes?.is_speech_recognition_enabled;

  const renderItem = (
    { label, id }: CommonAnswersType,
    index: number
  ): ReactElement => {
    return (
      <IonItem key={id} lines={lastAnswerIndex === index ? "none" : "full"} mode="md">
        <IonRadio slot="start" value={id} />
        <IonLabel>
          <p className="answer-with-scroll">{label}</p>
        </IonLabel>

        {isVocalisationEnabled && (
          <IonButton
            slot="end"
            fill="clear"
            onClick={(): void => {
              readText(label);
            }}
          >
            <IonIcon slot="icon-only" icon={volumeHighOutline} />
          </IonButton>
        )}
      </IonItem>
    );
  };

  return (
    <>
      <IonRadioGroup
        value={value}
        onIonChange={(e: CustomEvent): void => onChange(e.detail.value)}
        allowEmptySelection
      >
        <div style={{ overflow: 'auto', maxHeight: '300px' }} >
          {answers.map(renderItem)}
        </div>
      </IonRadioGroup>
      {isSpeechRecEnabled && (
        <IonItem lines="full" mode="md">
          <IonLabel>
            <p>{t("common_click_to_speak")}</p>
          </IonLabel>

          <IonButton onClick={startListnening} slot="end" fill="clear">
            {isListening ? (
              <IonIcon slot="icon-only" src="/assets/icon/loading.svg" />
            ) : (
              <IonIcon slot="icon-only" icon={micOutline} />
            )}
          </IonButton>
        </IonItem>
      )}
    </>
  );
};

export default AnswerRadio;
