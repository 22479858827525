import { IonModal } from "@ionic/react";
import { useFormik } from "formik";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TextField } from "../../components/atoms/LoginTextField";
import * as Yup from "yup";
import { resetPassword } from "../../services/user.service";
import { useConfig } from "../../contexts/ConfigContext";
import Icon from "../../components/atoms/Icon";
import { alertCircle } from "ionicons/icons";
import useToast from "../../hooks/useToast";

interface Props {
  isOpen: boolean;
  onDismiss(): void;
  reset_password_token: string;
  login: string;
  channel: string;
}

interface resetPasswordFormType {
  newPassword: string;
  confirmPassword: string;
  login: string;
}

// const resetPasswordSchema = Yup.object().shape({
//     login: Yup.string().email().required(),
//     newPassword: Yup.string()
//       .matches(
//         /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])(?!.*\s).{8,100}$/,
//         "form_error_email_password"
//       )
//       .required(),
//     confirmPassword: Yup.string()
// });


const frenchMobileRegex = /^(06|07)[0-9]{8}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const resetPasswordSchema = Yup.object().shape({
  login: Yup.string()
    .required('Login is required')
    .test('is-valid-login', 'Invalid email or phone number', function(value) {
      return emailRegex.test(value) || frenchMobileRegex.test(value);
    }),
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9])(?!.*\s).{8,100}$/,
      "form_error_email_password"
    )
    .required('New password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

const ResetPwdModal: FC<Props> = ({ isOpen, onDismiss, reset_password_token, login, channel}) => {
  const { t } = useTranslation();
  const { study } = useConfig();
  const [isError, setIsError] = useState(false);
  const present = useToast();

  const handleSubmit = async (values: resetPasswordFormType) => {
    try {
      if (values.newPassword !== values.confirmPassword) setIsError(true);
      else {
      setIsError(false); 
        await resetPassword(
              {
              studyId: study.id,
              },
              {
                login: values.login,
                reset_password_token: reset_password_token,
                password: values.newPassword,
                password_confirmation: values.confirmPassword,

              }
        );
        onDismiss();
      }
    } catch (e) {
      setIsError(true);
      present(e?.response?.data?.message);
    }
  };

  const { values, touched, errors, submitForm, setFieldValue } =
    useFormik<resetPasswordFormType>({
      initialValues: {
        login: login || "",
        newPassword: "",
        confirmPassword: "",
      },
      onSubmit: handleSubmit,
      validationSchema: resetPasswordSchema,
    });

  

  return (
    <IonModal isOpen={isOpen} backdropDismiss={true} onWillDismiss={onDismiss}>
      <Container onClick={onDismiss}>
      <Card onClick={(e) => e.stopPropagation()}>
        <Title>{t("change_pwd_title")}</Title>

         <TextField
            value={values.login}
            type="text"
            disabled={true}
            error={touched.login && errors.login}
            onChange={(value) => setFieldValue("login", value)}
            label={channel === 'email' ? t("forgot_pwd_email") : t("forgot_pwd_phone")}
            placeholder={channel === 'email' ? t("forgot_pwd_email") : t("forgot_pwd_phone")}
          />

         <TextField
            value={values.newPassword}
            type="password"
            // error={errors.newPassword}
            onChange={(value) => setFieldValue("newPassword", value)}
            label={t("change_pwd_new_password")}
            placeholder={t("change_pwd_new_password")}
          />

          <TextField
            value={values.confirmPassword}
            type="password"
            // error={errors.confirmPassword}
            onChange={(value) => setFieldValue("confirmPassword", value)}
            label={t("change_pwd_confirm_password")}
            placeholder={t("change_pwd_confirm_password")}
          />

        {isError && (
          <Error>
            <Icon icon={alertCircle} />
            {t("forgot_pwd_error")}
          </Error>
        )}
        <Buttons>
          <ModalButton onClick={onDismiss}>{t("common_cancel")}</ModalButton>
          <ModalButtonSubmit onClick={submitForm}> {t("reset_pwd_submit")}</ModalButtonSubmit>
        </Buttons>
        </Card>
      </Container>
    </IonModal>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Error = styled.div`
  color: red;
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 15px;
`;

const Card = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const ModalButton = styled.div`
  padding: 10px 20px;
  color: ${(props) => props.theme.primary.main};
  cursor: pointer;
`;

const ModalButtonSubmit = styled(ModalButton)`
  background-color: ${(props) => props.theme.primary.background};
  color: ${(props) => props.theme.primary.contrast};
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
`;

export default ResetPwdModal;
