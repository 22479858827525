import styled from "styled-components";

const FormButton = styled.div`
font-size: 16px;
background-color: ${props => props.theme.secondary.main};
color: ${props => props.theme.secondary.contrast};
padding: 10px;
display: block;
cursor: pointer;
margin: 0px 100px;
border-radius: 4px;
text-align: center;
font-weight: bold;
box-shadow: 0px 0px 25px -12px #000000;
border-radius:30px;
`

export default FormButton;