import { ReactElement } from "react";
import {
  IonTextarea,
  IonItem,
  IonInput,
  IonIcon,
  IonButton,
} from "@ionic/react";
import {
  TextQuestionType,
  QuestionTextTypeEnum,
} from "../../../../types/Question";
import { micOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useConfig } from "../../../../contexts/ConfigContext";
import useVocalisation from "../../../../hooks/useVocalisation";

interface AnswerTextProps {
  question: TextQuestionType;
  value?: string;
  onChange: (value: string) => void;
}

const AnswerText = ({
  question,
  value = "",
  onChange,
}: AnswerTextProps): ReactElement => {
  const { detail } = question;
  const { type } = detail;
  const { t } = useTranslation();
  const { selectedRole } = useConfig();
  const isSpeechRecEnabled =
    selectedRole?.attributes?.is_speech_recognition_enabled;

  const { startListnening, isListening } = useVocalisation([], onChange);

  return (
    <IonItem lines="full">
      {type === QuestionTextTypeEnum.PARAGRAPH && (
        <IonTextarea
          className="res-text-input"
          rows={6}
          value={value}
          onIonChange={(e: CustomEvent): void => onChange(e.detail.value)}
          placeholder={t("answer_text_placeholder")}
        />
      )}
      {type === QuestionTextTypeEnum.EMAIL && (
        <IonInput
          className="res-text-input"
          value={value}
          onIonChange={(e: CustomEvent): void => onChange(e.detail.value)}
          type="email"
          inputmode="email"
          pattern="email"
          placeholder={t("answer_text_placeholderEmail")}
        />
      )}
      {type === QuestionTextTypeEnum.LINE && (
        <IonInput
          className="res-text-input"
          value={value}
          onIonChange={(e: CustomEvent): void => onChange(e.detail.value)}
          placeholder={t("answer_text_placeholder")}
        />
      )}

      {isSpeechRecEnabled && (
        <IonButton slot="end" fill="clear" onClick={startListnening}>
          {isListening ? (
            <IonIcon slot="icon-only" src="/assets/icon/loading.svg" />
          ) : (
            <IonIcon slot="icon-only" icon={micOutline} />
          )}
        </IonButton>
      )}
    </IonItem>
  );
};

export default AnswerText;
