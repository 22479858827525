//بقلم Synakene

export const onboarding = {
  onboarding_title: "Mentalo",
  onboarding_subtitle: 'مهمتنا',
  onboarding_header_text: 'المعرفة حول الرفاهية العقلية للشباب.',
  onboarding_header_text2: "لمساعدتنا، لمساعدة نفسك، ولمساعدة الآخرين، ستحتاج إلى:",
  onboarding_step1: 'الخطوة 1',
  onboarding_step1_text: 'تحدث عن نفسك',
  onboarding_step2: 'الخطوة 2',
  onboarding_step2_text: "تقديم التحديثات 7 مرات في السنة في 5 دقائق",
  onboarding_step3: 'الخطوة 3',
  onboarding_step3_text: 'تتبع تقدمك',
  onboarding_step4: 'الخطوة 4',
  onboarding_step4_text: 'كن سفيرًا بدعوة أصدقائك',
};
