export const invite = {
  invite_title: 'Invitaciones',
  // Ajout Synakene
  invite_subtitle: 'Invita a tus amigos a participar',
  invite_thank_you_participation: '¡Gracias por tu participación!',
  invite_friends_to_help: 'Puedes ayudarnos aún más invitando a amigos.',
  invite_later: 'Más tarde',
  invite_friends: 'Invito a mis amigos',
  // invite_subtitle: 'Selecciona un canal para enviar una invitación',
  invite_modal_mail: 'Enviar una invitación por correo electrónico',
  invite_modal_EMAIL: 'Enviar una invitación por correo electrónico',
  invite_modal_sms: 'Enviar una invitación por SMS',
  invite_modal_name_placeholder: 'Ej: Juan',
  invite_modal_surname_placeholder: 'Ej: Pérez',
  invite_modal_email_placeholder: 'Ej: Juan.Perez@email.com',
  invite_modal_lang_placeholder: 'Elegir',
  invite_modal_phone_placeholder: 'Ej: 0123456789',
  invite_copy_notification: 'Copiado al portapapeles',
  invite_empty_config: 'No hay opciones de invitación disponibles',
  invite_channel_title: '¿Cómo prefieres ser invitado?',
  invite_EMAIL: 'Correo electrónico',
  invite_SMS: 'SMS',
  invite_PUBLIC_LINK: 'Copiar el enlace',
  invite_inclusion_direct: 'Inclusión directa'
};
