export const consent = {
  consent_subtitle_1_1: "DOCUMENT D'INFORMATION A DESTINATION DES 11-17 ANS",
  consent_subtitle_1_2: "DOCUMENT D'INFORMATION A DESTINATION DES 18-24 ANS",
  consent_subtitle_1_3: "DOCUMENT D'INFORMATION A DESTINATION DES PARENTS DES 11-17 ANS",
  consent_subtitle_2: "Bien-être mental des adolescents et jeunes adultes : réalités et évolutions (Mentalo)",
  consent_paragraph_1: "Le responsable scientifique de la recherche est la Professeure Karine CHEVREUL, directrice de l’unité ECEVE de l’Institut national de la santé et de la recherche médicale (Inserm) et d’Université Paris Cité. Elle est également responsable de la mise en œuvre du traitement des données.",
  consent_paragraph_2: "Le responsable de traitement des données est l’unité ECEVE – UMR 1123 INFORMATION SUR LA RECHERCHE A L’ATTENTION DES PARTICIPANTS DE 11 A 17 ANS",
  consent_paragraph_3_1: "Bonjour,",
  consent_paragraph_3_2: "Tu es invité.e à participer à l’étude intitulée « Bien-être mental des adolescents et jeunes adultes : réalités et évolutions » (Mentalo), qui s’intéresse au bien-être mental des 11 – 24 ans et qui est dirigée par la Professeure Karine CHEVREUL.",
  consent_paragraph_4: "Ce document a pour but de t’informer sur l’étude et son déroulement pour t’expliquer en quoi consiste ta participation. Tu es libre d’y participer ou non. Si tu ne veux pas participer, tu n’as pas à te justifier. Avant de te décider, tu peux en parler à un proche ou avec toute personne avec qui tu te sens à l'aise.",
  consent_paragraph_5: "Si tu acceptes de participer à cette étude, une note d’information destinée à tes parents est également disponible afin qu’ils puissent prendre connaissance de cette étude et de s’assurer qu’ils ne s’opposent pas au traitement de tes données.",
  consent_paragraph_6: "N’hésite pas à poser des questions à tout moment par e-mail en contactant les chercheurs responsables de l’étude mentalo@inserm.fr.",
  consent_paragraph_7: "Tu peux à tout moment changer d’avis et demander à ne plus participer à cette étude. Nous te demanderons simplement d’en informer les chercheurs par email mentalo@inserm.fr.",

  consent_subtitle_3: "1. CONTEXTE, OBJECTIFS ET JUSTIFICATION",

  consent_paragraph_8: "La pandémie de COVID-19 et les mesures sanitaires qui ont suivi comme les confinements ont eu un fort impact sur le bien-être mental de l’ensemble de la population et plus particulièrement chez les plus jeunes. Les dernières études qui se sont penchées sur le sujet montrent une dégradation de l’état de bien-être mental des adolescents et des jeunes adultes.",
  consent_paragraph_9: "L’étude Mentalo a été mise en place pour mieux comprendre la situation. Son objectif est d’étudier le bien-être mental des jeunes, son évolution sur un an, et les facteurs qui pourraient l’influencer.",
  consent_paragraph_10: "Elle s’adresse à des jeunes de 11 à 24 ans vivant en France et prévoit d’interroger 50 000 jeunes.",

  consent_subtitle_4: "2. DEROULEMENT DE LA RECHERCHE",

  consent_paragraph_11: "L’étude se déroule en ligne, via un questionnaire. Tu seras interrogé.e à différents moments tout au long de l’année d’étude, lors de ton inscription puis 15 jours après, puis 1, 2, 3, 6, 9 et 12 mois après.",
  consent_paragraph_12: "Cela ne te prendra pas plus de 10 minutes à chaque fois.",
  consent_paragraph_13: "Après avoir accepté de participer, on te demandera de te créer un compte sur le site où se trouve le questionnaire, avec un login (pseudonyme ou e-mail) et un mot de passe. On te demandera aussi d’indiquer un numéro de téléphone ou un e-mail pour t’avertir quand de nouveaux questionnaires seront disponibles. L’envoi de ces SMS ou e-mails sera automatique et les chercheurs n’auront pas accès à ces informations.",
  consent_paragraph_14: "Une fois que ton compte sera créé, tu pourras répondre au questionnaire de l’étude. Il est composé de modules obligatoires et de modules optionnels. Au moment de ton inscription, les modules obligatoires portent sur ton bien-être mental (comment tu vas, qu’est-ce qui te préoccupe…) et quelques questions sur tes caractéristiques (ton âge, si tu es collégien ou lycéen…).",
  consent_paragraph_15: "Lors du suivi, le module de suivi obligatoire portera sur ton bien-être mental, pour savoir comment tu vas par rapport à la dernière fois, s’il y a eu des changements et pourquoi.",
  consent_paragraph_16: "Dès ton inscription, tu auras aussi accès à des modules optionnels, que tu peux choisir de remplir ou non. Ils portent sur ta vie familiale, sociale, affective, scolaire ou professionnelle. Tu pourras répondre à ces modules à n’importe quel moment pendant l’année d’étude.",
  consent_paragraph_17: "Afin de te remercier pour ta participation, tu gagneras des points à chaque fois que tu termineras un module, obligatoire ou non. Tes points te permettront d’accéder à des cadeaux à partir du moment où tu arriveras à un certain seuil. Tu pourras choisir ton cadeau parmi ceux qui sont toujours disponibles et qui correspondent à ton nombre de points. Plus tu progresses dans l’étude, plus la valeur des cadeaux augmente.",
  consent_paragraph_18: "Tu pourras aussi inviter d’autres jeunes (amis, famille…) à participer à l’étude grâce à un lien qui leur sera envoyé.",
  consent_paragraph_19: "Toutes les informations sur l’étude sont présentes sur le site https://etude-mentalo.fr/.",
  consent_paragraph_20: "Ta participation nous permettra de mieux connaître le bien-être mental des jeunes en France.",
  consent_paragraph_21: "La durée de ta participation est de : 1 an",
  consent_paragraph_22: "La durée totale de la recherche est de 3 ans.",

  consent_subtitle_5: "3. CONFIDENTIALITE ET TRAITEMENT DES DONNEES",
  consent_paragraph_23: "Dans le cadre de ta participation à cette recherche, un traitement de tes données personnelles va être mis en œuvre pour permettre d’en analyser les résultats. Ce traitement poursuit une finalité de recherche scientifique et a pour base légale l’exécution d’une mission d’intérêt public.",
  consent_paragraph_24: "Les données que tu auras renseignées dans les questionnaires seront confidentielles et seuls les chercheurs travaillant sur l’étude y auront accès. Il ne sera pas possible pour les chercheurs de t’identifier. Tes identifiants de connexion, numéro de téléphone ou e-mail ne seront jamais communiqués aux chercheurs.",
  consent_paragraph_25: "Tes données seront conservées de façon sécurisée jusqu’à la fin de l’analyse soit pendant une durée maximale de 3 années. Ensuite, elles seront anonymisées. Tes données personnelles ne seront donc pas archivées à l’issue de cette période.",

  consent_paragraph_26: "Conformément à la loi, tu as le droit :",
  consent_paragraph_27: "d’accéder aux données que tu as renseignées pendant la recherche et de demander leur effacement ou leur modification.",
  consent_paragraph_28: "de t’opposer à tout moment à ce que tes données continuent d’être collectées.",
  consent_paragraph_29: "d’empêcher de façon temporaire l’utilisation de tes données.",
  consent_paragraph_30: "Ces droits ne pourront pas s’exercer s’ils sont susceptibles de rendre impossible ou de compromettre gravement la réalisation des objectifs de la recherche.",
  consent_paragraph_31: "Si tu souhaites exercer ces droits, tu pourras t’adresser à l’équipe de recherche mentalo@inserm.fr. Une réponse te sera apportée dans les plus brefs délais, et au plus tard un mois à compter de la réception de ta demande. En cas de difficulté(s), tu peux également, par l’intermédiaire de tes parents, contacter la déléguée de protection des données (DPD) de l'université Paris Cité.",
  consent_paragraph_32: "Si tu estimes, après nous avoir contactés, que tes droits « Informatique et Libertés » ne sont pas respectés, tes parents pourront adresser une réclamation à la CNIL (www.cnil.fr).",
  consent_paragraph_33: "Si tu décides d’arrêter de participer à la recherche, les données recueillies jusque-là seront utilisées conformément à la loi. Dans ce cas, tes données ne seront pas utilisées ultérieurement, ni pour une autre recherche.",
  consent_paragraph_34: "Tu as également le droit au respect de ta vie privée et à la confidentialité de tes données. Si tu as quinze ans ou plus, tu as le droit de refuser que tes parents/tuteurs légaux aient accès aux données te concernant recueillies au cours de l'étude. Si tu souhaites exercer ce droit, tu pourras t’adresser à l’équipe de recherche (mentalo@inserm.fr).",

  consent_subtitle_6: "4. INFORMATION DES RESULTATS GLOBAUX",

  consent_paragraph_35: "Tu auras accès aux résultats globaux de l’étude sur le site de l’étude https://etude-mentalo.fr/.",
  consent_paragraph_36: "Les résultats de la recherche pourront aussi être présentés à des congrès, dans des publications scientifiques ou dans les médias grand public.",

  consent_subtitle_7: "5. RE-UTILISATION DES DONNEES DANS LE CADRE DE NOUVELLES RECHERCHES",

  consent_paragraph_37: "Les données que tu as renseignées dans les questionnaires pourraient être réutilisées dans d’autres études sur le bien-être mental des adolescents, mais elles ne permettront jamais de t’identifier.",
  consent_paragraph_38: "Tu as le droit d’obtenir des informations complémentaires sur ces études, auprès du responsable de l’étude.",
  consent_paragraph_39: "Dans tous les cas, toi et tes tuteurs légaux avez le droit de refuser que tes données soient réutilisées dans d’autres études.",

  consent_subtitle_8: "6. DISPOSITIONS LEGISLATIVES ET REGLEMENTAIRES",

  consent_paragraph_40: "Cette recherche a obtenu l’avis favorable du Comité d’Évaluation Éthique de l’Inserm (CEEI/IRB) le 6 juin 2023 (avis n°23-1018).",
  consent_paragraph_41: "En bref...",
  consent_paragraph_42: "L’étude porte sur le bien-être mental des jeunes de 11 à 24 ans vivant en France.",
  consent_paragraph_43: "Elle nécessite le remplissage de questionnaires en ligne plusieurs fois par an.",
  consent_paragraph_44: "Le temps de réponse sera de moins de 10 minutes à chaque fois.",
  consent_paragraph_45: "Ta participation à l’étude est volontaire et libre.",
  consent_paragraph_46: "Tes données sont traitées de façon absolument confidentielle.",
  consent_paragraph_47: "Tu es libre de décider si tu souhaites ou non participer à la recherche.",
  consent_paragraph_48: "Comme tu as moins de 18 ans, tu devras partager avec tes parents/tuteurs légaux les informations sur l’étude et son organisation qui sont contenues dans la lettre d’information qui leur est destinée.",
  consent_paragraph_49: "Toi et tes parents/tuteurs légaux, vous avez le droit de vous opposer au traitement de tes données personnelles.",
  consent_paragraph_50: "Après en avoir parlé avec eux, nous te demandons de confirmer ta participation avant de commencer le questionnaire.",
  consent_paragraph_51: "En poursuivant l’inscription, je certifie être âgé.e entre 11 et 17 ans.",
  consent_paragraph_52: "En poursuivant l’inscription, je confirme avoir communiqué à mes parents/tuteurs légaux la lettre d’information qui leur est destinée et que ni moi, ni eux, ne nous opposons au traitement de mes données personnelles.",
  consent_paragraph_53: "En poursuivant l’inscription, je confirme que ni moi, ni mes parents/tuteurs légaux, ne nous opposons à la réutilisation de mes données dans le cadre de recherches ultérieures en respectant la confidentialité de mon identité.",

  // 2EME VERSION DU TEXTE 

  consent_paragraph_54: "Conformément aux dispositions de la loi n°78-17 relative à l’informatique, aux fichiers et aux libertés et au Règlement Général sur la Protection des Données (Règlement (UE) 2016/679), vous disposez des droits suivants :",
  consent_paragraph_55: "Droit d'accès aux informations vous concernant, afin d’en vérifier l’exactitude et, le cas échéant, afin de les rectifier, de les compléter, de les mettre à jour.",
  consent_paragraph_56: "Droit d'opposition : droit de vous opposer à tout moment, à la transmission de vos données et d'obtenir que vos données ne soient plus collectées pour l'avenir. L’exercice de ce droit entraîne l’arrêt de votre participation à la recherche.",
  consent_paragraph_57: "Droit à la limitation du traitement des données : droit de bloquer temporairement l’utilisation de ses données : aucune opération ne pourra être réalisée sur celles-ci.",
  consent_paragraph_58: "Vous aurez la possibilité d'exercer vos droits pendant 3 ans après la fin de la recherche. En effet, les données d'identification seront supprimées à la fin de cette période.",

  consent_paragraph_59: "Si vous souhaitez exercer ces droits, vous devrez vous adresser à l’équipe de recherche mentalo@inserm.fr ou la déléguée de protection des données (DPD) de l'université Paris Cité.",
  consent_paragraph_60: "De plus, vous disposez du droit de faire une réclamation auprès de la Commission Nationale de l’Informatique et des Libertés – CNIL (autorité française de contrôle des données personnelles) via le lien suivant https://www.cnil.fr/fr/cnil-direct/question/adresser-une-reclamation-plainte-la-cnil-quelles-conditions-et-comment ou CNIL - Service des Plaintes - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.",
  consent_paragraph_61: "Si vous décidez d’arrêter de participer à la recherche, les données recueillies précédemment à cet arrêt seront utilisées conformément à la réglementation, et exclusivement pour les objectifs de cette recherche. En effet, leur effacement serait susceptible de compromettre la validité des résultats de la recherche. Dans ce cas, vos données ne seront absolument pas utilisées ultérieurement ou pour une autre recherche.",

  consent_paragraph_62: "Les données que vous avez renseignées dans les questionnaires pourraient être réutilisées dans d’autres recherches portant sur le bien-être mental des adolescents et jeunes adultes, mais elles ne permettront en aucun cas votre identification.",
  consent_paragraph_63: "Vous disposez du droit d'obtenir des informations complémentaires, telles que la finalité de ces nouvelles recherches, auprès de l’équipe de recherche.",
  consent_paragraph_64: "Dans tous les cas, vous disposez du droit de vous opposer à toute réutilisation de vos données dans le cadre de recherches futures.",
  consent_paragraph_65: "Vous êtes libre de décider que vous souhaitez ou non participer à la recherche. Nous vous demandons de confirmer votre participation avant de commencer le questionnaire.",
  consent_paragraph_66: "En poursuivant l’inscription, je certifie être âgé entre 18 et 24 ans.",
  consent_paragraph_67: "En poursuivant l’inscription, je confirme que je ne m’oppose pas au traitement de mes données personnelles.",
  consent_paragraph_68: "En poursuivant l’inscription, je confirme que je ne m’oppose pas à la réutilisation de mes données dans le cadre de recherches ultérieures en respectant la confidentialité de mon identité.",

  // 3EME VERSION DU TEXTE

  consent_paragraph_69: "Les droits de votre enfant :",
  consent_paragraph_70: "Vous aurez la possibilité d'exercer les droits de votre enfant pendant 3 ans après la fin de la recherche. En effet, les données d'identification de votre enfant seront supprimées à la fin de cette période.",
  consent_paragraph_71: "Si vous souhaitez exercer ces droits et obtenir communication de vos informations, vous devez vous adresser à l’équipe de recherche mentalo@inserm.fr ou la déléguée de protection des données (DPD) de l'université Paris Cité.",
  consent_paragraph_72: "De plus, vous disposez du droit d’introduire une réclamation auprès de la Commission Nationale de l’Informatique et des Libertés – CNIL (autorité française de contrôle des données personnelles) via le lien suivant https://www.cnil.fr/fr/cnil-direct/question/adresser-une-reclamation-plainte-la-cnil-quelles-conditions-et-comment ou CNIL - Service des Plaintes - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.",
  consent_paragraph_73: "Si votre enfant décide d’arrêter de participer à la recherche, les données recueillies précédemment à cet arrêt seront utilisées conformément à la réglementation, et exclusivement pour les objectifs de cette recherche. En effet, leur effacement serait susceptible de compromettre la validité des résultats de la recherche. Dans ce cas, ses données ne seront absolument pas utilisées ultérieurement ou pour une autre recherche.",

  consent_paragraph_74: "Les données personnelles de votre enfant renseignées dans les questionnaires pourraient être réutilisées pour des recherches ultérieures sur le bien-être mental des jeunes, mais elles ne permettront en aucun cas son identification. Vous disposez du droit d'obtenir des informations complémentaires, telles que la finalité de ces nouvelles recherches, auprès de l’équipe de recherche.",
  consent_paragraph_75: "Dans tous les cas, vous disposez du droit de vous opposer, à tout moment, à toute réutilisation de données de votre enfant dans le cadre de recherches futures. Vous pourrez pour cela contacter l’équipe de recherche mentalo@inserm.fr.",

  consent_paragraph_76: "La recherche porte sur le bien-être mental des jeunes de 11 à 24 ans vivant en France.",
  consent_paragraph_77: "Elle nécessite le remplissage de questionnaires en ligne plusieurs fois pendant un an.",
  consent_paragraph_78: "Le temps de réponse sera de moins de 10 minutes à chaque fois.",
  consent_paragraph_79: "La participation de votre enfant à cette recherche est volontaire et libre.",
  consent_paragraph_80: "Vous avez le droit de vous opposer au traitement des données personnelles de votre enfant.",
  consent_paragraph_81: "Les données relatives à votre enfant sont traitées de façon absolument confidentielle.",
};
