import { setLocale } from "yup";

export const configLocales = () => setLocale({
  mixed: {
    required: "form_error_required",
    default: "form_default_error",
  },
  string: {
    email: "form_error_email",
    min: ({ min }) => ({ key: "form_error_min", values: { min } }),
  },
});