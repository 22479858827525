import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PostSiteType,
  SiteType,
  SubsiteType,
  WelcompeStepEnum,
} from "../../../../types/welcome";
import { getStudySites } from "../../../../services/study.service";
import ResonanceLoader from "../../../atoms/ResonanceLoader/ResonanceLoader";
import DisplaySitesList from "../../../molecules/Home/DisplaySitesList";
import Container from "../../../atoms/Container";
import NextPrevButtons from "../../../molecules/NextPrevButtons";
import ScreenTitle from "../../../atoms/ScreenTitle";
import { postSite } from "../../../../services/user.service";
import { useFetch } from "../../../../hooks/useFetch";
import Info from "../../../atoms/ErrorComponent";
import useToast from "../../../../hooks/useToast";
import { useHomeContext } from "../../../../pages/Home/HomeContent";
import { useConfig } from "../../../../contexts/ConfigContext";
import useVocalisation from "../../../../hooks/useVocalisation";
import styled from "styled-components";
import { volumeHighOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

const  DisplaySites = (): ReactElement => {
  const { welcomeData, goPrevious, setCurrentStep, previousStep} = useHomeContext();

  const studyId = welcomeData.id;
  const { selectedRole } = useConfig();
  const { readText } = useVocalisation();

  const isVocalisationEnabled =
  selectedRole?.attributes?.is_vocalisation_enabled;

  const { t } = useTranslation();
  const [selectedSite, setSelectedSite] = useState<SiteType | null>(null);
  const {
    isError,
    isLoading,
    data: sites,
  } = useFetch<SiteType[]>("sites", getStudySites, { studyId });
  const present = useToast();

  const onSiteSelect = async (siteId: number, subSiteId?: number) => {
    try {
      const body: PostSiteType = {
        sites: [
          {
            site_id: siteId,
            subsites: subSiteId ? [subSiteId] : [],
          },
        ],
      };

      await postSite(studyId, body);

      onNextClick();
    } catch (e) {
      console.log(e);
      present(e?.response?.data?.message);
    }
  };

  const handleSiteClick = (site: SiteType): void => {
    if (site.subsites.length) {
      setSelectedSite(site);
    } else {
      onSiteSelect(site.id);
    }
  };

  const handleSubsiteClick = ({ id }: SubsiteType): void => {
    onSiteSelect(selectedSite.id, id);
  };

  const handlePreviousClick = (): void => {
    if (selectedSite) {
      setSelectedSite(null);
    } else if (previousStep !== "INSCRIPTION"){
      goPrevious();
    }
  };

  const onNextClick = () =>
    setCurrentStep(WelcompeStepEnum.REGISTRED_SUCCESSFULLY);

  const labelKey = selectedSite ? "subsite" : "site";

  if (isLoading) return <ResonanceLoader />;

  if (isError)
    return (
      <Info
        title={t("common_server_error_title")}
        description={t("common_server_error_desc")}
        actionText={t("common_go_back")}
      />
    );
    const headerTitle =  selectedRole?.site[`question_${labelKey}`] !== '' && selectedRole?.site[`question_${labelKey}`] ? selectedRole?.site[`question_${labelKey}`] : t(`home_${labelKey}s_text`)
  return (
    <Container>
      <ScreenTitle> 
        {headerTitle}
        {isVocalisationEnabled  && <SiteIcon slot="end" icon={volumeHighOutline} onClick={() => readText(headerTitle)}/>}
        
        </ScreenTitle>
      {selectedSite ? (
        <DisplaySitesList
          sites={selectedSite.subsites}
          onSiteClick={handleSubsiteClick}
        />
      ) : (
        <DisplaySitesList sites={sites} onSiteClick={handleSiteClick} />
      )}

    <NextPrevButtons onPreviousClick={selectedSite && handlePreviousClick} /> 
    </Container>
  );
};
const SiteIcon = styled(IonIcon)`
  font-size: 18px!important;
  float: right;
  margin-right: 36px;
`
export default DisplaySites;
