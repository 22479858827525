//By Synakene

export const onboarding = {
  onboarding_title: "Mentalo",
  onboarding_subtitle: 'Our Quest',
  onboarding_header_text: 'Knowledge about the mental well-being of young people.',
  onboarding_header_text2: "To help us, help yourself, and help others, you will need to:",
  onboarding_step1: 'Step 1',
  onboarding_step1_text: 'Talk about yourself',
  onboarding_step2: 'Step 2',
  onboarding_step2_text: "Provide updates 7 times a year in 5 minutes",
  onboarding_step3: 'Step 3',
  onboarding_step3_text: 'Track your progress',
  onboarding_step4: 'Step 4',
  onboarding_step4_text: 'Be an ambassador by inviting your friends',
};