import styled from "styled-components";
import React, { useEffect } from 'react';


export default function NoSlug() {
  if (typeof window !== 'undefined') {
    window.location.replace("https://app.etude-mentalo.fr/mentalo");
    return null; // Render nothing as the page is redirecting
  }


  return (
    <Container>
      <Message>
        Merci de cliquer sur le lien de l'invitation sur votre boîte email ou
        messages SMS
      </Message>
    </Container>
  );
}

const Container = styled.div`
  background-color: ${(p) => p.theme.primary.main};
  height: 100%;
  color: ${(p) => p.theme.primary.contrast};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Message = styled.div`
  font-weight: bold;
  font-size: 24px;
  max-width: 600px;
  text-align: center;
`;
